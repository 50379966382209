import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import axios, { AxiosResponse } from 'axios';
import clsx from 'clsx';
import { getEnvApiUrl } from 'config/env';
import { IRootState } from 'config/store';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ICallback, ICallbackTriggered } from 'shared/model/callback.model';
import { APP_TIMESTAMP_FORMAT, convertDateFromServer, formatDate } from 'shared/utils/date-utils';
import { IDataTableColumn } from 'react-data-table-component';
import DataTable from 'shared/widgets/dataTable';
import ReactJson from 'react-json-view';

const apiUrl = getEnvApiUrl();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'calc(100vw - 90px)',
      [theme.breakpoints.down('sm')]: {
        width: '100vw'
      }
    },
    leftMenuOpen: {
      width: 'calc(100vw - 235px)'
    },
    title: {
      fontWeight: 'bold',
      '&>svg': {
        verticalAlign: 'middle',
        marginRight: theme.spacing(1)
      }
    },
    icon: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1)
    }
  })
);

function insertDataSlice<T>(data: T[], newData: T[], page: number, pageSize: number) {
  const start = (page - 1) * pageSize;
  const end = start + pageSize;
  return [...data.slice(0, start), ...newData, ...data.slice(end)];
}

function getDataSlice<T>(data: T[], page: number, pageSize: number) {
  const start = (page - 1) * pageSize;
  const end = start + pageSize;
  return data.slice(start, end);
}

function isDataPageLoaded<T>(data: T[], page: number, pageSize: number, total: number) {
  if (total === -1) return false;
  if (total === 0) return true;

  const start = (page - 1) * pageSize;
  const end = Math.min(start + pageSize, total);
  for (let i = start; i < end; i++) {
    if (!data[i]) return false;
  }

  return true;
}

function is200HttpCode(statusCode: number) {
  return Math.round(statusCode / 100) === 2;
}

export interface ICallbackDetailsProps {
  data?: ICallback;
}

interface Paginated<T> {
  results: T[];
  page: number;
  pageSize: number;
  pageCount: number;
  rowCount: number;
}

const CallbackDetails = (props: ICallbackDetailsProps) => {
  const callback = props.data;
  const classes = useStyles();
  const { t } = useTranslation();
  const leftMenuOpen = useSelector(({ layout }: IRootState) => layout.leftMenuOpen);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(-1);
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<ICallbackTriggered[]>([]);

  const fetchData = useCallback(async (callbackId: string, page: number, pageSize: number) => {
    setLoading(true);
    const response: AxiosResponse<Paginated<ICallbackTriggered>> = await axios.get(`${apiUrl}/callbacks/${callbackId}/triggered?page=${page}&page_size=${pageSize}`);

    response.data.results.forEach(aTrigger => {
      aTrigger.triggered_at = convertDateFromServer(aTrigger.triggered_at);
    });

    setTotal(response.data.rowCount);
    setData(data => insertDataSlice(data, response.data.results, page, pageSize));
    setLoading(false);
  }, []);

  // Load data if needed
  useEffect(() => {
    if (callback && !loading && !isDataPageLoaded(data, page, pageSize, total)) {
      fetchData(callback.id, page, pageSize);
    }
  }, [callback, fetchData, data, page, pageSize, total, loading]);

  const columns: IDataTableColumn<ICallbackTriggered>[] = useMemo(() => {
    return [

      {
        selector: 'status_code',
        name: t('callback_status_code', { defaultValue: 'Status Code' }),
        cell: (row: ICallbackTriggered) => {
          return <>
            {row.status_code}
            {is200HttpCode(row.status_code) ? (
              <CheckIcon color="primary" className={classes.icon} />
            ) : (
              <WarningIcon color="secondary" className={classes.icon} />
            )}
          </>
        },
        grow: 0.5
      },
      {
        selector: 'triggered_at',
        name: t('triggered_at'),
        grow: 1,
        format: (row: ICallbackTriggered) => formatDate(row.triggered_at, APP_TIMESTAMP_FORMAT)
      },
      {
        selector: 'url',
        name: 'URL',
        grow: 1.5
      },
      {
        selector: 'body',
        name: '',
        cell: (row: ICallbackTriggered) => <ReactJson
          style={{ fontSize: '12px' }}
          name={null}
          enableClipboard={true}
          src={row.body}
          collapsed
          displayDataTypes={false}
        />,
        grow: 3
      }

    ];
  }, [t, classes])

  if (!callback) {
    return null;
  }

  return (
    <Box className={clsx(classes.root, { [classes.leftMenuOpen]: leftMenuOpen })} p={2} pl={8}>
      <Box>
        <Box minHeight="100px">
          <Paper elevation={4}>
            <Box p={1}>
              <DataTable
                // title={callback.name}
                noHeader
                fixedHeader={false}
                columns={columns}
                data={getDataSlice(data, page, pageSize)}
                progressPending={loading}
                pagination
                paginationServer
                paginationPerPage={pageSize}
                paginationTotalRows={total}
                onChangeRowsPerPage={(newPageSize, newPage) => {
                  setPageSize(newPageSize)
                  setPage(newPage)
                }}
                onChangePage={setPage}
                highlightOnHover={false}
                pointerOnHover={false}
                noDataComponent={<Box pt={3}>
                  {t('noTriggeredCallback')}
                </Box>}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box >
  );
};

export default CallbackDetails;
