import { TextField } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';

export interface ISearchBoxProps {
  placeholder: string;
  onPlacesChanged: (result: google.maps.places.PlaceResult[]) => void;
}

const SearchBox = (props: ISearchBoxProps) => {
  const ref = useRef<HTMLInputElement | null>();

  useEffect(() => {
    const onPlacesChanged = () => {
      props.onPlacesChanged(searchBox.getPlaces());
    };
    const searchBox = new window.google.maps.places.SearchBox(ref.current as HTMLInputElement);
    searchBox.addListener('places_changed', onPlacesChanged);
  }, [props]);

  return <TextField label={props.placeholder} fullWidth inputRef={ref} />;
};

export default SearchBox;
