import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import { IRootState } from 'config/store';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PrivateComponent from 'shared/auth/privateComponent';
import { IUser } from 'shared/model/user.model';
import { errorNotification } from 'shared/reducers/notifierSlice';
import { deleteUser, fetchUsers } from 'shared/reducers/usersSlice';
import { formatDate } from 'shared/utils/date-utils';
import { displayUser } from 'shared/utils/user-utils';
import ConfirmDelete from 'shared/widgets/confirmDelete';
import DataTable from 'shared/widgets/dataTable';
import TitleWithSearchField from 'shared/widgets/titleWithSearchField';
import AddGroupToUserDialog from './dialogs/addGroupToUserDialog';
import AddOrEditUserDialog from './dialogs/addOrEditUserDialog';
import SendInvitation from './SendInvitation';
import UserDetails from './userDetails';
import UserGroupMemberShip from './userGroupMembership';
import { Info } from '@material-ui/icons';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contextToolBar: {
      '&>*': {
        marginLeft: theme.spacing(1)
      }
    },
    chip: {
      margin: theme.spacing(0.5),
      borderRadius: '10px'
    },
    warning: {
      color: '#ffb74d',
      marginRight: theme.spacing(1)
    },
    info: {
      color: "#64b5f6",
      marginRight: theme.spacing(1)
    },
    memberships: {
      display: 'flex',
      flexWrap: 'wrap',
      '&>*': {
        maxWidth: '150px',
        textOverflow: 'ellipsis'
      }
    },
    divider: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1)
    }
  })
);

const Users = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const users = useSelector(({ users }: IRootState) => users.users);
  const errorMessage = useSelector(({ users }: IRootState) => users.errorMessage);
  const loading = useSelector(({ users }: IRootState) => users.loading);
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState<IUser[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filterText, setFilterText] = React.useState('');

  const columns: IDataTableColumn<IUser>[] = useMemo(
    () => [
      {
        selector: 'first_name',
        name: t('lastname'),
        sortable: true,
        grow: 3,
        format: (row: IUser) => (
          <Box key={row.id} p={0.5}>
            <Box fontWeight="fontWeightBold" data-tag="allowRowEvents">
              {displayUser(row)}
            </Box>
            <Box fontWeight="fontWeightLight" data-tag="allowRowEvents">
              {row.email}
            </Box>
          </Box>
        )
      },
      {
        selector: 'workspace_role.workspace_role_name',
        name: t('role'),
        sortable: true,
        center: true,
        grow: 0.5
      },
      {
        selector: 'group_memberships',
        name: t('group', {count: 2}),
        grow: 6,
        hide: 'md',
        format: (row: IUser) => {
          const isAfter = moment().isAfter(moment(row.invite_expire_at))
          return (
          <Box display="flex" alignItems="center">
            {!!row.is_invitation_pending && (
              <Box display="flex" alignItems="center" data-tag="allowRowEvents">
                {isAfter? <WarningIcon className={classes.warning} /> :  <Info className={classes.info} />}
                  {isAfter ?  t('user_invitation_expired') :  t('user_invitation_pending') }
                <Divider className={classes.divider} orientation="vertical" flexItem />
              </Box>
            )}
            <Box className={classes.memberships}>
              {row.group_memberships.length < 6 ? (
                <>
                  {row.group_memberships.map(aGroup => (
                    <UserGroupMemberShip key={aGroup.group.group_id} groupMembership={aGroup} user={row} />
                  ))}
                </>
              ) : (
                <Box data-tag="allowRowEvents">{`${row.group_memberships.length} ${t('group', {count: row.group_memberships.length})}`}</Box>
              )}
            </Box>
          </Box>
        )}
      },
      {
        selector: 'last_login',
        name: t('last_login'),
        sortable: true,
        center: true,
        grow: 1,
        hide: 'md',
        format: (row: IUser) => formatDate(row.last_login)
      }
    ],
    [classes.memberships, classes.divider, classes.warning,classes.info, t]
  );

  useEffect(() => {
    if (errorMessage) {
      dispatch(errorNotification(t(`${errorMessage}`)));
    }
  }, [dispatch, errorMessage, t]);

  const actions = (
    <PrivateComponent hasRight="CREATE:User">
      <AddOrEditUserDialog />
    </PrivateComponent>
  );

  const contextActions = useMemo(() => {
    const onSuccess = () => {
      setToggleCleared(!toggleCleared);
    };

    const handleDelete = async () => {
      for (let i = 0; i < selectedRows.length; i++) {
        await dispatch(deleteUser(selectedRows[i], false));
      }
      onSuccess();
      dispatch(fetchUsers());
    };

    return (
      <Box className={classes.contextToolBar}>
        {selectedRows.length === 1 && (
          <>
            <PrivateComponent hasRight="UPDATE:User">
              <AddOrEditUserDialog user={selectedRows[0]} onSuccess={onSuccess} />
              <SendInvitation user={selectedRows[0]} onSuccess={onSuccess} />
            </PrivateComponent>
          </>
        )}
        <PrivateComponent hasRight="UPDATE:User">
          <AddGroupToUserDialog users={selectedRows} onSuccess={onSuccess} />
        </PrivateComponent>
        <PrivateComponent hasRight="DELETE:User">
          <ConfirmDelete onConfirm={handleDelete} objectToReturn={selectedRows} size="small" />
        </PrivateComponent>
      </Box>
    );
  }, [classes.contextToolBar, dispatch, selectedRows, toggleCleared]);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const conditionalRowStyles = [
    {
      when: (row: IUser) => row.is_invitation_pending,
      style: {
        backgroundColor: '#d6ecfe'
      }
    },
    {
      when: (row: IUser) => row.is_invitation_pending && moment().isAfter(moment(row.invite_expire_at)),
      style: {
        backgroundColor: '#FFF4E5'
      }
    }
  ];

  const title = React.useMemo(() => {
    const onChange = (filter: string) => {
      setFilterText(filter);
      if (filter.length === 0) {
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };

    return <TitleWithSearchField title={t('user', {count: 100})} placeholder={t('searchPlaceholder')} onChange={onChange} autoFocus />;
  }, [resetPaginationToggle, t]);

  const data =
    filterText.length > 0
      ? users.filter(item => {
          const name = displayUser(item).toLocaleLowerCase();
          const email = item.email.toLowerCase();
          const filter = filterText.toLocaleLowerCase();

          return name.includes(filter) || email.includes(filter);
        })
      : users;

  return (
    <Box p={1}>
      <DataTable
        title={title}
        columns={columns}
        data={data}
        selectableRows
        defaultSortField="first_name"
        onSelectedRowsChange={handleRowSelected}
        progressPending={loading}
        contextActions={contextActions}
        actions={actions}
        conditionalRowStyles={conditionalRowStyles}
        clearSelectedRows={toggleCleared}
        expandableRows
        expandableRowsComponent={<UserDetails />}
        expandOnRowClicked
        expandableRowsHideExpander
      />
    </Box>
  );
};

export default Users;
