import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import isNumber from 'lodash/isNumber';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './level.css';

export const levelThresholds = [30, 50];

export const useLevelStyles = makeStyles((theme: Theme) =>
  createStyles({
    border: {
      border: '2px Solid',
      borderRadius: '4px'
    },
    legend: {
      textAlign: 'center'
    }
  })
);

export interface IGraphLevelProps {
  levelPercent: any;
  width?: number;
  height?: number;
  legend?: string;
}

const GraphLevel = (props: IGraphLevelProps) => {
  const { levelPercent, legend, width = 80, height = 80 } = props;
  const classes = useLevelStyles();
  const { t } = useTranslation();

  // if (!isNumber(levelPercent) && ) {
  //   return null;
  // }

  const levelInfo = getLevelInfo(levelPercent);
  const levelColor = !isNumber(levelPercent) ? 'unknown-level' : levelInfo.className;
  return (
    <Box>
      <Box>
        <svg width={width} height={height} viewBox="0 0 100 100" className={`${classes.border} ${levelColor}`}>
         
          {!isNumber(levelPercent) ? 
            <g>
              <text text-anchor="center" font-size="45" id="svg_3" y="60" x="40" stroke-width="0" stroke="#000" fill="#ff0000">?</text> 
              <rect className={levelColor} id="40_percent" width="80" y="68" x="10" strokeWidth="15" height="2" />
              <rect className={levelColor} id="20_percent" width="80" y="88" x="10" strokeWidth="15" height="2" />
            </g>
          :  <title>{`${t('level')}: ${levelPercent}%`}</title> }
          <g>
            {levelPercent > 90 && <rect className={levelColor} id="100_percent" width="80" y="9" x="10" strokeWidth="15" height="2" />}
            {levelPercent > 70 && <rect className={levelColor} id="80_percent" width="80" y="29" x="10" strokeWidth="15" height="2" />}
            {levelPercent > 50 && <rect className={levelColor} id="60_percent" width="80" y="49" x="10" strokeWidth="15" height="2" />}
            {levelPercent > 30 && <rect className={levelColor} id="40_percent" width="80" y="68" x="10" strokeWidth="15" height="2" />}
            {levelPercent > 10 && <rect className={levelColor} id="20_percent" width="80" y="88" x="10" strokeWidth="15" height="2" />}
          </g>
        </svg>
      </Box>
      {legend && (
        <Box className={classes.legend}>
          <Typography variant="h6" className={levelColor}>
            {legend}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default GraphLevel;

export const getLevelInfo = (level: number) => {
  let className = 'green-level';
  let titleKey = 'high';
  let mapColorKey = '4caf50';
  if (level <= levelThresholds[0]) {
    className = 'red-level';
    titleKey = 'low';
    mapColorKey = 'e74c3c';
  } else if (level <= levelThresholds[1]) {
    className = 'orange-level';
    titleKey = 'medium';
    mapColorKey = 'f39c12';
  }
  return {
    className,
    titleKey,
    mapColorKey
  };
};
