import React from 'react';
import { useHasRight } from './auth-utils';

interface IPrivateComponentProps {
  hasRight: string;
  otherwise?: React.ReactElement<any, any>;
}

const PrivateComponent: React.FunctionComponent<IPrivateComponentProps> = props => {
  const isAuthorized = useHasRight(props.hasRight);
  if (isAuthorized) {
    return <>{props.children}</>;
  }
  if (props.otherwise) {
    return <>{props.otherwise}</>;
  }

  return null;
};

export default PrivateComponent;
