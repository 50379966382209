import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { IRootState } from 'config/store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PrivateComponent from 'shared/auth/privateComponent';
import { ReactComponent as AddGraphIcon } from 'shared/icons/createGraph.svg';
import { IGroup } from 'shared/model/group.model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white
    },
    toolbar: {
      color: theme.palette.primary.main,
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2)
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center'
    },
    divider: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1)
    },
    mr1: {
      marginRight: theme.spacing(1)
    },
    icon: {
      fill: 'white',
      width: '20px',
      height: '22px'
    }
  })
);

const DashboardToolbar = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dashboard = useSelector(({ dashboard }: IRootState) => dashboard.dashboard);
  const groups = useSelector(({ group }: IRootState) => group.groups);

  if (!dashboard) {
    return null;
  }

  const dashboardGroups: IGroup[] = dashboard.group_ids_from_graphs.reduce((agg: IGroup[], groupId) => {
    const group = groups.find(aGroup => aGroup.group_id === groupId);
    if (group) {
      agg.push(group);
    }
    return agg;
  }, []);

  return (
    <AppBar position="relative" className={classes.root}>
      <Toolbar disableGutters className={classes.toolbar}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box flexGrow={1} display="flex" alignItems="center" flexWrap="wrap" p={0.1}>
            <Box>{dashboard?.name}</Box>
            <Hidden smDown>
              <Divider orientation="vertical" flexItem className={classes.divider} />
              {dashboardGroups.map(aGroup => (
                <Chip key={aGroup.group_id} size="small" label={aGroup.group_name} variant="outlined" className={classes.mr1} />
              ))}
            </Hidden>
          </Box>
          <Box display="flex" alignItems="center">
            <PrivateComponent hasRight="CREATE:Graph">
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to={`/dashboard/${dashboard.dashboard_id}/graph/new`}
                size="small"
                title={t('create_graph')}
              >
                <AddGraphIcon className={classes.icon} />
              </Button>
            </PrivateComponent>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardToolbar;
