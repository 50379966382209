import { IRootState } from 'config/store';
import { useSelector } from 'react-redux';
import { IDevice } from 'shared/model/device.model';
import { IGroup } from 'shared/model/group.model';

/**
 * @param rolesRights available roles rights with for of array : [Admin_READ:Device, Admin_CREATE:Dashboard]
 * @param rightToCheck right to check
 */
const hasRight = (rolesRights: string[], rightToCheck: string) => rolesRights.some(r => r.endsWith(rightToCheck)) // we do not use the role part anymore that is why we use endsWith 

/**
 * @param rightToCheck right to check
 */
export const useHasRight = (rightToCheck?: string) => {
  const currentUser = useSelector(({ authentication }: IRootState) => authentication.currentUser);
  if (!rightToCheck) return true // if no rights to check, authorized by default (used to menuItem)
  if (!currentUser) return false // if no user not authorized by default

  // installer has no rights on the platform v1 except if it is an admin of a gorup
  if (currentUser?.workspace_role.workspace_role_name.toLowerCase().includes('installer') && !currentUser.group_memberships.some(gm => gm.group_role.group_role_name.includes('Admin'))) return false 
  // Check Right on whole workspace
  if (hasRight(currentUser.acls, rightToCheck)) return true
  // Check Rights on any group
  else {
    const hasGroupDelegation = currentUser.group_memberships.some(
      groupMembership => hasRight(groupMembership.acls, rightToCheck)
      );
    return hasGroupDelegation
  }
};

export const getGroupsDevices = (groups: IGroup[], filterCombineDevice = false) => {
  return groups.reduce((agg: IDevice[], aGroup) => {
    if (aGroup.devices) {
      const devicesToAdd = aGroup.devices.filter(aDevice => {
        if (filterCombineDevice && aDevice.is_combined) {
          return false;
        }

        return !agg.some(existingDevice => existingDevice.device_id === aDevice.device_id);
      });

      return agg.concat(devicesToAdd);
    }
    return agg;
  }, []);
};
