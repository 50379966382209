import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { IRootState } from 'config/store';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PrivateComponent from 'shared/auth/privateComponent';
import { IAlert } from 'shared/model/alert.model';
import { deleteAlert, fetchAlerts, updateAlert } from 'shared/reducers/alertsSlice';
import { fetchUsers } from 'shared/reducers/usersSlice';
import { APP_TIMESTAMP_FORMAT, formatDate } from 'shared/utils/date-utils';
import ConfirmDelete from 'shared/widgets/confirmDelete';
import DataTable from 'shared/widgets/dataTable';
import TitleWithSearchField from 'shared/widgets/titleWithSearchField';
import UserAvatar from 'shared/widgets/userAvatar';
import AlertDetailContent from './alertDetailContent';
import AlertDetails from './alertDetails';
import AlertGroup from './alertGroup';
import { Switch } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contextToolBar: {
      '&>*': {
        marginLeft: theme.spacing(1)
      }
    },
    icon: {
      width: '32px',
      height: '32px'
    }
  })
);

const Alerts = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const alerts = useSelector(({ alerts }: IRootState) => alerts.alerts);
  const loading = useSelector(({ alerts }: IRootState) => alerts.loading);
  const groups = useSelector(({ group }: IRootState) => group.groups);
  const users = useSelector(({ users }: IRootState) => users.users);
  const [selectedRows, setSelectedRows] = useState<IAlert[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filterText, setFilterText] = React.useState('');

  const columns: IDataTableColumn<IAlert>[] = useMemo(
    () => {
      const handleStatusChange = (alert: IAlert) => () => {
        const toUpdate = {
          alert_id: alert.alert_id,
          is_active: !alert.is_active
        };
        dispatch(updateAlert(toUpdate, true));
      };
      return [
      {
        selector: 'alert_name',
        name: t('name'),
        sortable: true,
        grow: 2,
        format: (row: IAlert) => (
          <Box display="flex" alignItems="center">
            <NotificationsNoneIcon data-tag="allowRowEvents" />
            <Box fontWeight="fontWeightBold" data-tag="allowRowEvents">
              {row.alert_name}
            </Box>
          </Box>
        )
      },
      {
        selector: 'a',
        name: t('detail'),
        grow: 4,
        sortable: true,
        hide: 'md',
        format: (alert: IAlert) => <AlertDetailContent alert={alert} />
      },
      {
        selector: 'last_triggered_timestamp',
        name: t('last_triggered_timestamp'),
        grow: 1,
        sortable: true,
        hide: 'sm',
        format: (alert: IAlert) => {
          if (alert.last_triggered_timestamp) {
            const humanize = alert.last_triggered_timestamp.fromNow();
            const dateStr = formatDate(alert.last_triggered_timestamp, APP_TIMESTAMP_FORMAT);
            return `${humanize} - ${dateStr}`;
          }
          return null;
        }
      },
      {
        selector: 'b',
        name: t('receivers'),
        grow: 1,
        center: true,
        sortable: true,
        hide: 'md',
        format: (alert: IAlert) => (
          <Box display="flex">
            {alert.recipients_for_notifications_ids.map(userId => {
              const user = users.find(aUser => aUser.id === userId);
              return <UserAvatar user={user} key={userId} />;
            })}
            {alert.recipient_groups_for_notifications_ids.map(grpId => {
              const group = groups.find(aGroup => aGroup.group_id === grpId);
              return <AlertGroup key={`grpId`} group={group} />;
            })}
          </Box>
        )
      },
      {
        selector: 'is_active',
        name: t('rules_status'),
        sortable: true,
        grow: 1,
        format: (row: IAlert) => {
          return (
            <PrivateComponent
              hasRight="UPDATE:Alert"
             
              otherwise={<Switch checked={row.is_active} color="primary" />}
            >
              <Switch checked={row.is_active} color="primary" onChange={handleStatusChange(row)} />
            </PrivateComponent>
          );
        }
      }
    ]},
    [groups, t, users,dispatch]
  );

  useEffect(() => {
    dispatch(fetchAlerts());
  }, [dispatch]);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(fetchUsers());
    }
  }, [users.length, dispatch]);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const actions = (
    <PrivateComponent hasRight="CREATE:Alert">
      <Button color="primary" variant="contained" component={Link} to="/alerts/new">
        <AddIcon />
      </Button>
    </PrivateComponent>
  );

  const contextActions = useMemo(() => {
    const onSuccess = () => {
      dispatch(fetchAlerts());
      setToggleCleared(!toggleCleared);
    };

    const handleDelete = async () => {
      for (let i = 0; i < selectedRows.length; i++) {
        await dispatch(deleteAlert(selectedRows[i]));
      }
      onSuccess();
    };

    return (
      <Box className={classes.contextToolBar}>
        {selectedRows.length === 1 && (
          <PrivateComponent hasRight="UPDATE:Alert">
            <Button color="primary" variant="contained" component={Link} to={`/alerts/${selectedRows[0].alert_id}`}>
              <EditIcon />
            </Button>
          </PrivateComponent>
        )}
        <PrivateComponent hasRight="DELETE:Alert">
          <ConfirmDelete onConfirm={handleDelete} objectToReturn={selectedRows} size="small" />
        </PrivateComponent>
      </Box>
    );
  }, [classes.contextToolBar, dispatch, selectedRows, toggleCleared]);

  const title = React.useMemo(() => {
    const onChange = (filter: string) => {
      setFilterText(filter);
      if (filter.length === 0) {
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };

    return <TitleWithSearchField title={t('alert', {count: 100})} placeholder={t('alerts_searchPlaceholder')} onChange={onChange} autoFocus />;
  }, [resetPaginationToggle, t]);

  const data =
    alerts.length > 0
      ? alerts.filter(item => {
          const name = item.alert_name.toLocaleLowerCase();
          const filter = filterText.toLocaleLowerCase();
          return name.includes(filter);
        })
      : alerts;

  return (
    <Box p={1}>
      <DataTable
        title={title}
        columns={columns}
        data={data}
        selectableRows
        defaultSortField="last_triggered_timestamp"
        progressPending={loading}
        onSelectedRowsChange={handleRowSelected}
        expandableRows
        expandableRowsComponent={<AlertDetails />}
        expandOnRowClicked
        expandableRowsHideExpander
        actions={actions}
        contextActions={contextActions}
        clearSelectedRows={toggleCleared}
      />
    </Box>
  );
};

export default Alerts;
