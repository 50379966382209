import { writeToString } from '@fast-csv/format';
import { IGraphData } from 'shared/model/api.model';
import { contentToFile, isFloat, replaceDecimalSeparator } from 'shared/utils/data-utils';
var JSZIP = require('jszip');

export type exportTypeSilo = 'daily_analysis'| 'level'
export interface ICSVExportDef {
  columns: ICsvDataColumn[];
  suffix: string;
  delimiter: string;
  decimalDelimiter: ',' | '.';
  language: string;
  types: exportTypeSilo[];
}

export interface ICsvDataColumn {
  label: string;
  value: string;
}

const convertDecimal = (value: number, exportDef: ICSVExportDef) => (isFloat(value) && exportDef.decimalDelimiter === ',') 
  ? replaceDecimalSeparator(value, '.', ',')
  : value

export const convertGraphDataToCSV = async (data: IGraphData[], exportDef: ICSVExportDef) => {
  const files: string | { name: string; content: string; }[] = []
  const devices = data.reduce((agg: any[], current) => {
    let device = agg.find(agg => agg.id === current.device_id);
    if (!device) {
      device = {
        id: current.device_id,
        device_name: current.device_name,
        device_reference: current.device_reference,
        timezone: current.timezone,
        points: []
      };
      agg.push(device);
    }

    device.points = device.points.concat(current.data_points.map(point => ({ ...point, dataType: current.data_type })));
    return agg;
  }, []);
  devices.forEach( async aDevice => {
    let dailyFlowMeasure :any[] = []
    const measures: any[] = aDevice.points.reduce((agg: any[], current: any) => {
      const timestamp = new Date(current.timestamp);
      let measure = agg.find(aMeasure => aMeasure.time === timestamp.getTime());
      if (!measure && current.dataType !== 'daily_analysis') {
        measure = {
          time: timestamp.getTime(),
          // @ts-ignore
          timestamp: timestamp.toLocaleString(exportDef.language).replaceAll(',', ''),
          device_name: aDevice.device_name,
          device_reference: aDevice.device_reference
        };
        agg.push(measure);
      }
      switch (current.dataType) {
        case 'position':
          const value = current.value;
          measure[current.dataType] = `${value.position_latitude},${value.position_longitude}`;
          break;
        case 'daily_analysis':
          dailyFlowMeasure.push( {
            timestamp: timestamp.toLocaleDateString(exportDef.language, {timeZone: aDevice.timezone}),
            device_name: aDevice.device_name,
            device_reference: aDevice.device_reference,
            'daily_analysis': convertDecimal(current.value, exportDef)
          })
          break;
        default:
          measure[current.dataType] = convertDecimal(current.value, exportDef)
          break;
      }

      return agg;
    }, []);

    if (dailyFlowMeasure.length && exportDef.types.includes('daily_analysis')) {
      let zone = aDevice.timezone; 
      try {
        const intermediateDate = new Date().toLocaleString(exportDef.language, {year:"numeric", timeZone: aDevice.timezone, timeZoneName: "short"})
        console.log({intermediateDate})
        zone= intermediateDate.split(' ')[1] ?? zone
      } catch (err) {
        console.log(err)
      }
      exportDef.columns[0].label += ` (${zone})` // add timezone
      const file = await convertDataToCSV(dailyFlowMeasure, {...exportDef, columns: exportDef.columns.filter(c => ['device_name', 'device_reference', 'timestamp', 'daily_analysis'].includes(c.value))}, 'daily_consumption');
      files.push(file)
      exportDef.columns = exportDef.columns.filter(c => c.value !== 'daily_analysis')
    }
    if (measures.length > 0 && exportDef.types.includes('level')) {
      const file = await convertDataToCSV(measures, exportDef);
      files.push(file)
    }
    if (files.length) {
      if (files.length ===1) contentToFile(files[0].content, 'text/csv;charset=utf-8;', `${aDevice.device_name}_${exportDef.suffix}_${files[0].name}`);
      if (files.length > 1) {
        const zip = new JSZIP();
        
        files.forEach(file => {
          console.log(file.name)
          zip.file(file.name, file.content);
        });

        // Generate the zip content asynchronously
        zip.generateAsync({ type: 'blob' })
          .then((content: Blob) => {
            // Create a download link
            saveAs(content, `${aDevice.device_name}_${exportDef.suffix}.zip`)
          });
      }
    }
  });
};

const convertDataToCSV = async (data: any[], exportDef: ICSVExportDef, type = 'data') => {
  const delimiter = exportDef.delimiter;
  const rowDelimiter = '\n';

  const headers = exportDef.columns.map(aDef => aDef.value);
  const headerLabels = exportDef.columns.map(aDef => aDef.label).join(delimiter);

  const csvConfig = {
    delimiter,
    rowDelimiter,
    headers,
    writeHeaders: false
  };

  const csv = await writeToString(data, csvConfig);
  const content = '\ufeff' + headerLabels + rowDelimiter + csv;

  const name = `${type}.csv`;
  return {name, content}
  // contentToFile(final, 'text/csv;charset=utf-8;', path);
};
