import { IconButton, Paper, InputBase, Divider } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import parseDms from '../../../shared/utils/dms-utils'
import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';

let listener;
const SearchBoxV2 = ({handlePlacesChanged, baseAddress = null}) => {
  const ref = useRef();
	if (ref?.current && baseAddress) ref.current.value = baseAddress
	const {t} = useTranslation()
  useEffect(() => {
    const searchBox = new window.google.maps.places.SearchBox(ref.current);
    if (!listener) listener = searchBox.addListener('places_changed', () => 
		{
			try {
				const coord = parseDms(ref.current.value)
				handlePlacesChanged(coord)
			}
			catch	 (err) {
				if (err === 'COULD_NOT_PARSE') handlePlacesChanged(searchBox.getPlaces())
				else handlePlacesChanged([])
			}
		});
		return () => {
			new window.google.maps.event.removeListener(listener) 
			listener=null
		}
  }, [handlePlacesChanged]);
	return <Paper style={{
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%'}}>
			<InputBase  inputRef={ref} style={{flex: 1}} placeholder={t('searchAddressPH')}/>
      <IconButton>
        <SearchIcon />
      </IconButton>
			{ref.current?.value && <>
				<Divider orientation="vertical" style={{
						height: 22,
						margin: 4
					}}/>
				<IconButton onClick={() =>{ 
					ref.current.value = ''
					handlePlacesChanged([])
					}} >
					<Close />
				</IconButton>
			</>
			}
	</Paper>
};

export default SearchBoxV2;
