import React from 'react';
import PropTypes from 'prop-types';
import { Grid, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core/';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@material-ui/core';
import DeviceGroup from 'modules/devices/deviceGroup';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({
    user_group_membership_id: PropTypes.string.isRequired
  }).isRequired).isRequired
};

const defaultProps = {};

/**
 *
 */
function GroupsDialog({ open, handleClose, groups }) {
  const { t } = useTranslation();
  return (
    <Dialog open={open} maxWidth="sm" onClose={handleClose} close={handleClose}>
      <DialogTitle>{t('group', {count: 2})}</DialogTitle>
      <DialogContent dividers>
        <Grid container >
          {groups.map((group) => (
            <Grid key={group.user_group_membership_id} item>
              <DeviceGroup group={group} />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

GroupsDialog.propTypes = propTypes;
GroupsDialog.defaultProps = defaultProps;
// #endregion

export default GroupsDialog;
