import { TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

export interface ICallbackFormFirstStepProps {
  step: number;
  activeStep: number;
}

const CallbackFormFirstStep = (props: ICallbackFormFirstStepProps) => {
  const { activeStep, step } = props;
  const { t } = useTranslation();

  const form = useFormContext();

  return (
    <Box display={activeStep === step ? 'block' : 'none'}>
      <Grid container justify="center">
        <Grid item xs={12} md={6} lg={4}>
          <Box textAlign="center">{t('request_post_info')}</Box>
          <TextField
            autoFocus
            margin="dense"
            label={t('name')}
            fullWidth
            name="name"
            inputRef={form.register({
              validate: value => {
                if (activeStep === step && value.length === 0) {
                  return <Trans i18nKey="required_field">Required Field</Trans>;
                }
                return true;
              }
            })}
            error={form.errors.name ? true : false}
            helperText={form.errors.name && form.errors.name.message}
          />
          <TextField
            margin="dense"
            label="URL"
            fullWidth
            name="url"
            type="url"
            inputRef={form.register({ required: <Trans i18nKey="required_field">Required Field</Trans>})}
            error={form.errors.url ? true : false}
            helperText={form.errors.url && form.errors.url.message}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CallbackFormFirstStep;
