import { createStyles, makeStyles, Theme } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { IRootState } from 'config/store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DeviceStatusFilter } from 'shared/model/device.model';
import { workspaceIsSilo } from 'shared/utils/workspace-utils';
import { IbcIcon, SiloIcon } from './deviceIcon';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
      width: '32px',
      height: '32px'
    },
    statusFilter: {
      height: '29px',
      minWidth: '100%',
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        minWidth: '10rem',
        marginLeft: theme.spacing(2)
      }
    },
    empty: {
      color: 'rgba(0, 0, 0, 0.5)'
    }
  })
);

interface IDeviceFilterProps {
  status: DeviceStatusFilter;
  onChange: (event: React.ChangeEvent<any>) => void;
}

const DeviceFilter = (props: IDeviceFilterProps) => {
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isSilo = workspaceIsSilo(settings);

  const classes = useStyles();
  const { t } = useTranslation();

  const { status, onChange } = props;

  return (
    <Select labelId="device-status-select-filter" value={status} onChange={onChange} className={classes.statusFilter}>
      <MenuItem value="empty">
        <span className={classes.empty}>{t('devices_status.placeholder')}</span>
      </MenuItem>
      <MenuItem value="ok">
        <div className={classes.menuItem}>
          {isSilo ? <SiloIcon /> : <IbcIcon status="ok" />}
          <div>{t('devices_status.ok.label')}</div>
        </div>
      </MenuItem>
      
      {!isSilo ? 
        <MenuItem value="pending">
          <div className={classes.menuItem}>
            <IbcIcon status="level_problem" />
            {t('devices_status.level_problem.label')}
          </div>
        </MenuItem>
      : <MenuItem value="pending">
        <div className={classes.menuItem}>
          {<SiloIcon />}
          {t('devices_status.pending.label')}
        </div>
      </MenuItem>
      }
      <MenuItem value="error">
        <div className={classes.menuItem}>
          {isSilo ? <SiloIcon /> : <IbcIcon status="error" />}
          {isSilo ? t('devices_status.error.label') : 'Ko'}
        </div>
      </MenuItem>
    </Select>
  );
};

export default DeviceFilter;
