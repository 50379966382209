import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import 'react-grid-layout/css/styles.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import 'react-resizable/css/styles.css';
import { Link, useParams } from 'react-router-dom';
import PrivateComponent from 'shared/auth/privateComponent';
import { IGraph } from 'shared/model/graph.model';
import { deleteGraph, fetchDashboard } from 'shared/reducers/dashboardSlice';
import ConfirmDelete from 'shared/widgets/confirmDelete';
import ErrorVizFallback from '../error/ErrorVizFallBack';
import NanoCurveViz from '../vizualisation/NanoHightchartCurveViz';
import NanoMapViz from '../vizualisation/NanoMapViz';
import NanoTableViz from '../vizualisation/NanoTableViz';
import NanoDashboardGridItemFilter from './filter/NanoDashboardGridItemFilter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      padding: '0.6rem',
      paddingTop: '0.2rem',
      height: '100%'
    },
    itemContent: {
      padding: 0,
      paddingBottom: '0 !important',
      width: '100%',
      height: 'calc(100% - 15px)' //15 px keep space for resizable-handle
    },
    header: {
      height: '28px',
      cursor: 'all-scroll'
    },
    headerTitle: {
      fontWeight: theme.typography.fontWeightBold
    },
    headerToolBar: {
      minWidth: '90px',
      '&>*': {
        marginLeft: theme.spacing(0.5),
        minWidth: '1rem'
      }
    },
    vizToDelete: {
      textAlign: 'center',
      height: '100%'
    }
  })
);

export interface NanoDashboardGridItemProps {
  graph: IGraph;
  preview?: boolean;
}

const NanoDashboardGridItem = (props: NanoDashboardGridItemProps) => {
  const { graph, preview = false } = props;
  const [error, setError] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const classes = useStyles();

  const onConfirmDelete = () => {
    if (graph.graph_id) {
      dispatch(deleteGraph(graph.graph_id));
      dispatch(fetchDashboard(id));
    }
  };

  return (
    <Card className={classes.item}>
      <CardContent className={classes.itemContent}>
        {!preview && (
          <Box display="flex" alignItems="center" className={classes.header + ' nano-grit-item-header'}>
            <Box flexGrow={1} className={classes.headerTitle}>
              <Typography>{graph.name}</Typography>
            </Box>
            <Box className={classes.headerToolBar}>
              {(graph.type === 'table' || graph.type === 'map') && (
                <PrivateComponent hasRight="UPDATE:Graph">
                  <NanoDashboardGridItemFilter graph={graph} />
                </PrivateComponent>
              )}
              <PrivateComponent hasRight="UPDATE:Graph">
                <Button color="primary" size="small" variant="outlined" component={Link} to={`/dashboard/${id}/graph/${graph.graph_id}`}>
                  <EditIcon fontSize="small" />
                </Button>
              </PrivateComponent>
              <PrivateComponent hasRight="DELETE:Graph">
                <ConfirmDelete
                  onConfirm={onConfirmDelete}
                  message={t('confirmDeleteViz', { name: graph.name })}
                  size="small"
                  objectToReturn={[]}
                  btnVariant="outlined"
                  iconFontSize="small"
                />
              </PrivateComponent>
            </Box>
          </Box>
        )}

        <ErrorBoundary
          fallbackRender={() => <ErrorVizFallback graph={graph} />}
          onReset={() => {
            setError(false);
          }}
          resetKeys={[error]}
        >
          {graph.type === 'curve' && <NanoCurveViz graph={graph} />}
          {graph.type === 'map' && <NanoMapViz graph={graph} preview={preview} />}
          {graph.type === 'table' && <NanoTableViz graph={graph} preview={preview} />}
        </ErrorBoundary>
      </CardContent>
    </Card>
  );
};

export default NanoDashboardGridItem;
