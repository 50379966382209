import React from 'react';
import PrivateRoute from 'shared/auth/private-route';
import products from './products';

export const readSiloProductRight = 'READ:DeviceContent';

export const siloProductsTo = '/products';


const Routes = () => (
  <div>
    <PrivateRoute path={siloProductsTo} exact component={products} hasRight={readSiloProductRight} />
  </div>
);

export default Routes;
