import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { IRootState } from 'config/store';
import React, { useEffect, useMemo, useState, useRef, RefObject,useCallback } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IGetGraphDataResponse, IGraphData, nanolikeDataType } from 'shared/model/api.model';
import { IDevice } from 'shared/model/device.model';
import { IGraph } from 'shared/model/graph.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { workspaceIsIbc } from 'shared/utils/workspace-utils';
import DataTable from 'shared/widgets/dataTable';
import DeviceName from 'shared/widgets/devices/deviceName';
import { columnFormatter, CompleteLevelFormatter, customSort, ibcDataTypeOrder, siloDataTypeOrder } from './nanoTableViz-utils';
import useInterval from 'shared/hooks/useInterval';
import { dataOnlyForFront } from '../form/graphFormSecondStep';
import { useHasRight } from 'shared/auth/auth-utils';


const apiUrl = getEnvApiUrl();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      // paddingTop: theme.spacing(1),
      // paddingBottom: theme.spacing(1),
      '&>div': {
        height: '100%',
        overflow: 'auto'
      }
    },
    link: {
      textDecoration: 'none',
      color: 'unset',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    icon: {
      width: '32px',
      height: '32px'
    }
  })
);

interface INanoTableVizState {
  loading: boolean;
  loadSuccess: boolean;
  errorMessage: string;
  graphData: IGraphData[];
}

const initialState: INanoTableVizState = {
  loading: false,
  loadSuccess: false,
  errorMessage: '',
  graphData: []
};

interface INanoTableVizProps {
  graph: IGraph;
  preview?: boolean;
}

const NanoTableViz = (props: INanoTableVizProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const groups = useSelector(({ group }: IRootState) => group.groups);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isIbc = workspaceIsIbc(settings);

  const [state, setState] = useState<INanoTableVizState>(initialState);
  const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(300);
  const { graph, preview = false } = props;
  const BoxCanvasRef : RefObject<HTMLDivElement> = useRef(null);
  const hasAdminRight = useHasRight('READ:menu_admin')


   // useEffect will run on stageCanvasRef value assignment
    useEffect( () => {

        // The 'current' property contains info of the reference:
        // align, title, ... , width, height, etc.
        if( BoxCanvasRef?.current?.offsetHeight){
            setFixedHeaderScrollHeight(BoxCanvasRef.current.offsetHeight)
            // let height = stageCanvasRef.current.offsetHeight;
            // let width  = stageCanvasRef.current.offsetWidth;
        }

    }, [BoxCanvasRef]);
   const loadGraphData =  useCallback(
      async () => {
        let ids = [...graph.device_ids];
      graph.group_ids.forEach((aGroupId: string) => {
        const group = groups.find(aGroup => aGroup.group_id === aGroupId);
        if (group && group.devices) {
          const deviceIds = group.devices.map(aDevice => aDevice.device_id);
          ids = ids.concat(deviceIds);
        }
      });

      const body: any = {
        data_types: graph.device_data_types.filter(d => !dataOnlyForFront.includes(d)),
        device_ids: ids,
        is_last_value: graph.is_last_value
      };
      if (!preview) {
        body.client_filter = graph.client_filter;
      }
      try {
        setState({
          ...initialState,
          loading: true
        });
        const response: AxiosResponse<IGetGraphDataResponse> = await axios.post(`${apiUrl}/get-graph-data`, body);
        const graphData = response.data.data;

        setState({
          graphData,
          loading: false,
          loadSuccess: true,
          errorMessage: ''
        });
      } catch (e) {
        setState({
          ...initialState,
          errorMessage: getRequestErrorMessage(e)
        });
      }
      },
      [groups, preview, graph]
    )
  useInterval(loadGraphData)

  const all = useMemo(() => {
    const deviceContent = {
      selector: 'device_content',
      name: t('device_content'),
      sortable: true
    }
    const location = {
      selector: 'farm_name',
      name: t('farm_name'),
      sortable: true,
      format: (row: IGraphData) => row.farm_name ?? '-'
    }
    const columns: IDataTableColumn<any>[] = [
      {
        selector: 'device_name',
        name: t('device_name'),
        sortable: true,
        minWidth: '240px',
        format: (row: IGraphData) => {
          const device = {
            device_id: row.device_id,
            device_name: row.device_name,
            device_reference: row.device_reference,
            status: row.status,
            is_combined: row.is_combined,
            is_silo: row.is_silo,
            is_tank: row.is_tank
          };
          return <DeviceName device={device as IDevice} />;
        }
      }
    ];
    // if indus we set content only if needed if not always
    if (props.graph.device_data_types.includes('device_content')) columns.push(deviceContent)
    if (props.graph.device_data_types.includes('location')){
      columns.push(location);
      // Artificially add the previous poi name in the data, we could also do backend side. 
      // But we use the fact that groups loaded are only allowed to the current User
      // For V2 all ackend side 
      if (isIbc && hasAdminRight) columns.push({
        selector: 'previous_poi_name',
        name: t('previous_location'),
        sortable: true,
        format: (row: IGraphData) => row.previous_poi_name ?? '-'
      });
    } 

    const typesColumns: any[] = [];
    const data = state.graphData.reduce((agg: any[], current) => {
      let row = agg.find(aRow => aRow.device_id === current.device_id);
      const type = current.data_type as nanolikeDataType;

      if (!row) {
        row = {
          ...current,
          device_status: current.status
        };
        agg.push(row);
      }
      if (row.previous_poi_id) row.previous_poi_name = groups.find(g => g.group_id === row.previous_poi_id)?.group_name
      if (type === 'levelPredictions') {
        row[type] = current.data_points
        current.data_points.forEach((_,i) => { // we assume predictions are well ordered
          const dataType = 'levelJPlus' + (i+1) as nanolikeDataType
            if (!typesColumns.some(aColumn => aColumn.id === dataType)) {
              typesColumns.push({
                id: dataType,
                selector: (r: any) => r.levelPredictions[i]?.level_percent,
                name: t(dataType),
                center: true,
                sortable: true,
                format: (row: any) => <CompleteLevelFormatter level={row.levelPredictions[i]} status={row.device_status}/>
              });
            }
        });
      } else {
        if (!typesColumns.some(aColumn => aColumn.selector === type)) {
          const column = {
              selector: type,
              name: t(type),
              center: true,
              sortable: true,
              format: type === 'level' ? (row: any) => <CompleteLevelFormatter level={row.level} status={ row.device_status } /> : columnFormatter(current, t)
            }
          typesColumns.push(column);
        }
        row[type] = current.data_points[0];
      }

      return agg;
    }, []);

    // #44 - The order of data types is predefined.
    const dataTypesOrder = isIbc ? ibcDataTypeOrder : siloDataTypeOrder;

    const orderedTypesColumns: any[] = [];
    dataTypesOrder.forEach(aDataTypeOrder => {
      const column = typesColumns.find(aColumn => aColumn.selector === aDataTypeOrder || aColumn.id === aDataTypeOrder);
      if (column) {
        orderedTypesColumns.push(column);
      }
    });
    typesColumns.forEach(aColumn => {
      if (!orderedTypesColumns.some(orderedColumn => orderedColumn.selector === aColumn.selector)) {
        orderedTypesColumns.push(aColumn);
      }
    });

    return { columns: [...columns, ...orderedTypesColumns], data };
  }, [isIbc, state.graphData, t, props.graph.device_data_types, groups, hasAdminRight]);

  return (
    //@ts-ignore
    <Box className={classes.root} ref={BoxCanvasRef}>
      <DataTable
        columns={all.columns}
        data={all.data}
        defaultSortField="device_name"
        sortFunction={customSort}
        progressPending={state.loading}
        pagination={false}
        fixedHeader
        fixedHeaderScrollHeight={`${fixedHeaderScrollHeight -32}px`}
        persistTableHead={false}
        noHeader={true}
      />
    </Box>
  );
};

export default NanoTableViz;
