import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { IRootState } from 'config/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { createGroup, updateGroup } from 'shared/reducers/groupSlice';
import { usePrevious } from 'shared/utils/hook-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import Loading from 'shared/widgets/loading';
import { workspaceIsSilo } from 'shared/utils/workspace-utils';
import GroupForm from './groupForm';
import { IDevice } from 'shared/model/device.model';
import axios, { AxiosResponse } from 'axios';
import { IGroup } from 'shared/model/group.model';
import { getEnvApiUrl } from 'config/env';

export interface IGroupFormResponse {
  group_name: string;
  client_poi_id:string;
  devices: ILabelValueOption<IDevice[]>[];
  order_recipient_emails?: string[];
  location?: any
}

const CreateOrEditGroup = () => {
  const dispatch = useDispatch();
  const updateSuccess = useSelector(({ group }: IRootState) => group.updateSuccess);
  const previousUpdateSuccess = usePrevious(updateSuccess);
  const loadingDevices = useSelector(({ devices }: IRootState) => devices.loading);
  // const group = useSelector(({ group }: IRootState) => group.group);
  const [group, setGroup] = useState<IGroup | null>(null);
  const loading = useSelector(({ group }: IRootState) => group.loading);
  const history = useHistory();
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isSilo = workspaceIsSilo(settings);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    async function fetchData() {
      if (id && id !== 'new') {
        const response: AxiosResponse<IGroup> = await axios.get(`${getEnvApiUrl()}/groups/${id}/`);
        setGroup(response.data);
      }
    }
    fetchData()
  }, [dispatch, id]);

  useEffect(() => {
    if (previousUpdateSuccess === false && updateSuccess === true) {
      history.push('/groups');
    }
  }, [history, previousUpdateSuccess, updateSuccess]);

  const onSubmit = (responses: IGroupFormResponse) => {
    const groupFromResponse = getGroupFromResponse(responses);
    if (id === 'new') {
      dispatch(createGroup({...groupFromResponse, is_poi: isSilo ? false: true}));
    } else {
      const toUpdate = {
        group_id: group?.group_id,
        ...groupFromResponse
      };
      dispatch(updateGroup(toUpdate));
    }
  };

  return (
    <Box p={1}>
      <Paper elevation={3}>
        {loadingDevices || loading ? (
          <Box p={4}>
            <Loading />
          </Box>
        ) : (
          <>{(id === 'new' || group) && <GroupForm onSubmit={onSubmit} group={group} />}</>
        )}
      </Paper>
    </Box>
  );
};

export default CreateOrEditGroup;

const getGroupFromResponse = (responses: IGroupFormResponse) => {
  const group: any = {
    ...responses
  };

  group.devices =responses.devices ? responses.devices.map(item => ({ device_id: item.value })) : [];

  return group;
};
