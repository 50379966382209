import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDevice } from 'shared/model/device.model';
import LabelValue from 'shared/widgets/labelValue';

interface ICapaMaxFieldProps {
  device: IDevice;
  showLabel?: boolean;
}

const CapaMaxField = (props: ICapaMaxFieldProps) => {
  const { device, showLabel = false } = props;
  const { t } = useTranslation();

  const value = device.farmer_capa_max ? device.farmer_capa_max : device.capa_max ? device.capa_max : null;
  if (!value) {
    return null;
  }

  if (showLabel) {
    return <LabelValue 
      label={t('capa_max')} 
      value={t('number_workspace_filling_unit', { value : value})} 
    />;
  }
  return <span>{t('number_workspace_filling_unit', {value : value})}</span>;
};

export default CapaMaxField;
