import TextField, { StandardTextFieldProps, TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext, Validate } from 'react-hook-form';

export interface IMultipleTextFieldProps extends StandardTextFieldProps {
  name: string;
  defaultValue?: string[];
  required?: boolean;
  className?: string;
  limitTags?: number;
  disabled?: boolean;
  validate?: Validate;
}

const MultipleTextField = (props: IMultipleTextFieldProps) => {
  const { name, className, defaultValue, limitTags, disabled, required = false, validate, ...rest } = props;
  const form = useFormContext();
  const [selection, setSelection] = useState<string[]>([]);

  const values = form.getValues()[name] ? form.getValues()[name] : [];

  useEffect(() => {
    if (values.length !== selection.length) {
      setSelection(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.length]);

  useEffect(() => {
    form.register({ name }, { required, validate });
  }, [form, name, required, validate]);

  const onChange = (e: any, values: any) => {
    const newSelection = values as string[];
    setSelection(newSelection);
    form.setValue(name, newSelection);
  };

  return (
    <Controller
      control={form.control}
      name={name}
      as={props => (
        <Autocomplete
          multiple
          freeSolo
          autoSelect
          defaultValue={values}
          autoComplete={false}
          className={className}
          onChange={onChange}
          limitTags={limitTags}
          options={[]}
          disabled={disabled}
          renderInput={(params: TextFieldProps) => {
            return (
              <TextField
                {...params}
                {...rest}
                // autoComplete="off"
                name={name}
                required={required}
                error={form.errors[name] ? true : false}
                helperText={form.errors[name] && form.errors[name].message}
                disabled={disabled}
                // inputProps={{
                //   ...params.inputProps,
                //   autoComplete: 'disabled'
                // }}
              />
            );
          }}
        />
      )}
    />
  );
};

export default MultipleTextField;
