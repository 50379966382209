import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { IRootState } from 'config/store';
import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { IDevice } from 'shared/model/device.model';
import { IGraph } from 'shared/model/graph.model';
import { IGroup } from 'shared/model/group.model';
import { getTimeOffSetI18nKey, offsetStringToTime } from 'shared/utils/date-utils';
import { getDeviceLabel } from 'shared/utils/device-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { workspaceIsSilo } from 'shared/utils/workspace-utils';
import { IGraphFormResponse } from './createOrEditGraphForm';
import GraphFormFirstStep from './graphFormFirstStep';
import GraphFormSecondStep from './graphFormSecondStep';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    title: {
      textAlign: 'center'
    },
    stepper: {
      padding: '1rem',
      paddingBottom: '0.5rem',
      width: '50vw',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.up('lg')]: {
        width: '40vw'
      },
      [theme.breakpoints.down('sm')]: {
        width: '80%'
      }
    },
    content: {
      width: '100%',
      minHeight: '40vh'
    },
    btnbar: {
      '&>*': {
        marginRight: theme.spacing(1)
      }
    },
    divider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    btnDivider: {
      marginLeft: theme.spacing(2)
    }
  })
);

export interface IGraphFormProps {
  onSubmit: (responses: IGraphFormResponse) => void;
}

const GraphForm = (props: IGraphFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const graph = useSelector(({ graphs }: IRootState) => graphs.graph);
  const devices = useSelector(({ devices }: IRootState) => devices.devices);
  const groups = useSelector(({ group }: IRootState) => group.groups);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isSilo = workspaceIsSilo(settings);

  const [activeStep, setActiveStep] = useState<number>(0);
  const { id, dashboardId } = useParams<{ id: string; dashboardId: string }>();
  const isNew = id === 'new' ? true : false;
  const steps = [t('graph_step1'), t('graph_step2')];

  const initialValues = !isNew && graph ? initDefaultValues(graph, devices, groups, t, isSilo) : {};

  const form = useForm<IGraphFormResponse>({
    mode: 'onChange',
    defaultValues: initialValues
  });

  const onSubmit = form.handleSubmit(props.onSubmit);

  const handleNext = async () => {
    const result = await form.triggerValidation();
    if (result) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = async () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const titleKey = isNew ? 'create_your_graph' : 'edit_your_graph';

  return (
    <FormContext {...form}>
      <form className={classes.root} onSubmit={onSubmit} autoComplete="off">
        <Box className={classes.title}>
          <Typography variant="h5">{t(titleKey)}</Typography>
        </Box>
        <Grid container justify="center" alignItems="center">
          <Grid item className={classes.content}>
            <Box>
              <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Box pt={4}>
              <GraphFormFirstStep step={0} activeStep={activeStep} />
              <GraphFormSecondStep step={1} activeStep={activeStep} />
            </Box>
          </Grid>
        </Grid>
        <Divider variant="middle" className={classes.divider} />
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.btnbar}>
          <Button component={Link} to={`/dashboard/${dashboardId}`} startIcon={<CancelIcon />} variant="contained">
            {t('cancel')}
          </Button>
          <Divider orientation="vertical" className={classes.btnDivider} />
          <Button color="primary" variant="contained" onClick={handleBack} startIcon={<ArrowBackIcon />} disabled={activeStep === 0}>
            {t('prev')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleNext}
            endIcon={<ArrowForwardIcon />}
            disabled={activeStep === steps.length - 1}
          >
            {t('next')}
          </Button>
          <Button type="submit" color="primary" startIcon={<SaveIcon />} disabled={activeStep !== steps.length - 1} variant="contained">
            {t('save')}
          </Button>
        </Box>
      </form>
    </FormContext>
  );
};

export default GraphForm;

/**
 * Init form with default values if exists
 * @param graph
 * @param devices
 * @param groups
 * @param t
 */
const initDefaultValues = (graph: IGraph, devices: IDevice[], groups: IGroup[], t: TFunction, isSilo: boolean) => {
  const devicesSelected = devices.filter(aDevice => graph.device_ids.includes(aDevice.device_id));
  const groupsSelected = groups.filter(aGroup => graph.group_ids.includes(aGroup.group_id));

  let device_group = devicesSelected.map(aDevice => ({
    label: getDeviceLabel(aDevice, isSilo),
    value: aDevice.device_id,
    group: t('device',{count: 100})
  }));
  device_group = device_group.concat(
    groupsSelected.map(aGroup => ({ label: aGroup.group_name, value: aGroup.group_id, group: t('group') }))
  );

  const device_data_types = graph.device_data_types.map(aType => ({ label: t(`${aType}`), value: aType }));

  let start_offset_time: ILabelValueOption | undefined;
  if (graph.start_offset_time) {
    const value = offsetStringToTime(graph.start_offset_time);
    const label = t(getTimeOffSetI18nKey(value));
    start_offset_time = {
      label,
      value
    };
  }
  const metric_text = graph.metric_text;

  return {
    graph_name: graph.name,
    graphType: graph.type,
    device_group,
    device_data_types,
    start_offset_time,
    metric_text
  };
};
