import { Button, createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { Save, SyncAlt } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import React, {  useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import {  useSelector } from 'react-redux';
import { getEnvApiUrl } from 'config/env';
import DeviceGroup from '../deviceGroup.tsx';
import { sortOptionsByLabel } from '../../../shared/utils/data-utils';
import { Alert } from '@material-ui/lab';
import {Promise} from 'bluebird'

import SingleAutoComplete from '../../../shared/widgets/form/singleAutocomplete.tsx';
import NanolikeDatePicker from 'shared/widgets/form/nanolikeDatePicker';
import axios from 'axios';
const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up('sm')]: {
        minWidth: '600px',
        minHeight: '120px'
      }
    },
    autoComplete: {
      flexGrow: 2
    }
  })
);


const AssignPoi = ({devices, ...props}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [updating, setUpdating] = useState(false);
  const groups = useSelector(({ group }) => group.groups);
  const form = useForm();

  const { onSuccess } = props;
  const [open, setOpen] = useState(false);

  let actualPois = devices.map(d => d.groups.filter(g => g.is_poi)).flat()
  actualPois = actualPois.filter(
    (group, index) =>
      actualPois.findIndex((item) => item.group_id === group.group_id) === index
  );

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const onSubmit = form.handleSubmit(data => {
    setUpdating(true)
    Promise.map(devices, device => axios.post(`${getEnvApiUrl()}/devices/${device.device_id}/reassign-poi`, {poiId: data.group.value, galileou_enabled: false, poi_reassignation_date: data.poi_reassignation_date?.startOf("day").toISOString()})
    ).then(_ => {
      onSuccess()
      handleClose()
    }).finally(() => setUpdating(false))
  });

  // we only keep pois and remove the one from the current devices
  const groupOptions = groups.filter(g => g.is_poi).filter(g => !actualPois.map(g => g.group_id).includes(g.group_id)).reduce((agg, current) => {
      agg.push({
        label: current.group_name,
        value: current.group_id
      });
    return agg;
  }, []);
  groupOptions.sort(sortOptionsByLabel);
  console.log({form})
  return (
    <>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
        <FormContext {...form}>
        <form onSubmit={onSubmit} autoComplete="off">
          <DialogTitle>{t('poiReassignation')}</DialogTitle>
          <DialogContent className={classes.content} dividers>
            <div style={{display: 'flex', flexDirection: 'column', rowGap:8}}>
              <div style={{display: 'flex', flexDirection: 'column', width:'fit-content'}}>
                <Typography variant="h6">{t('actualPoi', {count: actualPois.length})}</Typography>
                {actualPois.length ? 
                <div style={{display: 'flex', width:'fit-content'}}>
                  {actualPois.map(poi => (<DeviceGroup group={poi} withCount={devices.filter(d => d.groups.map(g => g.group_id).includes(poi.group_id)).length} />))}
                </div>
                : <Typography variant="body2">{t('noActualPois', {count: devices.length})} </Typography>
                }
              </div>
              <div style={{display: 'flex', gap:16, justifyContent:'space-between'}}>
              <SingleAutoComplete
                label={t('destinationPoi')}
                name="group"
                options={groupOptions}
                autoFocus
                className={classes.autoComplete}
                validate={(value) => {
                  if (!value || value.length === 0) {
                    return <Trans i18nKey="required_field">Required Field</Trans>;
                  }
                  return true;
                }}
              />
              <NanolikeDatePicker fullWidth={false} name="poi_reassignation_date" label={t('poiReassignationDate')} value={new Date()} />
              </div>
              <Alert severity="warning">{t('assignPoiAlert')}</Alert>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}  color="default" startIcon={<CancelIcon />} disabled={updating}>
              {t('cancel')}
            </Button>
            <Button type="submit" color="primary" startIcon={<Save />} disabled={updating}>
              {t('save')}
            </Button>
          </DialogActions>
        </form>
        </FormContext>
      </Dialog>
      <Button variant="contained" color="primary" onClick={handleOpen} title={t('poiReassignation')}>
        <SyncAlt />
      </Button>
    </>
  );
};

export default AssignPoi;
