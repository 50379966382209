import axios from 'axios';
import { logout } from 'shared/reducers/authenticationSlice';

const axiosUtils = {
  setupInterceptors: (store: any) => {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response && error.response.status === 401) {
          store.dispatch(logout());
        }

        return Promise.reject(error);
      }
    );
  }
};

export default axiosUtils;

export const getRequestErrorMessage = (error: any) => {
  if (error.response) {
    const data = error.response.data;
    if (data.code) {
      return data.code;
    }
  }
  return error.message;
};
