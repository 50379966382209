import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { IRootState } from 'config/store';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PrivateComponent from 'shared/auth/privateComponent';
import { ICallback } from 'shared/model/callback.model';
import { deleteCallback, fetchCallbacks, updateCallback } from 'shared/reducers/callbacksSlice';
import ConfirmDelete from 'shared/widgets/confirmDelete';
import DataTable from 'shared/widgets/dataTable';
import TitleWithSearchField from 'shared/widgets/titleWithSearchField';
import CallbackDetails from './callbackDetails';
import { APP_TIMESTAMP_FORMAT, formatDate } from 'shared/utils/date-utils';
import { displayUser } from 'shared/utils/user-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contextToolBar: {
      '&>*': {
        marginLeft: theme.spacing(1)
      }
    },
    icon: {
      width: '32px',
      height: '32px'
    }
  })
);

const Callbacks = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const callbacks = useSelector(({ callbacks }: IRootState) => callbacks.callbacks);
  const loading = useSelector(({ callbacks }: IRootState) => callbacks.loading);
  const [selectedRows, setSelectedRows] = useState<ICallback[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filterText, setFilterText] = React.useState('');

  const columns: IDataTableColumn<ICallback>[] = useMemo(() => {
    const handleStatusChange = (rule: ICallback) => () => {
      const toUpdate = {
        is_enabled: !rule.is_enabled,
        id: rule.id
      };
      dispatch(updateCallback(toUpdate, true));
    };
    return [
      {
        selector: 'name',
        name: t('name'),
        sortable: true,
        grow: 2
      },
      {
        selector: 'url',
        name: 'URL',
        sortable: true,
        grow: 3,
        hide: 'md'
      },
      {
        selector: 'ctype',
        name: t('ctype'),
        sortable: true,
        grow: 1,
        hide: 'md',
        format: (row: ICallback) => {
          if (row.notification_choice) {
            return t(`${row.notification_choice}`);
          }
          return null;
        }
      },
      {
        selector: 'devices',
        name: t('device', { count: 100 }),
        sortable: true,
        grow: 2,
        hide: 'md',
        format: (row: ICallback) => row.devices.map(deviceInfo => <Box key={deviceInfo.device_id}>{deviceInfo.device_name}</Box>)
      },
      {
        selector: 'groups',
        name: t('group', { count: 100 }),
        sortable: true,
        grow: 2,
        hide: 'md',
        format: (row: ICallback) => row.groups.map(group => <Box key={group.group_id}>{group.group_name}</Box>)
      },
      {
        selector: 'created_at',
        name: t('created_at'),
        sortable: true,
        grow: 1,
        hide: 'md',
        format: (row: ICallback) => formatDate(row.created_at, APP_TIMESTAMP_FORMAT)
      },
      {
        selector: 'createdBy',
        name: t('created_by'),
        sortable: true,
        grow: 1,
        hide: 'md',
        format: (row: ICallback) => displayUser(row.createdBy)
      },
      {
        selector: 'is_enabled',
        name: t('status'),
        sortable: true,
        grow: 2,
        format: (row: ICallback) => {
          return (
            <PrivateComponent hasRight="UPDATE:Callback" otherwise={<Switch checked={row.is_enabled} color="primary" />}>
              <Switch checked={row.is_enabled} color="primary" onChange={handleStatusChange(row)} />
            </PrivateComponent>
          );
        }
      }
    ];
  }, [dispatch, t]);

  useEffect(() => {
    dispatch(fetchCallbacks());
  }, [dispatch]);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const actions = (
    <PrivateComponent hasRight="CREATE:Callback">
      <Button color="primary" variant="contained" component={Link} to="/callback/new" size="small">
        <AddIcon />
      </Button>
    </PrivateComponent>
  );

  const contextActions = useMemo(() => {
    const onSuccess = () => {
      dispatch(fetchCallbacks());
      setToggleCleared(!toggleCleared);
    };

    const handleDelete = async () => {
      for (let i = 0; i < selectedRows.length; i++) {
        await dispatch(deleteCallback(selectedRows[i]));
      }
      onSuccess();
    };

    return (
      <Box className={classes.contextToolBar}>
        {selectedRows.length === 1 && (
          <PrivateComponent hasRight="UPDATE:Callback">
            <Button color="primary" variant="contained" component={Link} to={`/callback/${selectedRows[0].id}`}>
              <EditIcon />
            </Button>
          </PrivateComponent>
        )}
        <PrivateComponent hasRight="DELETE:Callback">
          <ConfirmDelete onConfirm={handleDelete} objectToReturn={selectedRows} size="small" />
        </PrivateComponent>
      </Box>
    );
  }, [classes.contextToolBar, dispatch, selectedRows, toggleCleared]);

  const title = React.useMemo(() => {
    const onChange = (filter: string) => {
      setFilterText(filter);
      if (filter.length === 0) {
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };

    return (
      <TitleWithSearchField title={t('callback', { count: 100 })} placeholder={t('callbacks_searchPlaceholder')} onChange={onChange} autoFocus />
    );
  }, [resetPaginationToggle, t]);

  const data =
    callbacks.length > 0
      ? callbacks.filter(item => {
        const name = item.name.toLocaleLowerCase();
        const filter = filterText.toLocaleLowerCase();
        return name.includes(filter);
      })
      : callbacks;

  return (
    <Box p={1}>
      <DataTable
        title={title}
        columns={columns}
        data={data}
        // Could not find a way to enable fixed header, without causing nested scrolls
        // when the callback triggers data is too big
        fixedHeader={false}
        selectableRows
        defaultSortField="name"
        progressPending={loading}
        paginationPerPage={10}
        onSelectedRowsChange={handleRowSelected}
        expandableRows
        expandableRowsComponent={<CallbackDetails />}
        expandOnRowClicked
        expandableRowsHideExpander
        actions={actions}
        contextActions={contextActions}
        clearSelectedRows={toggleCleared}
      />
    </Box>
  );
};

export default Callbacks;
