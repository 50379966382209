import { IRootState } from 'config/store';
import Alerts from 'modules/alerts/alerts';
import CreateOrEditAlertForm from 'modules/alerts/form/createOrEditAlertForm';
import Callbacks from 'modules/callbacks/callbacks';
import CreateOrEditCallback from 'modules/callbacks/form/createOrEditCallback';
import Dashboard from 'modules/dashboard/dashboard';
import CreateOrEditGraphForm from 'modules/dashboard/form/createOrEditGraphForm';
import Dashboards from 'modules/dashboards/dashboards';
import Device from 'modules/devices/device';
import Devices from 'modules/devices/devices';
import CreateOrEditGroup from 'modules/groupsDevices/form/createOrEditGroup';
import GroupsDevices from 'modules/groupsDevices/groups';
import Home from 'modules/home/home';
import KpiForm from 'modules/kpi/kpiForm';
import Products from 'modules/products/products';
import UserPassword from 'modules/profile/userPassword';
import UserProfile from 'modules/profile/userProfile';
import CreateOrEditRule from 'modules/rules/form/createOrEditRule';
import Rules from 'modules/rules/rules';
import { siloOrdersTo } from 'modules/siloOrders';
import Users from 'modules/users/users';
import React from 'react';
import Loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from 'shared/auth/private-route';
import ErrorBoundaryRoute from 'shared/error/errorBoundaryRoute';
import { workspaceIsIndus, workspaceIsSilo } from 'shared/utils/workspace-utils';
import Loading from 'shared/widgets/loading';

const SiloOrders = Loadable({
  loader: () => import(/* webpackChunkName: "siloOrders" */ 'modules/siloOrders'),
  loading: () => <Loading />
});

const Routes = () => {
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isSilo = workspaceIsSilo(settings);
  const isIndus = workspaceIsIndus(settings);

  return (
    <Switch>
      <PrivateRoute
        path="/dashboard/:dashboardId/graph/:id"
        component={CreateOrEditGraphForm}
        hasRight="UPDATE:Graph" />
      <ErrorBoundaryRoute path="/dashboard/:id" component={Dashboard} />
      <PrivateRoute path="/dashboards" component={Dashboards} hasRight="READ:Dashboard" />
      <PrivateRoute path="/users" component={Users} hasRight="READ:User" />
      <PrivateRoute path="/rule/:id" component={CreateOrEditRule} hasRight="UPDATE:Rule" />
      <PrivateRoute path="/rules" component={Rules} hasRight="READ:Rule" />
      <PrivateRoute path="/alerts/:id" component={CreateOrEditAlertForm} hasRight="UPDATE:Alert" />
      <PrivateRoute path="/alerts" component={Alerts} hasRight="READ:Alert" />
      <PrivateRoute path="/devices/:id" component={Device} hasRight="READ:Device" />
      <PrivateRoute path="/devices" component={Devices} hasRight="READ:Device" />
      <PrivateRoute path="/callbacks" component={Callbacks} hasRight="READ:Callback" />
      <PrivateRoute path="/callback/:id" component={CreateOrEditCallback} hasRight="UPDATE:Callback" />
      <PrivateRoute path="/groups/" component={GroupsDevices} hasRight="READ:Group" />
      <PrivateRoute path="/group/:id" component={CreateOrEditGroup} hasRight="UPDATE:Group" />
      {isIndus && <PrivateRoute path="/products" component={Products} hasRight="UPDATE:DeviceContent" />}
      <PrivateRoute path="/kpi" component={KpiForm} hasRight="READ:Kpi" />
      <ErrorBoundaryRoute path="/user-profile" exact component={UserProfile} />
      <ErrorBoundaryRoute path="/user-password" exact component={UserPassword} />
      {isSilo && <PrivateRoute path={siloOrdersTo} component={SiloOrders} hasRight="READ:Order" />}
      <Route path="/" exact render={Home} />
      <Route component={Home} />
    </Switch>
  );
};

export default Routes;
