import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import axios from 'axios';
import { getEnvApiUrl, getEnvWorkspace } from 'config/env';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { errorNotification } from 'shared/reducers/notifierSlice';
import Loading from 'shared/widgets/loading';
import ResetPasswordCard from './resetPasswordCard';
import LeftLanding from '../../shared/widgets/leftLanding.jsx'

const apiUrl = getEnvApiUrl();
axios.defaults.headers.common.WORKSPACE = getEnvWorkspace();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      height: `100vh`,
      '&>*': {
        width: '100%'
      }
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }
  })
);

const ResetPassword = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        await axios.get(`${apiUrl}/reset-user-password?token=${token}`);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        dispatch(errorNotification(t('error')));
        history.push('/');
      }
    };
    load();
  }, [dispatch, history, t, token]);

  return (
    <Grid container className={classes.root} spacing={0}>
      <LeftLanding />
      <Grid item sm={6}>
        <Box className={classes.box} p={4}>
          {loading ? <Loading /> : <ResetPasswordCard />}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
