import { CircularProgress, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'lodash/debounce';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        alignContent: 'flex-end'
      }
    },
    title: {
      marginRight: theme.spacing(3),
      paddingBottom: theme.spacing(0.5)
    },
    searchField: {
      marginTop: 0,
      width: '220px !important',
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        width: 'initial'
      }
    }
  })
);

interface ITitleWithSearchFieldProps {
  title: string;
  onChange: (...args: any) => any;
  autoFocus?: boolean;
  searchLabel?: string;
  placeholder?: string;
  debounceWait?: number;
  loading?: boolean;
}

const TitleWithSearchField: FC<ITitleWithSearchFieldProps> = props => {
  const classes = useStyles();

  const { debounceWait = 0, loading = false } = props;

  const debounceOnChange = debounce(props.onChange, debounceWait);

  const onChange = (e: any) => {
    debounceOnChange(e.target.value);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>{props.title}</Box>
      <TextField
        className={classes.searchField}
        autoFocus={props.autoFocus}
        size="small"
        label={props.searchLabel}
        placeholder={props.placeholder}
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {loading ? (
                <CircularProgress size="18px" />
              ) : (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
      />
      {props.children}
    </Box>
  );
};

export default TitleWithSearchField;
