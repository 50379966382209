import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { IRootState } from 'config/store';
import React from 'react';
import { useSelector } from 'react-redux';
import { IProduct } from 'shared/model/product.model';
import ProductGroups from './ProductGroups';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'calc(100vw - 90px)',
      margin: theme.spacing(0.5),
      [theme.breakpoints.down('sm')]: {
        width: '100vw'
      }
    },
    leftMenuOpen: {
      width: 'calc(100vw - 235px)'
    },
    center: {
      textAlign: 'center'
    }
  })
);

export interface IProductDetailsProps {
  data?: IProduct;
}

const ProductDetails = (props: IProductDetailsProps) => {
  const product = props.data;
  const classes = useStyles();
  const leftMenuOpen = useSelector(({ layout }: IRootState) => layout.leftMenuOpen);

  if (!product) {
    return null;
  }

  return (
    <Box className={clsx(classes.root, { [classes.leftMenuOpen]: leftMenuOpen })}>
      <Paper elevation={2}>
        <Box p={1}>
          {product.groups.map(gr => (
            <ProductGroups key={gr.group_id} product={product} group={gr} />
          ))}
        </Box>
      </Paper>
    </Box>
  );
};

export default ProductDetails;
