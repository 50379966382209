import Box from '@material-ui/core/Box';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, RouteProps } from 'react-router-dom';

export const ErrorBoundaryRoute = ({ component: Component, ...rest }: RouteProps) => {
  const encloseInErrorBoundary = (props: any) => {
    //@ts-ignore
    const component = <Component {...props} />;
    return <ErrorBoundary fallbackRender={() => <Box>An unexpected error has occurred.</Box>}>{component}</ErrorBoundary>;
  };

  return <Route {...rest} render={encloseInErrorBoundary} />;
};

export default ErrorBoundaryRoute;
