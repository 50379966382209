import { TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { IRootState } from 'config/store';
import React, { useState, useEffect, useCallback} from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { IGroup } from 'shared/model/group.model';
import { isValidEmail } from 'shared/utils/data-utils';
import { getDeviceLabel } from 'shared/utils/device-utils';
import { workspaceIsSilo, workspaceUseApi } from 'shared/utils/workspace-utils';
import MultipleTextField from 'shared/widgets/form/multipleTextField';
import SelectDevices from 'shared/widgets/form/selectDevices';
import { IGroupFormResponse } from './createOrEditGroup';
import { isArray } from 'lodash';
import SearchBoxV2 from 'modules/groupsDevices/form/searchBoxV2';
import NanolikeMap from 'shared/widgets/map/nanolikeMap';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography'
import ExpandMore from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    title: {
      textAlign: 'center'
    },
    stepper: {
      padding: '1rem',
      paddingBottom: '0.5rem',
      width: '50vw',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.up('lg')]: {
        width: '40vw'
      },
      [theme.breakpoints.down('sm')]: {
        width: '80%'
      }
    },
    content: {
      width: '100%',
      minHeight: '40vh'
    },
    btnbar: {
      '&>*': {
        marginRight: theme.spacing(1)
      }
    },
    divider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    btnDivider: {
      marginLeft: theme.spacing(2)
    },
    details: {
      flexDirection: "column",
      gap: 8

    }
  })
);

export interface IGroupFormProps {
  onSubmit: (responses: IGroupFormResponse) => void;
  group: IGroup | null;
}
const getMapOptions = (maps: any) => ({
    mapTypeControl: true,
    mapTypeControlOptions: {
      mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID
      ]
    }
  })

const GroupForm = (props: IGroupFormProps) => {
	const [map, setMap] = useState();
  const classes = useStyles();
  const { t } = useTranslation();
  const group = props.group;
  const updating = useSelector(({ group }: IRootState) => group.updating);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isSilo = workspaceIsSilo(settings);
  const useApi = workspaceUseApi(settings);
  const [location, setLocation] = useState<any>(group?.location || null);
	const onLoad = (e: any) => setMap(e.map)
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);

  const handlePlacesChanged = useCallback((results: any) => {
			if (isArray(results) && results.length && results[0].geometry && results[0].geometry.location){ 
        setLocation(results[0].geometry.location.toJSON())
      }
      else setLocation(null)
	}, []);

  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new';

  const initialValues = !isNew && group ? initDefaultValues(group, isSilo) : ({} as any);

  const form = useForm<IGroupFormResponse>({
    defaultValues: initialValues
  });
  // useEffect(() => {
  //   if (group?.location) setLocation(group.location)
  // }, [group]);
  useEffect(() => { 
    console.log({location})
    markers.map(m => m.setMap(null))
    if (location) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(location);
      setMarkers([])
      const marker = new window.google.maps.Marker({
        position: location,
        map
      });
      setMarkers([...markers, marker])
      //@ts-ignore
      map?.fitBounds(bounds)
      //@ts-ignore
      map?.setZoom(16)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, map]);


  const onSubmit = form.handleSubmit(responses => props.onSubmit({...responses, location}));

  const title = isNew ? t('add_group') : t('edit_group');
  return (
    <FormContext {...form}>
      <form className={classes.root}  autoComplete="off">
        <Box className={classes.title}>
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Grid container justify="center" alignItems="center">
          <Grid item className={classes.content} xs={12} md={6} lg={4}>
            <Box mb={2}>
              <TextField
                autoFocus
                margin="dense"
                label={t('group_name')}
                fullWidth
                name="group_name"
                inputRef={form.register({
                  validate: value => {
                    if (value.length === 0) {
                      return <Trans i18nKey="required_field">Required Field</Trans>;
                    }
                    return true;
                  }
                })}
                error={form.errors.group_name ? true : false}
                helperText={form.errors.group_name && form.errors.group_name.message}
                disabled={updating}
              />
            </Box>
            <Box mb={2}>
              <SelectDevices name="devices" label={t('select_device', {count: 100})} disabled={updating} selectAll />
            </Box>
            {isSilo ? (
              <>
                <Box mb={2}>
                  <MultipleTextField
                    name="order_recipient_emails"
                    label={t('order_recipient_emails')}
                    limitTags={3}
                    disabled={updating}
                    validate={(emails: string[]) => {
                      if (emails) {
                        const wrongEmail = emails.find(email => !isValidEmail(email));
                        if (wrongEmail) {
                          return (
                            <Trans i18nKey="email_invalid" values={{ email: wrongEmail }}>
                              invalid email
                            </Trans>
                          );
                        }
                      }
                      return true;
                    }}
                  />
                </Box>
                {useApi && <Box mb={2}>
                  <TextField
                    margin="dense"
                    label={t('client_poi_id')}
                    fullWidth
                    name="client_poi_id"
                    inputRef={form.register()}
                    helperText={t('client_poi_id_helper_text')}
                    disabled={updating}
                  />
                </Box>
                }
              </>
            ): (<Accordion defaultExpanded={!!group?.location}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
              >
                <Typography>{t('add_optionnal_address')}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                {/* @ts-ignore */}
                <SearchBoxV2 handlePlacesChanged={handlePlacesChanged} baseAddress={location ? `${location.lat} ${location.lng}` : null}/> 
                <NanolikeMap onLoad={onLoad} getMapOptions={getMapOptions} height="350px"/>
              </AccordionDetails>
            </Accordion>
            )}
          </Grid>
        </Grid>
        <Divider variant="middle" className={classes.divider} />
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.btnbar}>
          <Button component={Link} to="/groups" startIcon={<CancelIcon />} variant="contained" disabled={updating}>
            <Hidden xsDown>{t('cancel')}</Hidden>
          </Button>
          <Divider orientation="vertical" className={classes.btnDivider} />
          <Button color="primary" startIcon={<SaveIcon />} variant="contained" disabled={updating} onClick={onSubmit}>
            <Hidden xsDown>{t('save')}</Hidden>
          </Button>
        </Box>
      </form>
    </FormContext>
  );
};

export default GroupForm;

/**
 * Init form with default values if exists
 * @param callback
 * @param devices
 * @param groups
 * @param t
 */
const initDefaultValues = (group: IGroup, isSilo: boolean) => {
  const deviceOptions = group.devices
    ? group.devices.map(aDevice => {
        return {
          label: getDeviceLabel(aDevice, isSilo),
          value: aDevice.device_id
        };
      })
    : [];

  const roleOption = group.user_role_in_group
    ? {
        label: group.user_role_in_group.group_role_name,
        value: group.user_role_in_group.group_role_name
      }
    : undefined;

  return {
    ...group,
    devices: deviceOptions,
    role: roleOption
  };
};
