import { store } from 'config/store';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { initAnalytics } from './shared/utils/analytics-utils';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const envDomainMap = {
    io: 'production',
    tech: 'preproduction',
    dev: 'develop',
    local: 'local'
};

const domain = window.location.hostname.includes('localhost') ? 'local' : window.location.hostname.split('.').pop(); // io, tech, dev

// @ts-ignore
console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}, env : ${envDomainMap[domain ]}`);

// prevent sending error from local
if (process.env.NODE_ENV !== 'development') {
  // init Analytics
  initAnalytics()

  // init Sentry
  Sentry.init({
    dsn: "https://1e38ec743c994dfca5742b69552e86fa@o1316695.ingest.sentry.io/4504377847775232",
    integrations: [new BrowserTracing()],
    // @ts-ignore
    environment: envDomainMap[domain || 'io'],
    release: process.env.REACT_APP_VERSION,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2
  });
}


ReactDOM.render(
  <Suspense fallback={null}>
    <HelmetProvider>
      <Provider store={store}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <App />
        </SnackbarProvider>
      </Provider>
    </HelmetProvider>
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
