import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import config, { IMapCenter } from 'config/config';
import { getEnvGoogleMapKey } from 'config/env';
import GoogleMapReact, { MapOptions, Maps } from 'google-map-react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IDataPointPositionValue, IGraphData } from 'shared/model/api.model';

const configCenter = config.map.defaultCenter;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    map: {
      minHeight: '200px',
      width: '100%'
    },
    clusterMarker: {
      color: '#fff',
      background: '#1978c8',
      borderRadius: '50%',
      padding: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
);

export interface INanolikeMapProps {
  data?: IGraphData[];
  center?: IMapCenter;
  onLoad?: Function;
  onChange?: Function;
  height?: string;
  getMapOptions?: ((maps: Maps) => MapOptions);
}

const NanolikeMap: React.FunctionComponent<INanolikeMapProps> = props => {
  const classes = useStyles();
  const mapRef = useRef();
  const { i18n } = useTranslation();

  const { data, center } = props;

  const positionData = data ? data.filter(aData => aData.data_type === 'position') : [];

  const defaultCenter = configCenter;
  if (positionData.length > 0) {
    const locationValue = positionData[0].data_points[0].value as IDataPointPositionValue;
    if (locationValue.position_latitude) {
      defaultCenter.lat = locationValue.position_latitude;
    }
    if (locationValue.position_longitude) {
      defaultCenter.lng = locationValue.position_longitude;
    }
  } else if (center) {
    defaultCenter.lat = center.lat;
    defaultCenter.lng = center.lng;
  }

  const onGoogleApiLoaded = (e: any) => {
    mapRef.current = e.map;
    if (props.onLoad) {
      props.onLoad(e);
    }
  };

  const onChange = (e: any) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const containerStyle = props.height ? { height: props.height, maxHeight: props.height } : undefined;
  const mapStyle = props.height ? undefined : { height: props.height, maxHeight: props.height };
  
  return (
    <Box className={classes.map} style={containerStyle}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: getEnvGoogleMapKey(),
          language: i18n.resolvedLanguage,
          region: i18n.resolvedLanguage,
          libraries: ['places', 'drawing', 'geometry']
        }}
        style={mapStyle}
        defaultCenter={defaultCenter}
        defaultZoom={config.map.defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onGoogleApiLoaded}
        onChange={onChange}
        options={props.getMapOptions}
      >
        {props.children}
      </GoogleMapReact>
    </Box>
  );
};

export default NanolikeMap;

export const loadMapScript = (lang: string) => {
  document.getElementById('google-maps-script')?.remove();
  // remove scripts from google without ids
  let scripts = document.head.getElementsByTagName("script");
  for (let i = scripts.length - 1; i >= 0; i--) {
        let scriptSource = scripts[i].getAttribute('src');
        if (scriptSource != null && scriptSource.includes('maps.google')) scripts[i].remove();
    }

  const key = getEnvGoogleMapKey();

  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `//maps.google.com/maps/api/js?key=${key}&libraries=places,drawing,geometry&sensor=false&language=${lang}`;
  script.id = 'google-maps-script';
  document.body.appendChild(script);
};
