import { Moment } from 'moment';
import { deviceType } from 'shared/widgets/devices/deviceType';
import { nanolikeDataType } from './api.model';
import { IDevice } from './device.model';
import { IGroup } from './group.model';

export type notificationStrategy = 'email' | 'push';
export const allNotificationStrategy: notificationStrategy[] = ['email', 'push'];

export type AlertAggregationType = 'sum' | 'avg' | null;

export interface IAlert {
  alert_id?: string;
  recipients_for_notifications_ids: string[];
  recipient_groups_for_notifications_ids: string[];
  alert_name: string;
  is_active: boolean;
  data_type: nanolikeDataType;
  min_value: number | null;
  max_value: number | null;
  consecutive_trigger_delay?: string;
  notification_strategy_names: notificationStrategy[];
  devices_to_check_ids?: string[];
  device_groups_to_check_ids?: string[];
  devices_to_check?: IDevice[];
  device_groups_to_check?: IGroup[];
  geofencing_radius?: number | null;
  geofencing_lat?: number | null;
  geofencing_lng?: number | null;
  geofencing_strategy?: 'in' | 'out';
  last_modified_timestamp?: Moment | undefined;
  last_checked_timestamp?: Moment | undefined;
  last_triggered_timestamp?: Moment | undefined;
  device_types?: deviceType[];
  aggregate_operation?: AlertAggregationType;
}

export interface IOffendingDeviceData {
  poi_name?: string;
  device: string;
  device_id: string;
  device_name: string;
  is_silo: boolean;
  is_tank: boolean;
  device_reference: string;
  id: number;
  offending_lat: number;
  offending_lng: number;
  offending_timestamp: Moment | undefined;
  offending_value: any;
}

export interface IAlertNotification {
  alert_id: string;
  data_type_from_alert: string;
  max_value_from_alert: number;
  min_value_from_alert: number;
  notification_id: string;
  notification_timestamp: Moment | undefined;
  notified_groups_ids: string[];
  notified_users_ids: string[];
  offending_devices: IOffendingDeviceData[];
  offending_group: string;
}
