import { TFunction } from 'i18next';

export const getHighchartsLang = (t: TFunction) => {
  return {
    noData: t('no_data_available'),
    loading: t('loading'),
    decimalPoint: t('decimalPoint'),
    downloadPNG: t('downloadPNG'),
    downloadJPEG: t('downloadJPEG'),
    downloadPDF: t('downloadPDF'),
    downloadSVG: t('downloadSVG'),
    downloadCSV: t('downloadCSV'),
    downloadXLS: t('downloadXLS'),
    viewFullscreen: t('viewFullscreen'),
    exportButtonTitle: t('exportButtonTitle'),
    printButtonTitle: t('printButtonTitle'),
    resetZoom: t('resetZoom'),
    resetZoomTitle: t('resetZoomTitle'),
    thousandsSep: t('thousandsSep'),
    viewData: t('viewData'),
    printChart: t('printChart'),
    exitFullscreen: t('exitFullscreen'),
    months: [
      t('january'),
      t('february'),
      t('march'),
      t('april'),
      t('may'),
      t('june'),
      t('july'),
      t('august'),
      t('september'),
      t('october'),
      t('november'),
      t('december')
    ],
    weekdays: [
      t('sunday'),
      t('monday'),
      t('tuesday'),
      t('wednesday'),
      t('thursday'),
      t('friday'),
      t('saturday')
    ]
  };
};
