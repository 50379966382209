import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { kpiTypes } from './kpiForm';
import KpiType from './kpiType';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    toggleButton: {
      height: '100%',
      width: '150px',
      '&.Mui-selected': {
        color: theme.palette.primary.main
      }
    },
    buttonGroup: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    icon: {
      width: '100%',
      height: '32px'
    }
  })
);

export interface IKpiFormFirstStepProps {
  step: number;
  activeStep: number;
}

const KpiFormFirstStep = (props: IKpiFormFirstStepProps) => {
  const { activeStep, step } = props;
  const { t } = useTranslation();
  const form = useFormContext();
  const classes = useStyles();
  const [type, setType] = useState(form.getValues().type);

  useEffect(() => {
    form.register(
      { name: 'type' },
      {
        validate: value => {
          if (activeStep === step && !value) {
            return false;
          }
          return true;
        }
      }
    );
  }, [activeStep, form, step]);

  const onTypeChange = (e: any, selection: any) => {
    form.setValue('type', selection);
    setType(selection);
  };

  return (
    <Box display={activeStep === step ? 'block' : 'none'} pt={2}>
      <Box display="flex" justifyContent="center" pt={6} pb={2}>
        <ToggleButtonGroup value={type} exclusive onChange={onTypeChange} className={classes.buttonGroup}>
          {kpiTypes.map(kpiType => {
            return (
              <ToggleButton value={kpiType} className={classes.toggleButton} key={kpiType} disabled={!['use', 'history'].includes(kpiType)}>
                <KpiType type={kpiType} />
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Box>
      <Box>{form.errors && form.errors.type && <Alert severity="error">{t('required_field')}</Alert>}</Box>
    </Box>
  );
};

export default KpiFormFirstStep;
