import { IRootState } from 'config/store';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { removeNotification } from 'shared/reducers/notifierSlice';

let displayed: string[] = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(({ notifier }: IRootState) => notifier.notifications || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const storeDisplayed = (id: string) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: string) => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(aNotif => {
      const { message, type, key, anchorOrigin, persist =false, action } = aNotif;

      if (displayed.includes(key)) return;
      const translatedMessage = t(message);

      enqueueSnackbar(translatedMessage, {
        key,
        anchorOrigin,
        persist,
        variant: type,
        action,
        onClose: (event, reason, myKey) => {
          dispatch(removeNotification(myKey as string));
        },
        onExited: (event, myKey) => {
          dispatch(removeNotification(myKey as string));
          removeDisplayed(myKey as string);
        }
      });
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, t]);

  return null;
};

export default Notifier;
