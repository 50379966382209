import { KeyboardDateTimePicker, KeyboardDateTimePickerProps } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { APP_TIMESTAMP_FORMAT } from 'shared/utils/date-utils';

export interface INanolikeDateTimePickerProps extends Partial<KeyboardDateTimePickerProps> {
  name: string;
  value: Moment | null;
  required?: boolean;
  format?: string;
}

const NanolikeDateTimePicker = (props: INanolikeDateTimePickerProps) => {
  const { name, required = false, value = null, format = APP_TIMESTAMP_FORMAT, fullWidth = true, ...rest } = props;

  const [date, setDate] = useState<Moment>(value as Moment);
  const form = useFormContext();

  useEffect(() => {
    const validate = (value: Moment) => {
      if (required && !value) {
        return <Trans i18nKey="required_field">Required Field</Trans>;
      } else if (props.disableFuture) {
        const toCompare = moment.isMoment(value) ? value : moment(value);
        if (toCompare.isAfter(moment())) {
          return <Trans i18nKey="required_past_date">Past date</Trans>;
        }
      }
      return true;
    };

    form.register({ name }, { validate });
  }, [form, name, props.disableFuture, required]);

  const handleChange = (value: any) => {
    setDate(value);
    form.setValue(name, value);
  };

  return (
    <KeyboardDateTimePicker
      format={format}
      ampm={false}
      fullWidth={fullWidth}
      value={date}
      onChange={handleChange}
      error={form.errors[name] ? true : false}
      helperText={form.errors[name] && form.errors[name].message}
      {...rest}
    />
  );
};

export default NanolikeDateTimePicker;
