import { useMediaQuery, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';

export interface IConfirmPromotionDialog {
  open: boolean;
  onConfirm: Function;
  onClose: Function;
  title: string;
  message: string;
}

const ConfirmPromotionDialog = (props: IConfirmPromotionDialog) => {
  const { title, message, open } = props;
  const { t } = useTranslation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    props.onClose();
  };

  const handleConfirm = () => {
    props.onConfirm();
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} variant="contained" color="default" startIcon={<CancelIcon />}>
          {t('cancel')}
        </Button>
        <Button onClick={handleConfirm} variant="contained" color="primary" startIcon={<DoneIcon />} autoFocus>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmPromotionDialog;
