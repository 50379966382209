import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

const inputProps = {
  step: 'any',
  min: 0
};
export interface ISelectMinProps {
  step: number;
  activeStep: number;
}

const SelectMax = (props: ISelectMinProps) => {
  const { activeStep, step } = props;
  const { t } = useTranslation();
  const form = useFormContext();
  const datatype = form.watch<string, string>('data_type');

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={6} lg={4}>
        <TextField
          margin="dense"
          id="max_value"
          fullWidth
          label={t('max_val')}
          type="number"
          name="max_value"
          inputProps={inputProps}
          inputRef={form.register({
            validate: value => {
              if (activeStep === step) {

                if (value.length === 0) {
                  return <Trans i18nKey="required_field">Required Field</Trans>;
                }
                else if (value < 0) {
                  return <Trans i18nKey="positive_integer_value">Required Field</Trans>;
                }
                if (value?.length > 0 && ( datatype.value === 'level_t' || datatype.value === 'missingWeight' )) {
                  if (!/^\d+(\.\d{1})?$/.test(value)) return <Trans i18nKey="alert_form_one_decimal_error">Required Field</Trans>;
                }
                if (value?.length > 0 && datatype.value !== 'level_t' && datatype.value !== 'missingWeight') {
                  if (!Number.isInteger(Number(value))) {return <Trans i18nKey="alert_form_min_or_max_integer_error">Required Field</Trans>;}
                }
              }
              return true;
            }
          })}
          error={form.errors.max_value ? true : false}
          helperText={form.errors.max_value && form.errors.max_value.message}
        />
      </Grid>
    </Grid>
  );
};

export default SelectMax;
