import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { IRootState } from 'config/store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IUser } from 'shared/model/user.model';
import UserGroupMemberShip from './userGroupMembership';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'calc(100vw - 90px)',
      margin: theme.spacing(0.5),
      [theme.breakpoints.down('sm')]: {
        width: '100vw'
      }
    },
    leftMenuOpen: {
      width: 'calc(100vw - 235px)'
    },
    center: {
      textAlign: 'center'
    }
  })
);

export interface IUserDetailsProps {
  data?: IUser;
}

const UserDetails = (props: IUserDetailsProps) => {
  const user = props.data;
  const classes = useStyles();
  const { t } = useTranslation();
  const leftMenuOpen = useSelector(({ layout }: IRootState) => layout.leftMenuOpen);

  if (!user) {
    return null;
  }

  return (
    <Box className={clsx(classes.root, { [classes.leftMenuOpen]: leftMenuOpen })}>
      <Paper elevation={2}>
        <Box p={1}>
          {user.group_memberships.map(aGroup => (
            <UserGroupMemberShip key={aGroup.group.group_id} groupMembership={aGroup} user={user} />
          ))}
          {user.group_memberships.length === 0 && <Box className={classes.center}>{t('not_found')}</Box>}
        </Box>
      </Paper>
    </Box>
  );
};

export default UserDetails;
