import { Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from '@material-ui/lab/Alert';
import { IRootState } from 'config/store';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ICredential, login, resetPassword } from 'shared/reducers/authenticationSlice';
import PasswordTextField from 'shared/widgets/form/passwordTextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      width: '20rem'
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
      margin: theme.spacing(1),
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    resetPasswordBtn: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    resetPasswordTitle: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    }
  })
);

const LoginCard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [forgotPassword, setForgotPassword] = useState<Boolean>(false);
  const dispatch = useDispatch();
  const authentication = useSelector(({ authentication }: IRootState) => authentication);
  const { register, handleSubmit } = useForm<ICredential>();
  const { loading, loginError, updating, errorMessage } = authentication;

  const onSubmit = handleSubmit(credential => {
    if (forgotPassword) {
      dispatch(resetPassword(credential.username, 'fr'));
    } else {
      dispatch(login(credential as ICredential));
    }
  });

  const toggleForgotPassword = () => {
    setForgotPassword(!forgotPassword);
  };

  return (
    <form className={classes.root} noValidate onSubmit={onSubmit}>
      <Card>
        <CardContent>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          {forgotPassword ? (
            <>
              <Typography variant="h6" className={classes.resetPasswordTitle}>
                {t('reset_password_title')}
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label={t('email_address')}
                name="username"
                autoComplete="email"
                inputRef={register({ required: true })}
                disabled={updating || loading}
                autoFocus
              />
            </>
          ) : (
            <>
              {loginError && <Alert severity="error">{t(errorMessage)}</Alert>}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label={t('email_address')}
                name="username"
                autoComplete="email"
                inputRef={register({ required: true })}
                disabled={updating || loading}
                autoFocus
              />
              <PasswordTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={t('password')}
                id="password"
                autoComplete="current-password"
                inputRef={register({ required: true })}
                disabled={updating || loading}
              />
            </>
          )}
        </CardContent>
        <CardActions>
          {forgotPassword ? (
            <Button color="primary" variant="contained" type="submit" fullWidth disabled={loading}>
              {t('reset_password')}
            </Button>
          ) : (
            <Button color="primary" variant="contained" type="submit" fullWidth disabled={loading}>
              {t('login')}
            </Button>
          )}
        </CardActions>
        {!forgotPassword ? (
          <Box p={2}>
            <Link href="#" onClick={toggleForgotPassword}>
              {t('forgot_my_password')}
            </Link>
          </Box>
        ) : (
          <Box p={2}>
            <Link href="#" onClick={toggleForgotPassword}>
              {t('go_back')}
            </Link>
          </Box>
        )}
      </Card>
    </form>
  );
};

export default LoginCard;
