import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { AppThunk } from 'config/store';
import { IDashboard, IDashboardCreateOrUpdate } from 'shared/model/dashboard.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { fetchGraphs } from './graphsSlice';
import { errorNotification } from './notifierSlice';

const initialState = {
  loading: false,
  updating: false,
  updateSuccess: false,
  dashboards: [] as IDashboard[],
  dashboard: null as IDashboard | null,
  errorMessage: ''
};

export type DashboardState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    fetchDashboardsStart: state => {
      state.loading = true;
    },
    fetchDashboardsFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    fetchDashboardsSuccess: (state, action: PayloadAction<IDashboard[]>) => {
      state.loading = false;
      state.dashboards = action.payload;
    },
    fetchDashboardSuccess: (state, action: PayloadAction<IDashboard>) => {
      state.loading = false;
      state.dashboard = action.payload;
    },
    updateStart: state => {
      state.errorMessage = '';
      state.updateSuccess = false;
      state.updating = true;
    },
    updateFailed: (state, action: PayloadAction<string>) => {
      state.updateSuccess = false;
      state.updating = false;
      state.errorMessage = action.payload;
    },
    updateDashboardSuccess: (state, action: PayloadAction<IDashboard>) => {
      state.updateSuccess = true;
      state.updating = false;
      state.dashboard = action.payload;
    },
    updateLayoutSuccess: state => {
      state.updateSuccess = true;
      state.updating = false;
    },
    deleteDashboardSuccess: state => {
      state.updateSuccess = true;
      state.updating = false;
      state.dashboard = null;
    },
    deleteGraphSuccess: state => {
      state.updateSuccess = true;
      state.updating = false;
    }
  }
});

export default slice.reducer;

//actions
const {
  fetchDashboardsStart,
  fetchDashboardsFailed,
  fetchDashboardsSuccess,
  updateStart,
  updateFailed,
  updateDashboardSuccess,
  deleteDashboardSuccess,
  fetchDashboardSuccess,
  updateLayoutSuccess,
  deleteGraphSuccess
} = slice.actions;

const apiUrl = getEnvApiUrl();

export const fetchDashboards = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchDashboardsStart());
    const response: AxiosResponse = await axios.get(`${apiUrl}/dashboards/`);
    dispatch(fetchDashboardsSuccess(response.data));
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(fetchDashboardsFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
  }
};

export const createDashboard =
  (toCreate: IDashboardCreateOrUpdate): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      const response: AxiosResponse = await axios.post(`${apiUrl}/dashboards/`, toCreate);
      dispatch(updateDashboardSuccess(response.data));
      dispatch(fetchDashboards());
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const updateDashboard =
  (dashboard: IDashboard, toUpdate: IDashboardCreateOrUpdate): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      const response: AxiosResponse = await axios.patch(`${apiUrl}/dashboards/${dashboard.dashboard_id}/`, {
        name: toUpdate.name
      });
      dispatch(updateDashboardSuccess(response.data));
      dispatch(fetchDashboards());
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const deleteDashboard =
  (dashboard: IDashboard, fetchDashboardsAfter = true): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      await axios.delete(`${apiUrl}/dashboards/${dashboard.dashboard_id}/`);
      dispatch(deleteDashboardSuccess());
      if (fetchDashboardsAfter) {
        dispatch(fetchDashboards());
      }
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const fetchDashboard =
  (id: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchDashboardsStart());
      const response: AxiosResponse<IDashboard> = await axios.get(`${apiUrl}/dashboards/${id}/?cacheBuster=${new Date().getTime()}`);
      const graphIds = response.data.graphs.map(aGraph => aGraph.graph_id);
      await dispatch(fetchGraphs(graphIds));
      dispatch(fetchDashboardSuccess(response.data));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(fetchDashboardsFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const deleteGraph =
  (id: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      await axios.delete(`${apiUrl}/graphs/${id}/`);
      dispatch(deleteGraphSuccess());
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const updateLayout =
  (dashboard_id: string, settings: any): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      await axios.put(`${apiUrl}/dashboards/${dashboard_id}/user-settings/`, {
        settings
      });
      dispatch(updateLayoutSuccess());
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };
