/* eslint-disable no-mixed-operators */
import passwordValidator from 'password-validator';
import { IUser } from 'shared/model/user.model';

export const displayUser = (user: IUser) => (user.is_invitation_pending ? user.email : `${user.first_name || ''} ${user.last_name || ''}`);

export const displayUserInitials = (user: IUser) => `${user.first_name && user.first_name.charAt(0) || ''}${user.last_name && user.last_name.charAt(0) || ''}`;

export const validPasswordStrength = (pwdToCheck: string) => {
  const schema = new passwordValidator();
  schema.has().uppercase();
  schema.has().lowercase();
  schema.has().digits();
  schema.is().min(8);

  return schema.validate(pwdToCheck);
};

export const userWithEmptyName = (user: IUser) => user.first_name?.length > 0 || user.last_name?.length > 0;
