import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Chip as MaterialChip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as CalibrateIcon } from '../../../shared/icons/CalibrateIcon.svg';
import CalibrateDialog from 'modules/devices/actions/calibrateDialog';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import DeliveryDialog from 'modules/devices/actions/deliveryDialog';
import { openChat } from '../../../shared/utils/chat.utils';
import { AcUnit, WifiOff } from '@material-ui/icons';
import { Typography } from '@material-ui/core';

// #region constants

const DeliveryChip = (device, t) => {
	return <Chip 
			label={t('missing_delivery')} 
			color={'#2E48A7'} 
			icon={<LocalShippingIcon/>} 
			device={device}
			dialog={DeliveryDialog}
	/>
}
const LevelChip = (device, t) => {
	return <Chip 
			label={t('missing_level')} 
			color={'#2E48A7'} 
			icon={<CalibrateIcon/>} 
			device={device}
			dialog={CalibrateDialog}
	/>
}
const SupportChip = ({t}) => {
	return <Chip 
			label={t('contact_support')} 
			color={'#E83E6D'} 
			icon={<PhoneEnabledIcon/>} 
			contrastColor={'#FCDCE5'}
			onClick={openChat}
	/>
}

const ProblemCause = ({ problem_cause, t}) => {
	let icon = <WifiOff fontSize='inherit' />
	if (problem_cause === 'trapped_in_ice')
		icon = <AcUnit fontSize='inherit' />
	return <Typography color="error" style={{gap: 5, display: 'flex', alignItems:'center', flexWrap: 'wrap'}} variant='body2'>{icon}{t(`devices_status.${problem_cause}.label`)}</Typography>
}

const infos = {
	missing_level: LevelChip,
	missing_level_delivery: (device, t) => <div style={{display: 'flex', flexDirection: 'column', paddingTop:3 , paddingBottom: 3, gap:5}}>
	 {DeliveryChip(device, t)}
	 {LevelChip(device, t)}
	</div>,
	missing_delivery: DeliveryChip
}
// #endregion

// #region styled-components
const Chip = (props) => {
		const [open, setOpen] = useState(false);
		const CustomDialog = props.dialog
		const {color, contrastColor, ...other} = props
    const StyledChip = withStyles({
        outlined: {
            color: color,
            border: `1px solid ${color}`,
						'&:hover': {
							backgroundColor: `${contrastColor ?? '#F3F5FC'} !important`
						}
        },	
        icon: {
            color:  props.color
        }
    })(MaterialChip);
    return <>
		{open && <CustomDialog device={props.device} open={open} handleClose={() =>setOpen(false)} />}
		<StyledChip onClick={props.onClick ?? (() => setOpen(true))} {...other} variant="outlined" size="small" fullWidth />
		</>
};


// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
	device: PropTypes.shape({
		metadata: PropTypes.shape({
			info_to_calibrate: PropTypes.string
		}),
		status: PropTypes.string.isRequired
	}).isRequired
};

const defaultProps = {};

/**
 * 
 */
const MissingInfoField = ({device}) => {
	const { info_to_calibrate, problem_cause } = device.metadata ?? {}
	const {t} = useTranslation()
	if (device.status === 'pending' && info_to_calibrate && Object.keys(infos).includes(info_to_calibrate)) return infos[info_to_calibrate](device, t)	
	if (device.status === 'problem' && ["out_of_data", "trapped_in_ice"].includes(problem_cause)) return <ProblemCause problem_cause={problem_cause} t={t} />
	if (["problem", "calibration_problem", "error", "level_problem"].includes(device.status)) return <SupportChip t={t}/>
	return t(`devices_status.${device.status}.label`)
}

MissingInfoField.propTypes = propTypes;
MissingInfoField.defaultProps = defaultProps;
// #endregion

export default MissingInfoField;
