import AssignmentIcon from '@material-ui/icons/Assignment';
import React from 'react';
import { MenuItemProps } from 'shared/layout/menu/menuItem';
import { readSiloProductRight, siloProductsTo } from '.';


const SiloProductMenu: MenuItemProps = {
  icon: < AssignmentIcon />,
  primary: 'products',
  to: siloProductsTo,
  hasRight: readSiloProductRight
}


export default SiloProductMenu;
