import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IRootState } from 'config/store';
import isNumber from 'lodash/isNumber';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { allNotificationStrategy, IAlert } from 'shared/model/alert.model';
import { getDeviceTypeOptions } from 'shared/widgets/devices/deviceType';
import { useLocalizedDataTypeWithUnit } from 'shared/utils/lang-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1)
    }
  })
);

export interface IAlertDetailContentProps {
  alert?: IAlert;
}

const AlertDetailContent = (props: IAlertDetailContentProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { localizedDataTypeWithUnit } = useLocalizedDataTypeWithUnit();
  const groups = useSelector(({ group }: IRootState) => group.groups);

  const alert = props.alert;

  if (!alert) {
    return null;
  }

  // TODO should manage several groups or devices

  const deviceNamesToCheck = alert?.devices_to_check ? alert.devices_to_check.map(item => item.device_name) : [];
  const groupNamesToCheck = alert?.device_groups_to_check ? alert.device_groups_to_check.map(item => item.group_name) : [];

  const min = alert.min_value;
  const max = alert.max_value;

  let toCheckName = '';
  deviceNamesToCheck.forEach(item => {
    if (toCheckName.length > 0) {
      toCheckName += ', ';
    }
    toCheckName += item;
  });
  groupNamesToCheck.forEach(item => {
    if (toCheckName.length > 0) {
      toCheckName += ', ';
    }
    toCheckName += item;
  });

  let valueIndicator;

  if (alert.data_type === 'position') {
    if (alert.geofencing_strategy === 'in') {
      valueIndicator = t('in_area');
    } else {
      valueIndicator = t('out_area');
    }
  } else if (isNumber(min) && !isNumber(max)) {
    valueIndicator = t('get_lower') + ' ' + min;
  } else if (!isNumber(min) && isNumber(max)) {
    valueIndicator = t('exceeds') + ' ' + max;
  } else {
    valueIndicator = t('out_interval') + ' [' + min + ', ' + max + ']';
  }

  const of = toCheckName ? t('of') + ' ' + toCheckName : '';

  let deviceTypes = '';
  if (groupNamesToCheck.length > 0) {
    deviceTypes = t('of') + ' ';
    let options = getDeviceTypeOptions(groups, t);
    if (alert.device_types) {
      options = options.filter(option => alert.device_types?.some(deviceType => option.value === deviceType));
    }
    if (options.length > 0) {
      deviceTypes += '"' + options.map(option => option.label) + '"';
    }
  }

  let send = '';
  if (allNotificationStrategy.every(item => alert.notification_strategy_names.includes(item))) {
    send = t('send_mail_and_push');
  } else if (alert.notification_strategy_names.includes('email')) {
    send = t('send_mail');
  } else if (alert.notification_strategy_names.includes('push')) {
    send = t('send_push');
  }

  return (
    <Box className={classes.root} data-tag="allowRowEvents">
      {send} {t('when')} "{localizedDataTypeWithUnit(alert.data_type)}" {of} {deviceTypes} {valueIndicator}
    </Box>
  );
};

export default AlertDetailContent;
