import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { IRootState } from 'config/store';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { nanolikeDataType } from 'shared/model/api.model';
import { graphType, IGraph } from 'shared/model/graph.model';
import { createGraph, fetchGraph, updateGraph } from 'shared/reducers/graphsSlice';
import { timeToOffsetString } from 'shared/utils/date-utils';
import { usePrevious } from 'shared/utils/hook-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import Loading from 'shared/widgets/loading';
import GraphForm from './graphForm';

export interface IGraphFormResponse {
  graph_name: string;
  graphType: graphType;
  device_data_types: ILabelValueOption | ILabelValueOption[];
  metric_text?: string;
  start_offset_time?: ILabelValueOption;
  device_group: ILabelValueOption | ILabelValueOption[];
}

const CreateOrEditGraphForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const updateSuccess = useSelector(({ graphs }: IRootState) => graphs.updateSuccess);
  const previousUpdateSuccess = usePrevious(updateSuccess);
  const graph = useSelector(({ graphs }: IRootState) => graphs.graph);
  const loading = useSelector(({ graphs }: IRootState) => graphs.loading);
  const loadingDevices = useSelector(({ devices }: IRootState) => devices.loading);
  const history = useHistory();
  const { id, dashboardId } = useParams<{ id: string; dashboardId: string }>();

  const isNew = id === 'new' ? true : false;
  const groupGroup = t('group');

  useEffect(() => {
    if (!isNew && id) {
      dispatch(fetchGraph(id));
    }
  }, [dispatch, id, isNew]);

  useEffect(() => {
    if (previousUpdateSuccess === false && updateSuccess) {
      history.push(`/dashboard/${dashboardId}`);
    }
  }, [dashboardId, history, updateSuccess, previousUpdateSuccess]);

  const onSubmit = (responses: IGraphFormResponse) => {
    const selectedGraphType = responses.graphType;
    let dataTypes: nanolikeDataType[] = [];
    if (responses.device_data_types) {
      if (Array.isArray(responses.device_data_types)) {
        dataTypes = responses.device_data_types.map(option => option.value);
      } else {
        dataTypes.push(responses.device_data_types.value);
      }
    }

    if (selectedGraphType === 'map') {
      dataTypes = ['position'];
    }
    const name = responses.graph_name;

    const devices = Array.isArray(responses.device_group) ? responses.device_group : [responses.device_group];
    const metricText = responses.metric_text;
    const startOffsetTime = responses.start_offset_time ? responses.start_offset_time.value : undefined;

    const toCreateOrUpdate: IGraph = getGraphFromResponse(selectedGraphType, devices, groupGroup, dataTypes, metricText, startOffsetTime);
    toCreateOrUpdate.name = name;
    toCreateOrUpdate.dashboard_ids = [dashboardId as string];
    if (isNew) {
      dispatch(createGraph(toCreateOrUpdate));
    } else {
      const toUpdate = { graph_id: id, ...toCreateOrUpdate };
      dispatch(updateGraph(toUpdate));
    }
  };
  return (
    <Box p={1}>
      <Paper elevation={3}>
        {isNew || (!loading && !loadingDevices && graph) ? (
          <GraphForm onSubmit={onSubmit} />
        ) : (
          <Box p={4}>
            <Loading />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default CreateOrEditGraphForm;

export const getGraphFromResponse = (
  graphType: graphType,
  selectedDevicesOrGroups: ILabelValueOption[],
  groupLabel: string,
  dataType: nanolikeDataType[],
  metric_text: string | undefined,
  startOffsetTime: any
): IGraph => {
  const lastValue = graphType !== 'curve';

  const graph: IGraph = {
    is_last_value: lastValue,
    device_data_types: dataType,
    type: graphType,
    group_ids: [],
    device_ids: [],
    metric_text
  };

  if (graphType === 'curve') {
    const start_offset_time = timeToOffsetString(startOffsetTime);
    graph.start_offset_time = start_offset_time;
  }

  selectedDevicesOrGroups.forEach((selectedDeviceOrGroup: ILabelValueOption) => {
    if (selectedDeviceOrGroup.group === groupLabel) {
      graph.group_ids.push(selectedDeviceOrGroup.value as string);
    } else {
      graph.device_ids.push(selectedDeviceOrGroup.value as string);
    }
  });
  return graph;
};
