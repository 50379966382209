import {useRef,useEffect } from 'react';

import config from 'config/config';

// #region constants

function useInterval(callback, options) {
	const {delay = config.refreshGraphInterval, immediate= true} = options || {} 
  const savedCallback = useRef(callback)
  // Remember the latest callback if it changes.
  useEffect(() => {
    if (immediate && callback) callback()
		savedCallback.current = callback
  }, [immediate, callback])
  // Set up the interval.
  useEffect(() => {
    const id = setInterval(() => savedCallback.current(), delay)
    return () =>	clearInterval(id)}
  , [delay])
}

// #endregion

export default useInterval;
