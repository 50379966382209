import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import NanolikeMap from 'shared/widgets/map/nanolikeMap';
import SearchBox from 'shared/widgets/map/searchBox';

let circle: google.maps.Circle;

export interface IAlertFormMapProps {}

const AlertFormMap = (props: IAlertFormMapProps) => {
  const [map, setMap] = useState();
  const { t } = useTranslation();
  const form = useFormContext();
  const { id } = useParams<{ id: string }>();

  const isNew = id === 'new' ? true : false;

  const onDrawEnd = (newCircle: google.maps.Circle) => {
    if (circle) {
      circle.setMap(null);
    }
    circle = newCircle;
    setFormValues();
    circle.addListener('radius_changed', setFormValues);
    circle.addListener('center_changed', setFormValues);
  };

  const setFormValues = () => {
    const center = circle.getCenter();
    //@ts-ignore
    form.setValue('geofencing_lat', center.lat());
    //@ts-ignore
    form.setValue('geofencing_lng', center.lng());
    //@ts-ignore
    form.setValue('geofencing_radius', circle.getRadius());
  };

  const onLoad = (e: any) => {
    const { map, maps } = e;
    setMap(map);

    const drawingManagerConfig = {
      drawingControlOptions: {
        position: maps.ControlPosition.TOP_CENTER,
        drawingModes: ['circle']
      },
      circleOptions: {
        fillColor: '#5c27d8',
        fillOpacity: 0.3,
        strokeWeight: 1,
        clickable: false,
        editable: true,
        zIndex: 1
      }
    };

    const drawingManager = new maps.drawing.DrawingManager(drawingManagerConfig);
    drawingManager.setMap(map);

    maps.event.addListener(drawingManager, 'circlecomplete', onDrawEnd);

    if (!isNew) {
      const lat = Number(form.getValues().geofencing_lat);
      const lng = Number(form.getValues().geofencing_lng);
      const radius = Number(form.getValues().geofencing_radius);

      const circleOptions = {
        ...drawingManagerConfig.circleOptions,
        map,
        radius,
        center: {
          lat,
          lng
        }
      };
      circle = new maps.Circle(circleOptions);
      setTimeout(() => {
        map.fitBounds(circle.getBounds());
      }, 2000);
    }
  };

  const handlePlacesChanged = (results: google.maps.places.PlaceResult[]) => {
    if (results.length > 0 && map) {
      if (map && results[0].geometry) {
        const geometry = results[0].geometry;

        const bounds = new google.maps.LatLngBounds();
        if (geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(geometry.viewport);
        } else {
          bounds.extend(geometry.location);
        }

        //@ts-ignore
        map.fitBounds(bounds);
      }
    }
  };

  return (
    <Box marginTop={2}>
      <NanolikeMap onLoad={onLoad} height="350px"></NanolikeMap>
      <input name="geofencing_lat" ref={form.register({ required: true })} type="hidden" />
      <input name="geofencing_lng" ref={form.register({ required: true })} type="hidden" />
      <input name="geofencing_radius" ref={form.register({ required: true })} type="hidden" />

      <Box marginTop={2}>
        {form.errors && form.errors.geofencing_radius ? (
          <Alert variant="outlined" severity="error">
            {t('requiredGeofencing')}
          </Alert>
        ) : (
          <Alert variant="outlined" severity="info">
            {t('geofencing_desc')}
          </Alert>
        )}
      </Box>

      {map && <SearchBox placeholder={t('address')} onPlacesChanged={handlePlacesChanged} />}
    </Box>
  );
};

export default AlertFormMap;
