import { Box, Button, createStyles, makeStyles, Paper, Popover, Theme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { IRootState } from 'config/store';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import React, { useEffect, useMemo } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { nanolikeDataType } from 'shared/model/api.model';
import { IFilterCriterionValue, IGraph } from 'shared/model/graph.model';
import { updateGraphFilter } from 'shared/reducers/graphsSlice';
import { isNumeric } from 'shared/utils/data-utils';
import { useWorkspaceType } from 'shared/utils/workspace-utils';
import { isNumber } from 'util';
import { getDefaultValues, getFilters } from './filter-utils';
import FilterCriterion from './FilterCriterion';
import { criterionFormSeparator } from './filterGraph.model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeFilter: {
      color: '#4caf50'
    }
  })
);

export interface INanoDashboardGridItemFilterProps {
  graph: IGraph;
}

const NanoDashboardGridItemFilter = (props: INanoDashboardGridItemFilterProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const workspaceType = useWorkspaceType(settings);

  const { graph } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const all = getFilters(graph.type, workspaceType);

  all.sort((f1, f2) => {
    const l1 = t(f1.key).toLowerCase();
    const l2 = t(f2.key).toLowerCase();
    return l1.localeCompare(l2);
  });

  const defaultValues = useMemo(() => {
    const availableFilters = getFilters(graph.type, workspaceType);

    const filters = graph.client_filter ? graph.client_filter : [];

    const result: any = {};

    availableFilters.forEach(criterion => {
      const filter = filters.find(item => item.key === criterion.key);

      let defaultOperator: any = criterion.valueType === 'multiselect' ? 'in' : '<=';
      let defaultValue: any = '';
      if (filter) {
        defaultOperator = filter.operator;
        if (isArray(filter.value)) {
          defaultValue = filter.value.map(item => ({
            label: item,
            value: item
          }));
        } else if (isNumber(filter.value)) {
          defaultValue = filter.value;
        }
      }

      const operatorKey = `${criterion.key}${criterionFormSeparator}operator`;
      const valueKey = `${criterion.key}${criterionFormSeparator}value`;
      result[operatorKey] = defaultOperator;
      result[valueKey] = defaultValue;
    });
    return result;
  }, [graph.client_filter, graph.type, workspaceType]);

  const form = useForm({
    defaultValues
  });

  useEffect(() => {
    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetAll = () => {
    form.reset(getDefaultValues(graph.type, workspaceType));
    const newGraph: Partial<IGraph> = {
      graph_id: graph.graph_id,
      client_filter: []
    };

    dispatch(updateGraphFilter(newGraph));
  };

  const onSubmit = (responses: any) => {
    const availableFilters: IFilterCriterionValue[] = [];

    Object.keys(responses).forEach(aKey => {
      const value = responses[aKey];
      const split = aKey.split(criterionFormSeparator);
      const prefix = split[0];
      const suffix = split[1];

      let existingFilter = availableFilters.find(filter => filter.key === prefix);
      if (!existingFilter) {
        existingFilter = {
          key: prefix as nanolikeDataType,
          value: [] as any[]
        };
        availableFilters.push(existingFilter);
      }
      if (suffix === 'operator') {
        existingFilter.operator = value;
      } else if (suffix === 'value') {
        if (isNumeric(value)) {
          existingFilter.value = Number(value);
        } else if (isArray(existingFilter.value)) {
          if (isArray(value)) {
            if (value.length > 0) {
              if (value[0].value) {
                const valuesToAdd = value.map(item => item.value);
                existingFilter.value = existingFilter.value.concat(valuesToAdd);
              } else {
                existingFilter.value = existingFilter.value.concat(value);
              }
            } else {
              existingFilter.value = [];
            }
          } else {
            existingFilter.value.push(value);
          }
        }
      }
    });

    const filters = availableFilters.filter(item => {
      if (isArray(item.value)) {
        return item.value.some(filterValue => (isString(filterValue) ? filterValue.length > 0 : true));
      }
      return true;
    });

    const newGraph: Partial<IGraph> = {
      graph_id: graph.graph_id,
      client_filter: filters
    };

    dispatch(updateGraphFilter(newGraph));

    handleClose();
  };

  const open = Boolean(anchorEl);
  const isActive = graph && graph.client_filter && graph.client_filter.length > 0;

  if (all.length === 0) {
    return null;
  }

  return (
    <>
      <Button color="primary" size="small" variant="text" onClick={handleClick} className={isActive ? classes.activeFilter : undefined}>
        {isActive && <CheckIcon />}
        {t('filter_graph')}
      </Button>
      <Popover
        id={`graph_filter_${graph.graph_id}`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Paper elevation={3}>
          <Box padding={2}>
            <FormContext {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <Box>{t('filter_graph_title')}</Box>
                <Box>
                  {all.map(def => (
                    <FilterCriterion key={def.key} def={def} graph={graph} />
                  ))}
                </Box>
                <Box textAlign="right">
                  <Button type="button" variant="text" onClick={resetAll}>
                    {t('reset')}
                  </Button>
                  <Button type="submit" variant="text">
                    {t('apply')}
                  </Button>
                </Box>
              </form>
            </FormContext>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};

export default NanoDashboardGridItemFilter;
