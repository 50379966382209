import moment, { Moment } from 'moment';
import momentTz from 'moment-timezone';
import { IDevice } from 'shared/model/device.model';
import { ILabelValueOption } from './select-utils';

export const ONE_HOUR = 60 * 60;
export const ONE_DAY = ONE_HOUR * 24;
export const ONE_WEEK = ONE_DAY * 7;
export const TWO_WEEK = ONE_DAY * 14;
export const ONE_MONTH = ONE_DAY * 30;
export const THREE_MONTH = ONE_MONTH * 3;
export const FOUR_MONTH = ONE_MONTH * 4;

export const APP_DATE_FORMAT = 'L LT';
export const APP_TIMESTAMP_FORMAT = 'L LTS';
export const APP_LOCAL_DATE_FORMAT = 'L';

export const APP_SERVER_DATE_FORMAT = 'YYYY-MM-DD';

export const convertDateFromServer = (date: string | Moment | undefined) => (date ? moment(date) : undefined);

export const formatDate = (aDate: Moment | undefined | Date | number | string, format: string = APP_LOCAL_DATE_FORMAT) => {
  if (!aDate) {
    return '';
  }
  const date = (aDate instanceof moment ? aDate : moment(aDate)) as Moment;
  return date.format(format);
};

export const convertDatesToServer = (obj: any) => {
  for (let prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      if (obj[prop] instanceof moment) {
        obj[prop] = obj[prop].format(APP_SERVER_DATE_FORMAT);
      }
    }
  }
};

export const convertDateTimesToServer = (obj: any) => {
  for (let prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      if (obj[prop] instanceof moment) {
        obj[prop] = obj[prop].format();
      }
    }
  }
};

export const offsetStringToTime = (value: string) => {
  switch (value) {
    case '120 00:00:00':
      return FOUR_MONTH;
    case '90 00:00:00':
      return THREE_MONTH;
    case '30 00:00:00':
      return ONE_MONTH;
    case '14 00:00:00':
      return TWO_WEEK;
    case '7 00:00:00':
      return ONE_WEEK;
    case '1 00:00:00':
      return ONE_DAY;
    case '0 1:00:00':
      return ONE_HOUR;
    default:
      return ONE_HOUR;
  }
};

export const timeToOffsetString = (value: number) => {
  switch (value) {
    case FOUR_MONTH:
      return '120 00:00:00';
    case THREE_MONTH:
      return '90 00:00:00';
    case ONE_MONTH:
      return '30 00:00:00';
    case TWO_WEEK:
      return '14 00:00:00';
    case ONE_WEEK:
      return '7 00:00:00';
    case ONE_DAY:
      return '1 00:00:00';
    case ONE_HOUR:
      return '0 1:00:00';
    default:
      return '0 1:00:00';
  }
};

export const getTimeOffSetI18nKey = (value: number) => {
  switch (value) {
    case FOUR_MONTH:
      return 'four_month';
    case THREE_MONTH:
      return 'three_month';
    case ONE_MONTH:
      return 'one_month';
    case TWO_WEEK:
      return 'two_week';
    case ONE_WEEK:
      return 'one_week';
    case ONE_DAY:
      return 'one_day';
    default:
      return 'one_day';
  }
};

export const timestampToUnixtimestamp = (timestamp: string): number => moment(timestamp).unix() * 1000;

export const fasterTimestampToUnixtimestamp = (timestamp: string): number => new Date(timestamp).getTime();

export const getTimezonesNames = () => {
  const options: ILabelValueOption<string>[] = [];
  const names = momentTz.tz.names();
  for (const name of names) {
    if ((name.indexOf('/') < 0 && name !== 'UTC') || name.startsWith('Etc/')) {
      continue;
    }
    options.push({
      label: momentTz.tz(name).format('Z'),
      value: name
    });
  }
  options.sort((a, b) => {
    if (a.label === b.label) {
      if (a.value === 'UTC') {
        return -1;
      }
      return a.value === b.value ? 0 : a.value > b.value ? 1 : -1;
    }
    const afc = a.label.charAt(0);
    const bfc = b.label.charAt(0);
    if (afc === '-') {
      if (bfc === '+') {
        return -1;
      }
      return a.label > b.label ? -1 : 1;
    }
    if (bfc === '-') {
      return 1;
    }
    return a.label > b.label ? 1 : -1;
  });
  options.forEach(a => (a.label = `${a.value} (GMT ${a.label})`));
  return options;
};

export const getCurrentTimeZone = () => {
  const current = moment.tz.guess();

  return {
    label: `${current} (GMT ${momentTz.tz(current).format('Z')})`,
    value: current
  };
};

export const convertDeviceDateFromServer = (device: IDevice) => {
  device.device_last_delivery = convertDateFromServer(device.device_last_delivery);
  device.device_install_date = convertDateFromServer(device.device_install_date);
  device.device_fabrication_date = convertDateFromServer(device.device_fabrication_date);
  device.device_next_maintenance_date = convertDateFromServer(device.device_next_maintenance_date);
  device.last_seen = convertDateFromServer(device.last_seen);
  device.device_end_life_date = convertDateFromServer(device.device_end_life_date);
  device.device_next_delivery = convertDateFromServer(device.device_next_delivery);
  device.device_last_calibration = convertDateFromServer(device.device_last_calibration);
};
