import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import React from 'react';
import { MenuItemProps } from 'shared/layout/menu/menuItem';
import { readSiloOrdersRight, siloOrdersTo } from '.';


const SiloOrdersMenu: MenuItemProps = {
  icon: <LocalShippingIcon />,
  primary: 'orders.title',
  to: siloOrdersTo,
  hasRight: readSiloOrdersRight
};

export default SiloOrdersMenu;
