import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'config/store';
import { SnackbarAction, SnackbarOrigin } from 'notistack';
import { v4 as uuid } from 'uuid';

type NotificationType = 'default' | 'success' | 'error' | 'warning' | 'info';

export interface INotification {
  key: string;
  message: string;
  anchorOrigin?: SnackbarOrigin
  action?: SnackbarAction
  persist?: boolean,
  preventDuplicate?: boolean,
  type: NotificationType;
}

const initialState = {
  notifications: [] as INotification[]
};

export type NotifierState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'notifier',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<INotification>) => {
      state.notifications.push(action.payload);
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      const index = state.notifications.findIndex(aNotif => aNotif.key === action.payload);
      state.notifications.splice(index, 1);
    },
    clearNotifications: state => {
      //clean notifications
      state.notifications.length = 0;
    }
  }
});

export const { removeNotification, clearNotifications } = slice.actions;
const { addNotification } = slice.actions;

export const notification = (message: string, type?: NotificationType): AppThunk => dispatch => {
  const notification: INotification = {
    key: uuid(),
    message,
    type: type ? type : 'default'
  };
  return dispatch(addNotification(notification));
};
export const customNotification = (notification: INotification): AppThunk => dispatch => {
  return dispatch(addNotification(notification))
}
export const infoNotification = (message: string) => notification(message, 'info');

export const successNotification = (message: string) => notification(message, 'success');

export const warningNotification = (message: string) => notification(message, 'warning');

export const errorNotification = (message: string) => notification(message, 'error');

export default slice.reducer;
