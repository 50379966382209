import { Box, Button, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import { getEnvApiUrl } from 'config/env';
import axios from 'axios';
import moment, {Moment} from 'moment';
import React, {useState} from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IDevice } from 'shared/model/device.model';
import { errorNotification, successNotification } from 'shared/reducers/notifierSlice';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import NanolikeDateTimePicker from 'shared/widgets/form/nanolikeDateTimePicker';

const apiUrl = getEnvApiUrl();
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        width: '20rem'
      }
    }
  })
);

interface ICalibrateDialogProps {
  open: boolean;
  device: IDevice;
  delivery?: any;
  handleClose: () => void;
  onSucess?: () => void;
}
interface ISiloOrderFormResponse {
  date: Moment;
  tonnage: number;
};

const DeliveryDialog = (props: ICalibrateDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [saving, setSaving] = useState(false);
  const form = useForm<ISiloOrderFormResponse>({
    mode: 'onChange',
     defaultValues: {
      tonnage: props.delivery?.tonnage ?? 0,
      date: props.delivery?.date ?? moment().startOf("day")
    }
  });
  const save = async (responses: ISiloOrderFormResponse) => {
    const silos = [{
      id: props.device.device_id,
      tonnage: Number(responses.tonnage),
      content: { name: 'feed', type: 'ContentType' }
    }]

    const toSend = props.delivery ? {delivery_date: responses.date.toISOString(), delivery_quantity: Number(responses.tonnage), source: 'delivery'} : {
      bypass_send_email: true,
      date: responses.date.toISOString(),
      timeSlot: 'morning',
      source: 'delivery',
      silos
    };
    const call = props.delivery ? axios.patch(`${apiUrl}/orders/${props.delivery.id}`, toSend) : axios.post(`${apiUrl}/orders/`, toSend)
    try {
      setSaving(true);
      // @ts-ignore
      await call
      dispatch(successNotification(t('orders.saveSuccess')));
      setSaving(false);
      form.reset();
      if (props.onSucess) props.onSucess()
      props.handleClose()
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(errorNotification(errorMsg));
      setSaving(false);
    }
  };

  const capaMaxMessage = props.device.capa_max && form.watch('tonnage') && form.watch('tonnage') > props.device.capa_max ? (
    <Trans i18nKey="orders.errors.capaMax" values={{ max: props.device.capa_max }}>
      Capamax
    </Trans>
  ) : false
  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={props.handleClose}>
      <FormContext {...form}>
        <form className={classes.root} onSubmit={form.handleSubmit(save)} autoComplete="off">
          <DialogTitle>{t('orders.delivery')}</DialogTitle>
          <DialogContent>
            <Box>
               <TextField
                name={`tonnage`}
                type="number"
                label={t('string_workspace_filling_unit', { value: t('orders.tonnage')})}
                InputProps={{ inputProps: { step: 'any' } }}
                inputRef={form.register({
                  min: { value: 0.01, message: <Trans i18nKey="should_be_above_zero">Should be greater than 0</Trans> },
                  required: <Trans i18nKey="required_field">Required Field</Trans>,
                  validate: {
                    //@ts-ignore
                    doubleCapaMax: v => v < 2 * props.device.capa_max || <Trans i18nKey="orders.errors.capaMax" values={{ max: props.device.capa_max }}>Capamax</Trans>
                  }
                })}
                defaultValue="0"
                fullWidth
                disabled={saving}
                error={(form.errors.tonnage && form.errors.tonnage ? true : false) || !!capaMaxMessage }
                helperText={(form.errors.tonnage && form.errors.tonnage.message) || capaMaxMessage }
              />
            </Box>
            <Box>
              <NanolikeDateTimePicker
                name="date"
                value={props.delivery?.date ?? moment().startOf("day")}
                label={t('orders.date')}
                disabled={saving}
                required
                disableFuture
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={props.handleClose} variant="contained" color="default" startIcon={<CancelIcon />} disabled={saving}>
              {t('cancel')}
            </Button>
            <Button type="submit" variant="contained" color="primary" startIcon={<DoneIcon />} autoFocus disabled={saving}>
              {t('confirm')}
            </Button>
          </DialogActions>
        </form>
      </FormContext>
    </Dialog>
  );
};

export default DeliveryDialog;
