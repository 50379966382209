import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { IRootState } from 'config/store';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PrivateComponent from 'shared/auth/privateComponent';
import { ISiloOrderSilo } from 'shared/model/siloOrder.model';
import { fetchOrders } from 'shared/reducers/siloOrdersSlice';
import { APP_LOCAL_DATE_FORMAT, formatDate } from 'shared/utils/date-utils';
// import { getSupportEmail } from 'shared/utils/workspace-utils';
import DataTable from 'shared/widgets/dataTable';
import TitleWithSearchField from 'shared/widgets/titleWithSearchField';
import { newSiloOrdersTo, readSiloOrdersRight } from '.';
import { Edit } from '@material-ui/icons';
import DeliveryDialog from 'modules/devices/actions/deliveryDialog';
import ConfirmDelete from 'shared/widgets/confirmDelete';
import axios from 'axios';
import { getEnvApiUrl } from 'config/env';
import { DatePicker } from '@material-ui/pickers';

interface ISiloOrderRow {
  silo: ISiloOrderSilo;
  date: string | Moment | undefined;
  tonnage: number;
  source: 'export' | 'delivery' | 'order' | null;
  id: string;
}

const handleDelete = async (id: string) => {
  const apiUrl = getEnvApiUrl();
  await axios.delete(`${apiUrl.replace('v1','internal')}/calibration-delivery/${id}`)
}

const SiloOrders = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const siloOrders = useSelector(({ siloOrders }: IRootState) => siloOrders.siloOrders);
  // const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const loading = useSelector(({ siloOrders }: IRootState) => siloOrders.loading);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [openDeliveryeditionDialog, setOpenDeliveryeditionDialog] = useState(false);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(2, 'weeks').startOf('day'));

  const columns: IDataTableColumn<ISiloOrderRow>[] = useMemo(
    () => [
      {
        selector: 'silo.device_name',
        name: t('silo'),
        sortable: true,
        grow: 1,
        format: (row: ISiloOrderRow) => (
          <Box fontWeight="fontWeightBold">
            <Box key={row.silo.id}>{row.silo.device_name}</Box>
          </Box>
        )
      },
      {
        selector: 'silo.farm_name',
        name: t('farm_name'),
        sortable: true,
        grow: 1
      },
      {
        selector: 'date',
        name: t('orders.date'),
        grow: 1,
        sortable: true,
        format: (row: ISiloOrderRow) => formatDate(row.date, APP_LOCAL_DATE_FORMAT)
      },
      {
        selector: 'tonnage',
        name: t('string_workspace_filling_unit', { value: t('orders.tonnage')}),
        grow: 1,
        center: true,
        sortable: true,
        hide: 'md',
        format: (row: ISiloOrderRow) => t('number_workspace_filling_unit', { value: row.tonnage})
      },
      {
        selector: 'source',
        name: t('orders.source'),
        grow: 1,
        center: true,
        sortable: true,
        hide: 'md',
        format: (row: ISiloOrderRow) => (row.source ? t(`orders.${row.source}`) : '')
      }
    ],
    [t]
  );

  const data = useMemo(() => {
    const result = siloOrders.reduce((agg: ISiloOrderRow[], current) => {
        current.silos.forEach(silo => {
          if (filterText.length === 0 || silo.device_name.toLowerCase().includes(filterText.toLowerCase())) {
            agg.push({
              silo,
              date: current.date,
              tonnage: silo.tonnage,
              source: current.source,
              id: current.id
            });
          }
        });

      return agg;
    }, []);
    return result;
  }, [siloOrders, filterText]);

  useEffect(() => {
    dispatch(fetchOrders(startDate))
  }, [dispatch, startDate]);

  const onSucess = () => { 
    dispatch(fetchOrders(startDate))
    setToggleCleared(!toggleCleared);
  }

  const contextActions = selectedRows.length === 1 && (
      <Box style={{gap: 8, display: 'flex'}}>
        <Button color="primary" variant="contained"  size="small" onClick={() => setOpenDeliveryeditionDialog(true)}>
          <Edit />
        </Button>
        <ConfirmDelete onConfirm={() => handleDelete(selectedRows[0].id).then(_ => onSucess())} objectToReturn={selectedRows} size="small" />
        <DeliveryDialog onSucess={onSucess} delivery={selectedRows[0]} device={{...selectedRows[0].silo, device_id: selectedRows[0].silo.id }} open={openDeliveryeditionDialog} handleClose={() => setOpenDeliveryeditionDialog(false)}/> 
      </Box>
  );
  const actions = (

      <PrivateComponent hasRight={readSiloOrdersRight}>
        <Button style={{maxHeight: 32}} color="primary" variant="contained" component={Link} to={newSiloOrdersTo}>
          <AddIcon />
        </Button>
      </PrivateComponent>
  );

  const title = useMemo(() => {
    const onChange = (filter: string) => {
      setFilterText(filter);
      if (filter.length === 0) {
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };

    return (
      <Box display="flex" alignItems={"center"} style={{gap: 16}}>
      <TitleWithSearchField
        title={t('orders.delivery', {count: 100})}
        placeholder={t('orders.search_by_silo')}
        onChange={onChange}
        autoFocus
      />
      <DatePicker
        // label={t('from')}
        disableToolbar
        value={startDate}
        onChange={(value: any)=> setStartDate(value)}
        format={APP_LOCAL_DATE_FORMAT}
        disableFuture
        margin="none"
        variant="inline"
        style={{marginBottom: 8}}
        
      />
      </Box>
    );
  }, [resetPaginationToggle, startDate, t]);

  return (
    <>
      <Box p={1}>
        <DataTable
          title={title}
          columns={columns}
          data={data}
          selectableRows
          defaultSortField="date"
          defaultSortAsc={false}
          progressPending={loading}
          // expandOnRowClicked
          expandableRowsHideExpander
          onSelectedRowsChange={(state) => setSelectedRows(state.selectedRows)}
          actions={actions}
          contextActions={contextActions}
          clearSelectedRows={toggleCleared}
        />
      </Box>
    </>
  );
};

export default SiloOrders;
