import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { AppThunk } from 'config/store';
import { IRule, IRulePayload, IRuleResponse } from 'shared/model/rule.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { convertDateFromServer } from 'shared/utils/date-utils';
import { errorNotification } from './notifierSlice';

const initialState = {
  loading: false,
  errorMessage: '',
  rules: [] as IRule[],
  rule: null as IRule | null,
  updating: false,
  updateSuccess: false
};

/**
 * Data specific to certain rules are store in a data object in the API.
 * For convenience for forms we flatten the rule data.
 */
function convertRuleResponse(ruleResponse: IRuleResponse): IRule {
  const { data, ...rest } = ruleResponse;
  return {
    ...rest,
    ...(data  ?? {})
  };
}

export type RulesState = typeof initialState;

export const slice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    fetchRulesStart: state => {
      state.loading = true;
      state.errorMessage = '';
      state.updateSuccess = false;
    },
    fetchRulesFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    },
    fetchRuleSuccess: (state, action: PayloadAction<IRuleResponse>) => {
      const rule = action.payload;
      rule.created_at = convertDateFromServer(rule.created_at);
      rule.last_triggered_time = convertDateFromServer(rule.last_triggered_time);
      state.loading = false;
      state.rule = convertRuleResponse(rule);
    },
    fetchRulesSuccess: (state, action: PayloadAction<IRuleResponse[]>) => {
      action.payload.forEach(rule => {
        rule.created_at = convertDateFromServer(rule.created_at);
        rule.last_triggered_time = convertDateFromServer(rule.last_triggered_time);
      });
      state.loading = false;
      state.rules = action.payload.map(convertRuleResponse);
    },
    updateRuleStart: state => {
      state.updating = true;
      state.errorMessage = '';
      state.updateSuccess = false;
    },
    updateRuleFailed: (state, action: PayloadAction<string>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    },
    updateRuleSuccess: (state, action: PayloadAction<IRuleResponse>) => {
      state.updating = false;
      state.updateSuccess = true;
      state.rule = convertRuleResponse(action.payload)
    },
    deleteRuleSuccess: state => {
      state.updateSuccess = true;
      state.updating = false;
      state.rule = null;
    }
  }
});

export default slice.reducer;

//Actions
const {
  fetchRulesStart,
  fetchRulesFailed,
  fetchRulesSuccess,
  fetchRuleSuccess,
  updateRuleStart,
  updateRuleFailed,
  updateRuleSuccess,
  deleteRuleSuccess
} = slice.actions;

const apiUrl = getEnvApiUrl();

export const fetchRules = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchRulesStart());
    const response: AxiosResponse<IRuleResponse[]> = await axios.get(`${apiUrl}/rules/`);
    dispatch(fetchRulesSuccess(response.data));
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(fetchRulesFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
  }
};

export const fetchRule = (id: string): AppThunk => async dispatch => {
  try {
    dispatch(fetchRulesStart());
    const response: AxiosResponse<IRuleResponse> = await axios.get(`${apiUrl}/rules/${id}/`);
    dispatch(fetchRuleSuccess(response.data));
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(fetchRulesFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
  }
};

export const updateRule = (rule: Partial<IRulePayload>, fetch: boolean = false): AppThunk => async dispatch => {
  try {
    dispatch(updateRuleStart());
    const response: AxiosResponse<IRuleResponse> = await axios.put(`${apiUrl}/rules/${rule.id}/`, rule);
    dispatch(updateRuleSuccess(response.data));
    if (fetch) {
      dispatch(fetchRules());
    }
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(updateRuleFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
  }
};

export const createRule = (rule: IRulePayload, fetch: boolean = false): AppThunk => async dispatch => {
  try {
    dispatch(updateRuleStart());
    const response: AxiosResponse<IRuleResponse> = await axios.post(`${apiUrl}/rules/`, rule);
    dispatch(updateRuleSuccess(response.data));
    if (fetch) {
      dispatch(fetchRules());
    }
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(updateRuleFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
  }
};

export const deleteRule = (rule: IRulePayload): AppThunk => async dispatch => {
  try {
    dispatch(updateRuleStart());
    await axios.delete(`${apiUrl}/rules/${rule.id}/`);
    dispatch(deleteRuleSuccess());
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(updateRuleFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
  }
};
