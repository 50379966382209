import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      textAlign: 'center',
      '&>*': {
        color: theme.palette.primary.main
      }
    },
    message: {
      marginLeft: theme.spacing(2)
    }
  })
);

interface ILoadingProps {
  message?: string;
}

const Loading = (props: ILoadingProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress color="primary" />
      {props.message && (
        <Box className={classes.message}>
          <Typography>{props.message}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Loading;
