import { Box, Grid, Paper } from '@material-ui/core';
import React, { useMemo } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { IDevice } from 'shared/model/device.model';
import { formatDate } from 'shared/utils/date-utils';
import DeviceName from 'shared/widgets/devices/deviceName';
import LabelValue from 'shared/widgets/labelValue';
import CapaMaxField from './fields/capaMaxField';

interface ICombinedDeviceDetailsProps {
  data: IDevice;
}

const CombinedDeviceDetails = (props: ICombinedDeviceDetailsProps) => {
  const { t } = useTranslation();

  const { data } = props;

  const columns: IDataTableColumn<IDevice>[] = useMemo(() => {
    const capaMaxColumn: IDataTableColumn<IDevice> = {
      selector: 'capa_max',
      name: t('capa_max'),
      sortable: true,
      grow: 2,
      format: (row: IDevice) => <CapaMaxField device={row} />
    };

    const results: IDataTableColumn<IDevice>[] = [
      {
        selector: 'device_name',
        name: t('device_name'),
        sortable: true,
        grow: 3,
        format: (row: IDevice) => <DeviceName device={row} />
      },
      capaMaxColumn
    ];
    return results;
  }, [t]);

  const devices = data.devicesCombined ? data.devicesCombined : [];

  return (
    <Box p={1}>
      <Paper>
        <Grid container>
          <Grid item lg={6} xl={4}>
            <Box padding={1}>
              <Paper>
                <Box padding={2}>
                  <LabelValue label={t('device_name')} value={data.device_name} />
                  <LabelValue label={t('device_reference')} value={data.device_reference} />
                  <LabelValue label={t('device_starting_date')} value={formatDate(data.device_install_date)} />
                  <CapaMaxField device={data} showLabel />
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
        <DataTable noHeader columns={columns} data={devices} defaultSortField={'device_name'} />
      </Paper>
    </Box>
  );
};

export default CombinedDeviceDetails;
