import TextField, { StandardTextFieldProps, TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { Controller, FieldError, FieldValues, NestDataObject, useFormContext, Validate } from 'react-hook-form';
import { ILabelValueOption } from 'shared/utils/select-utils';

export interface ISingleAutoCompleteProps extends StandardTextFieldProps {
  name: string;
  options: ILabelValueOption[];
  defaultValue?: ILabelValueOption;
  disableClearable?: boolean;
  className?: string;
  validate?: Validate;
  onSelectionChange?: Function;
  disabled?: boolean;
}
// WARNING: This is not a drop in replacement solution and
// it might not work for some edge cases. Test your code! 
const get = (obj: NestDataObject<FieldValues, FieldError>, path: string, defValue = null) => {
  // If path is not defined or it has false value
  if (!path) return undefined
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)
  // Find value 
  //@ts-ignore
  const result = pathArray?.reduce(
    (prevObj: { [x: string]: any; }, key: string | number) => prevObj && prevObj[key],
    obj
  )
  // If found value is undefined return default value; otherwise return the value
  return result === undefined ? defValue : result
}

const SingleAutoComplete = (props: ISingleAutoCompleteProps) => {
  const {
    name,
    options,
    className,
    defaultValue,
    validate,
    disableClearable = true,
    onSelectionChange,
    disabled,
    required,
    ...rest
  } = props;
  const form = useFormContext();

  const [selection, setSelection] = useState<ILabelValueOption>();

  useEffect(() => {
    form.register({ name }, { validate });
  }, [form, name, validate]);

  const onChange = (e: any, value: ILabelValueOption | null) => {
    setSelection(value as ILabelValueOption);
    form.setValue(name, value as ILabelValueOption, true);
    if (onSelectionChange) {
      onSelectionChange(value);
    }
  };
  const defaultSelectedValue = selection ? options.find(option => selection.value === option.value) : defaultValue;
  return (
    <Controller
      control={form.control}
      name={name}
      as={props => (
        <Autocomplete
          multiple={false}
          className={className}
          disableClearable={disableClearable}
          onChange={onChange}
          defaultValue={defaultSelectedValue}
          options={options}
          disabled={disabled}
          groupBy={option => option.group as string}
          getOptionLabel={option => option.label}
          getOptionSelected={(option, value) => {
            return value.value === option.value;
          }}
          renderInput={(params: TextFieldProps) => {
            return (
              <TextField
                autoComplete="off"
                name={name}
                required={required}
                error={!!get(form.errors, name)}
                // @ts-ignore
                // error={form.errors?.silos?.length && !!form.errors?.silos[0]?.device}
                helperText={get(form.errors, name)?.message}
                disabled={disabled}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'disabled'
                }}
                {...params}
                {...rest}
              />
            );
          }}
        />
      )}
    />
  );
};

export default SingleAutoComplete;
