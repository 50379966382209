import Box from '@material-ui/core/Box';
import { IRootState } from 'config/store';
import React, { useMemo } from 'react';
import { Layout, Layouts, Responsive } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import 'react-resizable/css/styles.css';
import sizeMe, { SizeMeProps } from 'react-sizeme';
import { IGraph } from 'shared/model/graph.model';
import { updateLayout } from 'shared/reducers/dashboardSlice';
import Loading from 'shared/widgets/loading';
import './NanoDashboardGrid.css';
import NanoDashboardGridItem from './NanoDashboardGridItem';

export const DASHBOARD_GRID_HEIGHT = 80;

const defaultLayout: Layout = {
  i: '',
  x: 0,
  y: 0,
  h: 4,
  w: 30,
  minW: 5,
  minH: 2,
  moved: false,
  static: false
};

const breakpoints = {
  lg: 1300,
  md: 1000,
  sm: 480,
  xs: 100,
  xxs: 0
};

const getGraphDefaultLayout = (graph: IGraph) => {
  switch (graph.type) {
    case 'curve':
    case 'table':
      return {
        ...defaultLayout,
        minW: 8
      };
    case 'map':
      return {
        ...defaultLayout,
        minW: 8
      };
    default:
      return defaultLayout;
  }
};

const getLayout = (graph: IGraph, layout: Layout[], index: number) => {
  const found = layout.find(aLayout => aLayout.i === graph.graph_id);
  if (!found) {
    const graphDefaultLayout = getGraphDefaultLayout(graph);

    const currentLayout = {
      ...graphDefaultLayout,
      i: graph.graph_id as string,
      y: index * 2
    };
    layout.push(currentLayout);
  }
};

const getLayouts = (graphs: IGraph[], layouts: Layouts) => {
  const lg = layouts.lg ? [...layouts.lg] : [];
  const md = layouts.md ? [...layouts.md] : [];
  const sm = layouts.sm ? [...layouts.sm] : [];
  const xs = layouts.xs ? [...layouts.xs] : [];
  const xxs = layouts.xxs ? [...layouts.xxs] : [];

  graphs.forEach((graph, index) => {
    getLayout(graph, lg, index);
    getLayout(graph, md, index);
    getLayout(graph, sm, index);
    getLayout(graph, xs, index);
    getLayout(graph, xxs, index);
  });

  return {
    lg,
    md,
    sm,
    xs,
    xxs
  };
};

export interface INanoDashboardGrid extends SizeMeProps {}

const NanoDashboardGrid = (props: INanoDashboardGrid) => {
  const { size } = props;
  const dispatch = useDispatch();
  const dashboard = useSelector(({ dashboard }: IRootState) => dashboard.dashboard);
  const graphs = useSelector(({ graphs }: IRootState) => graphs.graphs);

  const children = useMemo(() => {
    return graphs.map(graph => (
      <Box key={graph.graph_id}>
        <NanoDashboardGridItem graph={graph} />
      </Box>
    ));
  }, [graphs]);

  const onLayoutStop = (layout: any, allLayouts: any) => {
    if (dashboard?.dashboard_id) {
      dispatch(updateLayout(dashboard.dashboard_id, allLayouts));
    }
  };

  const currentLayouts = useMemo(() => {
    const layouts = dashboard?.__user_settings ? { ...dashboard.__user_settings.settings } : {};
    return getLayouts(graphs, layouts);
  }, [graphs, dashboard]);

  if (!size.width) {
    // fix #13 : Default widget size is not take into account (pb between react-sizeme and react-grid-layout )
    return <Loading />;
  }

  return (
    <Responsive
      layouts={currentLayouts}
      isDraggable
      isResizable
      width={size.width}
      breakpoints={breakpoints}
      draggableHandle=".nano-grit-item-header"
      cols={{
        lg: 30,
        md: 20,
        sm: 10,
        xs: 1,
        xxs: 1
      }}
      rowHeight={DASHBOARD_GRID_HEIGHT}
      onLayoutChange={onLayoutStop}
    >
      {children}
    </Responsive>
  );
};

const config = { monitorWidth: true, refreshRate: 50, refreshMode: 'debounce', noPlaceholder: true };

// Using sizeMe sets up the grid to be re-rendered automatically not only when the window size changes, but also
// when the container size changes, so it works for Full Screen mode switches.
// @ts-ignore
export default sizeMe(config)(NanoDashboardGrid);
