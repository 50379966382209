import { Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { IRootState } from 'config/store';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PrivateComponent from 'shared/auth/privateComponent';
import { fetchDevice } from 'shared/reducers/devicesSlice';
import DeviceIcon from 'shared/widgets/devices/deviceIcon';
import AddGroupToDeviceDialog from './dialogs/addGroupToDeviceDialog';
import EditDeviceDialog from './dialogs/editDeviceDialog';
import ExportDeviceCsvDialog from './dialogs/exportDeviceCsvDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white
    },
    toolbar: {
      color: theme.palette.primary.main,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1)
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center'
    },
    divider: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1)
    },
    actions: {
      '&>button': {
        marginLeft: theme.spacing(1)
      }
    },
    icon: {
      marginRight: theme.spacing(0.5)
    },
    link: {
      display: 'flex',
      alignItems: 'center'
    }
  })
);

const DeviceToolbar = () => {
  const classes = useStyles();
  const device = useSelector(({ devices }: IRootState) => devices.device);
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  if (!device) {
    return null;
  }

  const onSuccess = () => {
    if (id) {
      dispatch(fetchDevice(id));
    }
  };

  return (
    <AppBar position="relative" className={classes.root}>
      <Toolbar disableGutters className={classes.toolbar}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item sm={6}>
            <Box my={1} display="flex" alignItems="center">
              <DeviceIcon device={device} />
              <Box marginLeft={1}>
                <Typography color="textPrimary">{device.device_name}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box className={classes.actions} display="flex" flexDirection="row-reverse" my={1}>
              <PrivateComponent hasRight="UPDATE:Device">
                <EditDeviceDialog device={device} onSuccess={onSuccess} />
              </PrivateComponent>
              <PrivateComponent hasRight="ADD_TO_GROUP:Device">
                <AddGroupToDeviceDialog devices={[device]} onSuccess={onSuccess} />
              </PrivateComponent>
              <ExportDeviceCsvDialog devices={[device]} />
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default DeviceToolbar;
