import { IUser } from 'shared/model/user.model';
import { LANG_KEY } from './storage-utils';
import { displayUser } from './user-utils';
import { getEnvWorkspace } from '../../config/env'

export const hideChat = () => {
  // @ts-ignore
  window.$crisp.push(['do', 'chat:hide']);
};

export const showChat = () => {
  // @ts-ignore
  window.$crisp.push(['do', 'chat:show']);
};

export const openChat = () => {
  showChat();
  // @ts-ignore
  window.$crisp.push(['do', 'chat:open']);
};

export const setUserForChat = (user: IUser) => {
  const email = user.email;
  const nickName = displayUser(user);

  // @ts-ignore
  $crisp.push(['set', 'user:email', email]);
  // @ts-ignore
  $crisp.push(['set', 'user:nickname', nickName]);
  changeChatLang(user.preferred_language);
  hideChat();
};

export const setUserWorkspaceChat = () => {
  // @ts-ignore
  $crisp.push(["set", "session:data", [[["workspace", getEnvWorkspace()]]]]);
};

export const changeChatLang = (lang: string) => {
  if (window.localStorage.getItem(LANG_KEY) !== lang) {
    window.localStorage.setItem(LANG_KEY, lang);
  }
};
