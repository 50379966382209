import Box from '@material-ui/core/Box';
import isNumber from 'lodash/isNumber';
import React, { useEffect, useRef } from 'react';
import greenAtlasMarker from 'shared/icons/green_atlas_marker.png';
import greenWifiMarker from 'shared/icons/green_wifi_marker.png';
import orangeAtlasMarker from 'shared/icons/orange_atlas_marker.png';
import orangeWifiMarker from 'shared/icons/orange_wifi_marker.png';
import redAtlasMarker from 'shared/icons/red_atlas_marker.png';
import redWifiMarker from 'shared/icons/red_wifi_marker.png';
import { DeviceStatus } from 'shared/model/device.model';
import { levelThresholds } from '../visualization/graphLevel';
import { DeviceIconPng } from '../devices/deviceIcon';

export interface IMapMarkerProps {
  name?: string;
  lat?: number;
  lng?: number;
  map: any;
  markers: any[];
  onClose?: Function;
}

const MapMarker: React.FunctionComponent<IMapMarkerProps> = props => {
  const { map, markers, onClose } = props;

  const windowRef = useRef<google.maps.InfoWindow>();

  useEffect(() => {
    if (markers.length === 0) {
      return;
    }
    let content = '<div>';
    markers.forEach(marker => {
      content += `<div>${marker.content}</div>`;
    });
    content += '</div>';

    windowRef.current = new google.maps.InfoWindow({
      content
    });
    windowRef.current.addListener('closeclick', (e: any) => {
      if (onClose) {
        onClose();
      }
      // @ts-ignore
      document.activeElement.blur();
    });
    windowRef.current.open(map, markers[0]);
  }, [map, markers, onClose]);

  useEffect(() => {
    return () => {
      if (windowRef.current) {
        windowRef.current.close();
        // COMMENTED BECAUSE CAUSE BIG RECURSIVE CALL ON CLOSE EVENT
        // if (onClose) {
        //   console.log("RENDER OUT EVENT")
        //   onClose();
        // }
        // @ts-ignore
        document.activeElement.blur();
      }
    };
  }, [onClose]);

  return <Box>{props.children}</Box>;
};

export default MapMarker;

export const getDeviceIcon = (status: DeviceStatus | undefined, level: number, wifi: boolean, is_silo: boolean, is_tank: boolean) => {
  let url = DeviceIconPng({is_silo, is_tank});
  let size = new google.maps.Size(60, 60);
  let scaledSize = new google.maps.Size(38, 38);
  if (status === 'ok' && isNumber(level)) {
    scaledSize = new google.maps.Size(28, 38);
    if (level <= levelThresholds[0]) {
      url = wifi ? redWifiMarker : redAtlasMarker;
    } else if (level <= levelThresholds[1]) {
      url = wifi ? orangeWifiMarker : orangeAtlasMarker;
    } else {
      url = wifi ? greenWifiMarker : greenAtlasMarker;
    }
  } 

  return {
    url,
    size,
    scaledSize,
    anchor: new google.maps.Point(12, 34),
    labelOrigin: new google.maps.Point(0, 0)
  };
};
