import { Moment } from 'moment';
import { IDevice } from './device.model';
import { IUser } from './user.model';
import { IGroup } from './group.model';

export type nanolikeRuleType = 'InactiveIbcRule' | 'LostIbcRule' | 'MaintenanceDateRule' | 'IbcDepositRule';
export const ruleTypes: nanolikeRuleType[] = ['InactiveIbcRule', 'LostIbcRule', 'MaintenanceDateRule', 'IbcDepositRule'];


/**
 * Common rule data
 */
interface IRuleBase {
  created_at: Moment | undefined;
  created_by: string;
  id: string;
  is_enabled: boolean;
  last_triggered_time: Moment | undefined;
  name: string;
  quiet_period_in_h: number;
  recipients_groups: IGroup[];
  recipients_users: IUser[];
  resourcetype: nanolikeRuleType;
  watched_devices: IDevice[];
  watched_groups: IGroup[];
}

/**
 * Data specific to certain rules. Stored in a data object in the API
 */
interface RuleData {
  inactive_days?: number;
  is_at_customer_location?: boolean;
  is_at_factory?: boolean;
  lost_days?: number;
  min_days_for_maintenance?: number;
  days_after_delivery?: number;
}

export interface IRuleResponse extends IRuleBase {
  data: RuleData;
}

export interface IRulePayload extends IRuleBase, RuleData {}

/**
 * For convenience for forms we flatten the rule data
 */
export interface IRule extends IRuleBase, RuleData {}

export interface ITriggeredDevice {
  device_id: string;
  device_name: string;
  device_reference: string;
  is_silo: boolean;
  is_tank: boolean;
  offending_value: number;
}

export interface IRuleTriggered {
  triggered_at: Moment | undefined;
  triggered_id: string;
  rule_id: string;
  offending_devices: ITriggeredDevice[];
}
