import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { AppThunk } from 'config/store';
import { IAddGroupToUser } from 'shared/model/group.model';
import { IUser, IUserGroupMembership, IUserInfos } from 'shared/model/user.model';
import { IWorkspaceRolev1 } from 'shared/model/workspace.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { authenticationSuccess, logout } from './authenticationSlice';
import { errorNotification, successNotification } from './notifierSlice';

export interface IInviteUser {
  email: string;
  workspace_role: string;
  group_admin_ids: string[];
  group_user_ids: string[];
  lang: string;
}

interface IUsersResponse {
  count: string;
  results: IUser[];
}

const initialState = {
  loading: false,
  errorMessage: '',
  users: [] as IUser[],
  user: null as IUser | null,
  updating: false,
  updateSuccess: false,
  inviting: false,
  inviteSuccess: false
};

export type UserState = typeof initialState;

export const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUsersStart: state => {
      state.loading = true;
      state.errorMessage = '';
      state.updateSuccess = false;
    },
    fetchUsersFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    },
    fetchUsersSuccess: (state, action: PayloadAction<IUser[]>) => {
      state.loading = false;
      state.users = action.payload;
    },
    fetchUserSuccess: (state, action: PayloadAction<IUser>) => {
      state.loading = false;
      state.user = action.payload;
    },
    updateUserStart: state => {
      state.errorMessage = '';
      state.updateSuccess = false;
      state.updating = true;
    },
    updateUserFailed: (state, action: PayloadAction<string>) => {
      state.updateSuccess = false;
      state.updating = false;
      state.errorMessage = action.payload;
    },
    deleteUserSuccess: state => {
      state.updateSuccess = true;
      state.updating = false;
      state.user = null;
    },
    updateUserSuccess: state => {
      state.updateSuccess = true;
      state.updating = false;
    },
    inviteUserStart: state => {
      state.inviting = true;
      state.inviteSuccess = false;
    },
    inviteUserSuccess: state => {
      state.inviting = false;
      state.inviteSuccess = true;
    },
    inviteUserFailed: (state, action: PayloadAction<string>) => {
      state.inviting = false;
      state.errorMessage = action.payload;
    }
  }
});

export default slice.reducer;

//Actions
const {
  fetchUserSuccess,
  fetchUsersStart,
  fetchUsersFailed,
  fetchUsersSuccess,
  updateUserStart,
  updateUserFailed,
  deleteUserSuccess,
  updateUserSuccess,
  inviteUserStart,
  inviteUserSuccess,
  inviteUserFailed
} = slice.actions;

const apiUrl = getEnvApiUrl();

export const fetchUser =
  (id: string): AppThunk =>
    async dispatch => {
      try {
        dispatch(fetchUsersStart());
        const response = await axios.get<IUser>(`${apiUrl}/users/${id}/`);
        dispatch(fetchUserSuccess(response.data));
      } catch (error) {
        const errorMsg = getRequestErrorMessage(error);
        dispatch(fetchUsersFailed(errorMsg));
        dispatch(errorNotification(`${errorMsg}`));
      }
    };

export const fetchUsers = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(fetchUsersStart());
    const response = await axios.get<IUsersResponse>(`${apiUrl}/users/`);
    const allUsers = response.data.results;

    dispatch(fetchUsersSuccess(allUsers));
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(fetchUsersFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
  }
};

export const deleteUser =
  (user: IUser, fetchUsersAfter = true): AppThunk =>
    async dispatch => {
      try {
        dispatch(updateUserStart());
        await axios.delete(`${apiUrl}/users/${user.id}/`);
        dispatch(deleteUserSuccess());
        if (fetchUsersAfter) {
          dispatch(fetchUsers());
        }
      } catch (error) {
        const errorMsg = getRequestErrorMessage(error);
        dispatch(updateUserFailed(errorMsg));
        dispatch(errorNotification(`${errorMsg}`));
      }
    };

export const deleteCurrentUser =
  (user: IUser): AppThunk =>
    async dispatch => {
      try {
        await axios.delete(`${apiUrl}/users/${user.id}/`);
        await dispatch(logout());
      } catch (error) {
        console.log("error", {error})
        const errorMsg = getRequestErrorMessage(error);
        dispatch(errorNotification(`${errorMsg}`));
      }
    };

export const addGroupsToUser =
  (groups: IAddGroupToUser[], user: IUser, fetch = true): AppThunk =>
    async dispatch => {
      try {
        dispatch(updateUserStart());
        await axios.post(`${apiUrl}/users/${user.id}/group-memberships/`, groups);
        dispatch(updateUserSuccess());
        if (fetch) {
          dispatch(fetchUsers());
        }
      } catch (error) {
        const errorMsg = getRequestErrorMessage(error);
        dispatch(updateUserFailed(errorMsg));
        dispatch(errorNotification(`${errorMsg}`));
      }
    };

export const updateGroupRoleForUser =
  (toUpdate: IUserGroupMembership, user: IUser): AppThunk =>
    async dispatch => {
      try {
        dispatch(updateUserStart());
        await axios.patch(`${apiUrl}/users/${user.id}/group-memberships/${toUpdate.user_group_membership_id}/`, {
          group_role_id: toUpdate.group_role.group_role_id
        });
        dispatch(updateUserSuccess());
        dispatch(fetchUsers());
      } catch (error) {
        const errorMsg = getRequestErrorMessage(error);
        dispatch(updateUserFailed(errorMsg));
        dispatch(errorNotification(`${errorMsg}`));
      }
    };

export const deleteGroupsFromUser =
  (groupMemberShip: IUserGroupMembership, user: IUser, fetch = true): AppThunk =>
    async dispatch => {
      try {
        dispatch(updateUserStart());
        await axios.delete(`${apiUrl}/users/${user.id}/group-memberships/${groupMemberShip.user_group_membership_id}/`);
        dispatch(updateUserSuccess());
        if (fetch) {
          dispatch(fetchUsers());
        }
      } catch (error) {
        const errorMsg = getRequestErrorMessage(error);
        dispatch(updateUserFailed(errorMsg));
        dispatch(errorNotification(`${errorMsg}`));
      }
    };

export const changeUserWorkspaceRole =
  (wsRole: IWorkspaceRolev1, user: IUser): AppThunk =>
    async dispatch => {
      try {
        dispatch(updateUserStart());
        await axios.patch(`${apiUrl}/users/${user.id}/`, wsRole);
        dispatch(updateUserSuccess());
        dispatch(fetchUsers());
      } catch (error) {
        const errorMsg = getRequestErrorMessage(error);
        dispatch(updateUserFailed(errorMsg));
        dispatch(errorNotification(`${errorMsg}`));
      }
    };

export const inviteUser =
  (invite: IInviteUser): AppThunk =>
    async dispatch => {
      try {
        dispatch(inviteUserStart());
        await axios.post(`${apiUrl}/invite/`, invite);
        dispatch(inviteUserSuccess());
        dispatch(successNotification('user_successfully_invited'));
        dispatch(fetchUsers());
      } catch (error) {
        const errorMsg = getRequestErrorMessage(error);
        dispatch(inviteUserFailed(errorMsg));
        dispatch(errorNotification(`${errorMsg}`));
      }
    };

export const reInviteUser =
  (user: IUser): AppThunk =>
    async dispatch => {
      try {
        dispatch(inviteUserStart());
        await axios.get(`${apiUrl}/users/${user.id}/reinvite`);
        dispatch(inviteUserSuccess());
        dispatch(successNotification('user_successfully_invited'));
        dispatch(fetchUsers());
      } catch (error) {
        const errorMsg = getRequestErrorMessage(error);
        dispatch(inviteUserFailed(errorMsg));
        dispatch(errorNotification(`${errorMsg}`));
      }
    };

export const updateCurrentUser =
  (user: IUserInfos): AppThunk =>
    async dispatch => {
      try {
        dispatch(updateUserStart());
        const response: AxiosResponse<IUser> = await axios.patch(`${apiUrl}/users/${user.id}/`, user);
        dispatch(updateUserSuccess());
        dispatch(authenticationSuccess(response.data));
        dispatch(successNotification('edit_profile_success'));
      } catch (error) {
        const errorMsg = getRequestErrorMessage(error);
        dispatch(updateUserFailed(errorMsg));
        dispatch(errorNotification(`${errorMsg}`));
      }
    };
