import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useState } from 'react';
import { IGetGraphDataRequest, IGetGraphDataResponse, IGraphData } from 'shared/model/api.model';
import { IDevice } from 'shared/model/device.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { PendingOrErrorDeviceIcon } from 'shared/widgets/devices/deviceIcon';
import { Link } from 'react-router-dom';
import Loading from 'shared/widgets/loading';
import GraphLevel from 'shared/widgets/visualization/graphLevel';
import TemperatureMetric from 'shared/widgets/visualization/temperatureMetric';
import PrivateComponent from 'shared/auth/privateComponent';
import CalibrateBtn from './actions/calibrateBtn';
// import CalibrateBtn from './actions/calibrateBtn';
import DeviceInfos from './deviceInfo';
import DeviceMap from './deviceMap';
import { newSiloOrdersTo, readSiloOrdersRight } from '../siloOrders/';
import { OKSTATUS } from 'shared/utils/device-utils';
import useInterval from 'shared/hooks/useInterval';

const apiUrl = getEnvApiUrl();
const getGraphDataUrl = `${apiUrl}/get-graph-data`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1)
    },
    loadingBox: {
      textAlign: 'center',
      margin: theme.spacing(1)
    },
    chip: {
      margin: theme.spacing(0.5),
      borderRadius: '10px'
    },
    paperMap: {
      padding: theme.spacing(1),
      height: '100%'
    },
    p2: {
      padding: theme.spacing(2)
    },
    siloActions: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '5px'
    },
    visu: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      justifyContent: 'space-between',
      width:"fit-content",
      height: '100%',
      '&>*': {
        margin: theme.spacing(2)
      }
    },
    level: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
      
    }
  })
);


export interface IDefaultDeviceDetailsProps {
  data: IDevice;
}

const DefaultDeviceDetails = (props: IDefaultDeviceDetailsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [graphDatas, setGraphDatas] = useState<IGraphData[]>([]);

  const device = props.data;

  const load = useCallback(async () => {
    try {
      const body: IGetGraphDataRequest = {
        is_last_value: true,
        device_ids: [device.device_id],
        data_types: '__all__'
      };
      setLoading(true);
      setErrorMsg('');
      const response: AxiosResponse<IGetGraphDataResponse> = await axios.post(getGraphDataUrl, body);
      const result = response.data.data;
      setGraphDatas(result);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setErrorMsg(getRequestErrorMessage(e));
    }
  }, [device.device_id]);

  useInterval(load)

  const timeData = graphDatas?.find(graphData => graphData.data_type === 'lastMessageReceived');
  const positionData = graphDatas?.find(graphData => graphData.data_type === 'position');
  const levelPercentData = graphDatas?.find(graphData => graphData.data_type === 'level_percent');
  const temperature = graphDatas?.find(graphData => graphData.data_type === 'temperature');
  const lastCalibration = graphDatas?.find(graphData => graphData.data_type === 'last_calibration');
  const level = graphDatas?.find(graphData => graphData.data_type === 'level');

  return (
    <Box className={classes.root}>
      {loading ? (
        <Paper elevation={3}>
          <Box p={2}>
            <Loading />
          </Box>
        </Paper>
      ) : (
        <>
          {errorMsg ? (
            <Box>{errorMsg}</Box>
          ) : (
            <>
              {graphDatas.length > 0 ? (
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Paper elevation={3} className={classes.p2}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                          <DeviceInfos
                            device={device}
                            timeData={timeData}
                            positionData={positionData}
                            lastCalibration={lastCalibration}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} style={{display:"flex", justifyContent:"center"}}>
                          <Box className={classes.visu}>
                            {
                              // @ts-ignore
                              OKSTATUS.includes(device.status) ? (
                              <Box className={classes.level}>
                                  <GraphLevel 
                                    levelPercent={levelPercentData?.data_points[0].value} 
                                    legend={t('data_type_unit', {value : level?.data_points[0]?.filling})}
                                  />
                              </Box>
                            ) : (
                              <PendingOrErrorDeviceIcon status={device.status} metadata={device.metadata} />
                            )}
                            <TemperatureMetric graphData={temperature} />

                            <Box className={classes.siloActions}>
                              <CalibrateBtn device={device} onSuccess={load} />
                              <PrivateComponent hasRight={readSiloOrdersRight}>
                                <Button style={{ textAlign: 'center'}} color="primary" variant="contained" component={Link} to={newSiloOrdersTo}>
                                  {t('orders.another')}
                                </Button>
                              </PrivateComponent>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Paper elevation={3} className={classes.paperMap}>
                          <DeviceMap data={graphDatas} height="100%" />
                    </Paper>
                  </Grid>
                </Grid>
              ) : null}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default DefaultDeviceDetails;
