import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { IRootState } from 'config/store';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { nanolikeRuleType } from 'shared/model/rule.model';
import { IUser } from 'shared/model/user.model';
import { displayUser } from 'shared/utils/user-utils';
import MultipleAutoComplete from 'shared/widgets/form/multipleAutocomplete';
import SelectDevicesOrGroups from 'shared/widgets/form/selectDevicesOrGroups';
import RuleIcon from '../ruleIcons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    type: {
      '&>svg': {
        marginRight: theme.spacing(1)
      }
    }
  })
);

export interface IRuleFormSecondStepProps {
  step: number;
  activeStep: number;
}

const RuleFormSecondStep = (props: IRuleFormSecondStepProps) => {
  const { activeStep, step } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const groups = useSelector(({ group }: IRootState) => group.groups);
  const users = useSelector(({ users }: IRootState) => users.users);
  const currentUser = useSelector(({ authentication }: IRootState) => authentication.currentUser) as IUser;
  const updating = useSelector(({ rules }: IRootState) => rules.updating);
  const form = useFormContext();
  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new' ? true : false;

  const groupGroup = t('group');
  const userGroup = t('user', { count: 100 });

  const groupOptions = groups.map(aGroup => ({
    label: aGroup.group_name,
    value: aGroup.group_id,
    group: groupGroup
  }));
  const usersOptions = [...users, currentUser].map(aUser => ({
    label: displayUser(aUser),
    value: aUser.id as string,
    group: userGroup
  }));

  const resourcetype = form.getValues().resourcetype as nanolikeRuleType;

  const usersGroupOptions = groupOptions.concat(usersOptions);
  const userGroupDefaultValue = !isNew ? form.getValues().user_group : [
    {
      label: displayUser(currentUser),
      value: currentUser.id,
      group: userGroup
    }
  ]

  form.setValue('user_group', userGroupDefaultValue)
  return (
    <Box display={activeStep === step ? 'block' : 'none'}>
      <Grid container justify="center">
        <Grid item xs={12} md={6} lg={4}>
          <Box display="flex" justifyContent="center" alignItems="center" className={classes.type}>
            <RuleIcon type={resourcetype} />
            {t(resourcetype)}
          </Box>
          <TextField
            autoFocus
            margin="dense"
            label={t('name')}
            fullWidth
            name="name"
            inputRef={form.register({
              validate: value => {
                if (activeStep === step && value.length === 0) {
                  return <Trans i18nKey="required_field">Required Field</Trans>;
                }
                return true;
              }
            })}
            error={form.errors.name ? true : false}
            helperText={form.errors.name && form.errors.name.message}
            disabled={updating}
          />
          <SelectDevicesOrGroups
            step={step}
            activeStep={activeStep}
            name="device_group"
            label={t('entities')}
            disabled={updating}
            selectAll
          />
          <MultipleAutoComplete
            name="user_group"
            label={t('recipients')}
            options={usersGroupOptions}
            validate={(value: any) => {
              if (activeStep === step && !value) {
                return <Trans i18nKey="required_field">Required Field</Trans>;
              }
              return true;
            }}
            disabled={updating}
          />
          {resourcetype === 'MaintenanceDateRule' && (
            <TextField
              type="number"
              margin="dense"
              label={t('min_days_for_maintenance')}
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
              name="min_days_for_maintenance"
              inputRef={form.register({
                validate: value => {
                  if (activeStep === step && value.length === 0) {
                    return <Trans i18nKey="required_field">Required Field</Trans>;
                  }
                  return true;
                }
              })}
              error={form.errors.min_days_for_maintenance ? true : false}
              helperText={form.errors.min_days_for_maintenance && form.errors.min_days_for_maintenance.message}
              disabled={updating}
            />
          )}
          {resourcetype === 'IbcDepositRule' && (
            <TextField
              type="number"
              margin="dense"
              label={t('days_after_delivery')}
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
              name="days_after_delivery"
              inputRef={form.register({
                validate: value => {
                  if (activeStep === step && value.length === 0) {
                    return <Trans i18nKey="required_field">Required Field</Trans>;
                  }
                  return true;
                }
              })}
              error={form.errors.days_after_delivery ? true : false}
              helperText={form.errors.days_after_delivery && form.errors.days_after_delivery.message}
              disabled={updating}
            />
          )}
          {resourcetype === 'InactiveIbcRule' && (
            <>
              <TextField
                type="number"
                margin="dense"
                label={t('inactive_days')}
                InputProps={{ inputProps: { min: 0 } }}
                fullWidth
                name="inactive_days"
                inputRef={form.register({
                  validate: value => {
                    if (activeStep === step && value.length === 0) {
                      return <Trans i18nKey="required_field">Required Field</Trans>;
                    }
                    return true;
                  }
                })}
                error={form.errors.inactive_days ? true : false}
                helperText={form.errors.inactive_days && form.errors.inactive_days.message}
                disabled={updating}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    inputRef={form.register}
                    name="is_at_customer_location"
                    color="primary"
                    defaultChecked={form.getValues().is_at_customer_location}
                    disabled={updating}
                  />
                }
                label={t('is_at_customer_location')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    inputRef={form.register}
                    name="is_at_factory"
                    color="primary"
                    defaultChecked={form.getValues().is_at_factory}
                    disabled={updating}
                  />
                }
                label={t('is_at_factory')}
              />
            </>
          )}
          {resourcetype === 'LostIbcRule' && (
            <TextField
              type="number"
              margin="dense"
              label={t('lost_days')}
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
              name="lost_days"
              inputRef={form.register({
                validate: value => {
                  if (activeStep === step && value.length === 0) {
                    return <Trans i18nKey="required_field">Required Field</Trans>;
                  }
                  return true;
                }
              })}
              error={form.errors.lost_days ? true : false}
              helperText={form.errors.lost_days && form.errors.lost_days.message}
              disabled={updating}
            />
          )}

          <TextField
            type="number"
            margin="dense"
            label={t('quiet_period_in_d', { nb: 'x' })}
            InputProps={{ inputProps: { min: 0 } }}
            fullWidth
            name="quiet_period_in_h"
            inputRef={form.register({
              validate: value => {
                if (activeStep === step && value.length === 0) {
                  return <Trans i18nKey="required_field">Required Field</Trans>;
                }
                return true;
              }
            })}
            error={form.errors.quiet_period_in_h ? true : false}
            helperText={form.errors.quiet_period_in_h && form.errors.quiet_period_in_h.message}
            disabled={updating}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default RuleFormSecondStep;
