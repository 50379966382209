import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import { IRootState } from 'config/store';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHasRight } from 'shared/auth/auth-utils';
import PrivateComponent from 'shared/auth/privateComponent';
import { IGroupRole } from 'shared/model/group.model';
import { IUser, IUserGroupMembership } from 'shared/model/user.model';
import { deleteGroupsFromUser, updateGroupRoleForUser } from 'shared/reducers/usersSlice';
import { displayUser } from 'shared/utils/user-utils';
import ConfirmDeleteDialog from 'shared/widgets/confirmDeleteDialog';
import ConfirmPromotionDialog from './dialogs/ConfirmPromotionDialog';

const WS_AUTHORITIES = {
  ADMIN: 'Admin',
  USER: 'User'
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.5),
      borderRadius: '10px'
    }
  })
);

export interface IUserGroupMemberShipProps {
  groupMembership: IUserGroupMembership;
  user: IUser;
  readonly?: boolean;
}

const UserGroupMemberShip = (props: IUserGroupMemberShipProps) => {
  const classes = useStyles();
  const updating = useSelector(({ users }: IRootState) => users.updating);
  const dispatch = useDispatch();
  const authorities = useSelector(({ group }: IRootState) => group.authorities);
  
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openPromotion, setOpenPromotion] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const { t } = useTranslation();

  const admin_role = authorities.find(groupRole => groupRole.group_role_name === WS_AUTHORITIES.ADMIN);
  const user_role = authorities.find(groupRole => groupRole.group_role_name === WS_AUTHORITIES.USER);

  const { groupMembership, user, readonly = false } = props;

  const canPromote = useHasRight('UPDATE:User')

  const tryDelete = () => {
    setOpenDelete(true);
  };

  const onConfirmDelete = () => {
    dispatch(deleteGroupsFromUser(groupMembership, user));
    setOpenDelete(false);
  };

  const onCloseConfirmDeleteDialog = () => {
    setOpenDelete(false);
  };

  const onConfirmPromotion = async () => {
    const toUpdate = { ...groupMembership };
    if (groupMembership.group_role.group_role_name === WS_AUTHORITIES.ADMIN) {
      toUpdate.group_role = user_role as IGroupRole;
      await dispatch(updateGroupRoleForUser(toUpdate, user));
    } else {
      toUpdate.group_role = admin_role as IGroupRole;
      await dispatch(updateGroupRoleForUser(toUpdate, user));
    }

    setOpenPromotion(false);
  };

  const onClosePromotion = () => {
    setOpenPromotion(false);
  };

  const onOpenPromotion = () => {
    let title = t('make_admin');
    let description = t('make_admin_desc', {
      username: displayUser(user),
      groupName: groupMembership.group.group_name
    });
    if (groupMembership.group_role.group_role_name === WS_AUTHORITIES.ADMIN) {
      title = t('make_user');
      description = t('make_user_desc', {
        username: displayUser(user),
        groupName: groupMembership.group.group_name
      });
    }

    setTitle(title);
    setDescription(description);
    setOpenPromotion(true);
  };

  const readOnlyChip = (
    <Chip
      icon={groupMembership.group_role.group_role_name === WS_AUTHORITIES.ADMIN ? <StarIcon /> : undefined}
      label={groupMembership.group.group_name}
      title={groupMembership.group.group_name}
      variant="outlined"
      className={classes.chip}
      size="small"
    />
  );

  if (readonly) {
    return readOnlyChip;
  }

  return (
    <PrivateComponent hasRight="UPDATE:Group" otherwise={readOnlyChip}>
      <Chip
        icon={groupMembership.group_role.group_role_name === WS_AUTHORITIES.ADMIN && user.workspace_role.group_role_delegation ? <StarIcon /> : undefined}
        label={groupMembership.group.group_name}
        title={groupMembership.group.group_name}
        variant="outlined"
        className={classes.chip}
        size="small"
        disabled={updating}
        onDelete={canPromote ? tryDelete : undefined}
        onClick={canPromote && user.workspace_role.group_role_delegation ? onOpenPromotion : undefined}
      />
      <ConfirmDeleteDialog
        open={openDelete}
        onConfirm={onConfirmDelete}
        onClose={onCloseConfirmDeleteDialog}
        objectToReturn={[groupMembership]}
      />
      <ConfirmPromotionDialog
        open={openPromotion}
        onConfirm={onConfirmPromotion}
        onClose={onClosePromotion}
        title={title}
        message={description}
      />
    </PrivateComponent>
  );
};

export default UserGroupMemberShip;
