import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { IRootState } from 'config/store';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { nanolikeCallbackNotificationChoice, nanolikeCallbackType } from 'shared/model/callback.model';
import { createCallback, fetchCallback, updateCallback } from 'shared/reducers/callbacksSlice';
import { ILabelValueOption } from 'shared/utils/select-utils';
import Loading from 'shared/widgets/loading';
import CallbackForm from './callbackForm';

export interface ICallbackFormResponse {
  name: string;
  url: string;
  ctype: nanolikeCallbackType;
  device_group: ILabelValueOption[];
  notification_choice: nanolikeCallbackNotificationChoice;
  data_types: ILabelValueOption[];
}

const CreateOrEditCallback = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const updateSuccess = useSelector(({ callbacks }: IRootState) => callbacks.updateSuccess);
  const loading = useSelector(({ callbacks }: IRootState) => callbacks.loading);
  const callback = useSelector(({ callbacks }: IRootState) => callbacks.callback);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new' ? true : false;

  const groupGroup = t('group');
  const deviceGroup = t('device',{count: 100});

  useEffect(() => {
    if (!isNew && id) {
      dispatch(fetchCallback(id));
    }
  }, [dispatch, id, isNew]);

  useEffect(() => {
    if (updateSuccess === true) {
      history.push('/callbacks');
    }
  }, [history, updateSuccess]);

  const onSubmit = (responses: ICallbackFormResponse) => {
    const callbackFromResponse = getCallbackFromResponse(responses, groupGroup, deviceGroup);
    if (isNew) {
      dispatch(createCallback(callbackFromResponse));
    } else {
      dispatch(updateCallback({...callbackFromResponse, id }));
    }
  };

  return (
    <Box p={1}>
      <Paper elevation={3}>
        {isNew || (!loading && callback) ? (
          <CallbackForm onSubmit={onSubmit} />
        ) : (
          <Box p={4}>
            <Loading />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default CreateOrEditCallback;

const getCallbackFromResponse = (responses: ICallbackFormResponse, groupGroup: string, deviceGroup: string) => {
  const callback: any = {
    ...responses,
    ctype: 'BOTH',
    is_enabled: true
  };

  const devices: string[] = [];
  const groups: string[] = [];
  responses.device_group.forEach(current => {
    if (current.group === groupGroup) {
      groups.push(current.value);
    } else if (current.group === deviceGroup) {
      devices.push(current.value);
    }
  });
  callback.device_ids = devices;
  callback.group_ids = groups;
  delete callback.device_group;

  callback.data_types = responses.data_types.map(option => option.value);

  return callback;
};
