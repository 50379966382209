import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { IRootState } from 'config/store';
import React, { useEffect, useMemo, useState } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IAlert } from 'shared/model/alert.model';
import { IAlertNotificationsResponse } from 'shared/reducers/alertsSlice';
import { APP_TIMESTAMP_FORMAT, convertDateFromServer, formatDate } from 'shared/utils/date-utils';
import DataGrid from 'shared/widgets/dataTable';

const apiUrl = getEnvApiUrl();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    description: {
      fontSize: '1rem',
      fontWeight: 'lighter'
    }
  })
);

export interface IAlertDetailsProps {
  data?: IAlert;
}

const AlertDetails = (props: IAlertDetailsProps) => {
  const alert = props.data;
  const classes = useStyles();
  const { t } = useTranslation();
  const devices = useSelector(({ devices }: IRootState) => devices.devices);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const response: AxiosResponse<IAlertNotificationsResponse> = await axios.get(`${apiUrl}/alerts/${alert?.alert_id}/notifications/?status=alert_validated`);

      const notifications = response.data.results;

      const data = notifications.reduce((agg: any[], current) => {
        const dataType = current.data_type_from_alert;
        const notificationTimestamp = convertDateFromServer(current.notification_timestamp);
        const alertData = current.offending_devices.map(deviceData => ({
          ...deviceData,
          dataType,
          notificationTimestamp,
          offendingTimestamp: convertDateFromServer(deviceData.offending_timestamp),
          deviceName: deviceData.device_name +( deviceData.poi_name ? " - " + deviceData.poi_name : ''),
          dataValue: dataType === 'position' ? `${deviceData.offending_lat}, ${deviceData.offending_lng}` : deviceData.offending_value
        }));

        agg = agg.concat(alertData);
        return agg;
      }, []);
      setData(data);
      setLoading(false);
    };

    loadData();
  }, [alert, devices]);

  const columns: IDataTableColumn<any>[] = useMemo(
    () => [
      {
        selector: 'id',
        name: t('id'),
        sortable: true,
        grow: 1,
        omit: true
      },
      {
        selector: 'notificationTimestamp',
        name: t('notificationTimestamp'),
        sortable: true,
        grow: 1,
        format: (row: any) => {
          return formatDate(row.notificationTimestamp, APP_TIMESTAMP_FORMAT);
        }
      },
      {
        selector: 'offendingTimestamp',
        name: t('date'),
        sortable: true,
        grow: 1,
        format: (row: any) => {
          return formatDate(row.offendingTimestamp, APP_TIMESTAMP_FORMAT);
        }
      },
      {
        selector: 'deviceName',
        name: t('name'),
        sortable: true,
        grow: 1
      },
      {
        selector: 'dataValue',
        name: t('value'),
        grow: 1
      }
    ],
    [t]
  );

  if (!alert) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <DataGrid
        noHeader
        columns={columns}
        data={data}
        defaultSortField="notificationTimestamp"
        defaultSortAsc={false}
        progressPending={loading}
        paginationPerPage={10}
      />
    </Box>
  );
};

export default AlertDetails;
