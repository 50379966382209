import { Checkbox, createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IRootState } from 'config/store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { sortByLabel } from 'shared/utils/data-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
      width: '32px',
      height: '32px'
    },
    groupsFilter: {
      height: '29px',
      minWidth: '100%',
      marginBottom: theme.spacing(1),
      marginTop: 0,
      [theme.breakpoints.up('sm')]: {
        minWidth: '220px',
        marginLeft: theme.spacing(2)
      }
    },
    empty: {
      color: 'rgba(0, 0, 0, 0.5)'
    }
  })
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IGroupFilterProps {
  onChange: (selection: ILabelValueOption<string>[]) => void;
}

const GroupFilter = (props: IGroupFilterProps) => {
  const groups = useSelector(({ group }: IRootState) => group.groups);

  const classes = useStyles();
  const { t } = useTranslation();

  if (groups.length < 2) {
    return null;
  }

  const sortedGroup: ILabelValueOption<string>[] = [...groups].map(item => ({ label: item.group_name, value: item.group_id }));
  sortedGroup.sort(sortByLabel);

  const onChange = (event: React.ChangeEvent<any>, selection: any) => {
    props.onChange(selection);
  };

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      limitTags={2}
      size="small"
      options={sortedGroup}
      getOptionLabel={option => option.label}
      renderInput={params => <TextField {...params} placeholder={t('groups_of_devices')} className={classes.groupsFilter} />}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
          {option.label}
        </React.Fragment>
      )}
      getOptionSelected={(option, value) => {
        return value.value === option.value;
      }}
      onChange={onChange}
    />
  );
};

export default GroupFilter;
