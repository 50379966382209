import { Button } from '@material-ui/core';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { IRootState } from 'config/store';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IDevice } from 'shared/model/device.model';
import { unCombineDevices } from 'shared/reducers/devicesSlice';
import { usePrevious } from 'shared/utils/hook-utils';
import ConfirmDialog from 'shared/widgets/ConfirmDialog';

interface IUnCombineBtnProps {
  device: IDevice;
  onSuccess: () => void;
}

const UnCombineBtn = (props: IUnCombineBtnProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const updateSuccess = useSelector(({ devices }: IRootState) => devices.updateSuccess);
  const updating = useSelector(({ devices }: IRootState) => devices.updating);
  const previousUpdating = usePrevious(updating);

  const { device, onSuccess } = props;
  const [open, setOpen] = useState(false);

  const canCombine = device.is_combined;

  useEffect(() => {
    if (canCombine && updateSuccess && open && previousUpdating) {
      handleClose();
      onSuccess();
    }
  }, [canCombine, updateSuccess, dispatch, open, previousUpdating, onSuccess]);

  if (!canCombine) {
    return null;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    dispatch(unCombineDevices(device));
  };

  return (
    <>
      <ConfirmDialog open={open} onClose={handleClose} onConfirm={onConfirm} message={t('confirmUncombine')} disabled={updating} />
      <Button variant="contained" color="primary" onClick={handleOpen} title={t('unCombine_tooltip')}>
        <LinkOffIcon />
      </Button>
    </>
  );
};

export default UnCombineBtn;
