import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { AppThunk } from 'config/store';
import { ICallback } from 'shared/model/callback.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { convertDateFromServer } from 'shared/utils/date-utils';
import { errorNotification } from './notifierSlice';

const initialState = {
  loading: false,
  errorMessage: '',
  callbacks: [] as ICallback[],
  callback: null as ICallback | null,
  updating: false,
  updateSuccess: false
};

export type CallbacksState = typeof initialState;

export const slice = createSlice({
  name: 'callbacks',
  initialState,
  reducers: {
    fetchCallbacksStart: state => {
      state.loading = true;
      state.errorMessage = '';
      state.updateSuccess = false;
    },
    fetchCallbacksFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    },
    fetchCallbackSuccess: (state, action: PayloadAction<ICallback>) => {
      const callback = action.payload;
      callback.created_at = convertDateFromServer(callback.created_at);
      state.loading = false;
      state.callback = callback;
    },
    fetchCallbacksSuccess: (state, action: PayloadAction<ICallback[]>) => {
      action.payload.forEach(callback => {
        callback.created_at = convertDateFromServer(callback.created_at);
      });
      state.loading = false;
      state.callbacks = action.payload;
    },
    updateCallbackStart: state => {
      state.updating = true;
      state.errorMessage = '';
      state.updateSuccess = false;
    },
    updateCallbackFailed: (state, action: PayloadAction<string>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    },
    updateCallbackSuccess: (state, action: PayloadAction<ICallback>) => {
      state.updating = false;
      state.updateSuccess = true;
      state.callback = action.payload;
    },
    deleteCallbackSuccess: state => {
      state.updateSuccess = true;
      state.updating = false;
      state.callback = null;
    }
  }
});

export default slice.reducer;

//Actions
const {
  fetchCallbacksStart,
  fetchCallbacksFailed,
  fetchCallbackSuccess,
  fetchCallbacksSuccess,
  updateCallbackStart,
  updateCallbackFailed,
  updateCallbackSuccess,
  deleteCallbackSuccess
} = slice.actions;

const apiUrl = getEnvApiUrl();

export const fetchCallbacks = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchCallbacksStart());
    const response: AxiosResponse<ICallback[]> = await axios.get(`${apiUrl}/callbacks/`);
    dispatch(fetchCallbacksSuccess(response.data));
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(fetchCallbacksFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
  }
};

export const fetchCallback = (id: string): AppThunk => async dispatch => {
  try {
    dispatch(fetchCallbacksStart());
    const response: AxiosResponse<ICallback> = await axios.get(`${apiUrl}/callbacks/${id}/`);
    dispatch(fetchCallbackSuccess(response.data));
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(fetchCallbacksFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
  }
};

export const updateCallback = (callback: Partial<ICallback>, fetch: boolean = false): AppThunk => async dispatch => {
  try {
    dispatch(updateCallbackStart());
    const response: AxiosResponse<ICallback> = await axios.patch(`${apiUrl}/callbacks/${callback.id}/`, callback);
    dispatch(updateCallbackSuccess(response.data));
    if (fetch) {
      dispatch(fetchCallbacks());
    }
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(updateCallbackFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
  }
};

export const createCallback = (callback: ICallback, fetch: boolean = false): AppThunk => async dispatch => {
  try {
    dispatch(updateCallbackStart());
    const response: AxiosResponse<ICallback> = await axios.post(`${apiUrl}/callbacks/`, callback);
    dispatch(updateCallbackSuccess(response.data));
    if (fetch) {
      dispatch(fetchCallbacks());
    }
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(updateCallbackFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
  }
};

export const deleteCallback = (callback: ICallback): AppThunk => async dispatch => {
  try {
    dispatch(updateCallbackStart());
    await axios.delete(`${apiUrl}/callbacks/${callback.id}/`);
    dispatch(deleteCallbackSuccess());
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(updateCallbackFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
  }
};
