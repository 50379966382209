import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { IRootState } from 'config/store';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AlertAggregationType, IAlert, notificationStrategy } from 'shared/model/alert.model';
import { createAlert, fetchAlert, updateAlert } from 'shared/reducers/alertsSlice';
import { fetchUsers } from 'shared/reducers/usersSlice';
import { ILabelValueOption } from 'shared/utils/select-utils';
// import { workspaceIsSilo } from 'shared/utils/workspace-utils';
import Loading from 'shared/widgets/loading';
import AlertForm from './alertForm';

export interface IAlertFormResponse {
  alert_name: string;
  data_type: ILabelValueOption;
  device_group_to_check: ILabelValueOption[];
  max_value?: string;
  min_value?: string;
  geofencing_strategy?: ILabelValueOption;
  recipients: ILabelValueOption[];
  // consecutive_trigger_delay: ILabelValueOption;
  geofencing_lat: number;
  geofencing_lng: number;
  geofencing_radius: number;
  device_types: ILabelValueOption[];
  notification_strategy_names: notificationStrategy[];
  aggregate_operation: AlertAggregationType | 'simple';
}

export interface ICreateOrEditAlertFormProps {
  onSuccess?: Function;
}

const CreateOrEditAlertForm = (props: ICreateOrEditAlertFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const updateSuccess = useSelector(({ alerts }: IRootState) => alerts.updateSuccess);
  const alert = useSelector(({ alerts }: IRootState) => alerts.alert);
  const loading = useSelector(({ alerts }: IRootState) => alerts.loading);
  const loadingDevices = useSelector(({ devices }: IRootState) => devices.loading);
  const users = useSelector(({ users }: IRootState) => users.users);
  const loadingUsers = useSelector(({ users }: IRootState) => users.loading);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  // const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  // const isSilo = workspaceIsSilo(settings);

  const isNew = id === 'new' ? true : false;
  const groupGroup = t('group');

  useEffect(() => {
    if (!isNew && id) {
      dispatch(fetchAlert(id));
    }
  }, [dispatch, id, isNew]);

  useEffect(() => {
    if (updateSuccess === true) {
      history.push('/alerts');
    }
  }, [history, updateSuccess]);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(fetchUsers());
    }
  }, [users.length, dispatch]);

  const onSubmit = (responses: IAlertFormResponse) => {
    const device_groups_to_check_ids = responses.device_group_to_check.filter(item => item.group === groupGroup).map(item => item.value);
    const devices_to_check_ids = responses.device_group_to_check.filter(item => item.group !== groupGroup).map(item => item.value);

    const recipients_for_notifications_ids = responses.recipients.reduce((agg: string[], current) => {
      if (current.group !== groupGroup) {
        agg.push(current.value);
      }
      return agg;
    }, []);

    const recipient_groups_for_notifications_ids = responses.recipients.reduce((agg: string[], current) => {
      if (current.group === groupGroup) {
        agg.push(current.value);
      }
      return agg;
    }, []);

    const device_types = responses.device_types ? responses.device_types.map(option => option.value) : [];

    const aggregate_operation = responses.aggregate_operation === 'simple' ? null : responses.aggregate_operation;

    const alertToCreate: IAlert = {
      alert_id: !isNew ? alert?.alert_id : undefined,
      alert_name: responses.alert_name,
      is_active: true,
      // consecutive_trigger_delay: String(moment.duration(THREE_MONTH).asSeconds()),
      data_type: responses.data_type.value,
      device_groups_to_check_ids,
      devices_to_check_ids,
      geofencing_lat: responses.geofencing_lat ? Number(responses.geofencing_lat) : null,
      geofencing_lng: responses.geofencing_lng ? Number(responses.geofencing_lng) : null,
      geofencing_radius: responses.geofencing_radius ? Number(responses.geofencing_radius) : null,
      geofencing_strategy: responses.geofencing_strategy ? responses.geofencing_strategy.value : null,
      min_value: responses.min_value ? Number(responses.min_value) : null,
      max_value: responses.max_value ? Number(responses.max_value) : null,
      recipients_for_notifications_ids,
      recipient_groups_for_notifications_ids,
      notification_strategy_names: responses.notification_strategy_names,
      device_types,
      aggregate_operation: aggregate_operation
    };
    if (isNew) {
      // create alert
      dispatch(createAlert(alertToCreate));
    } else {
      dispatch(updateAlert(alertToCreate));
    }
    if (props.onSuccess) {
      props.onSuccess();
    }
  };
  return (
    <Box p={1}>
      <Paper elevation={3}>
        {isNew || (!loading && !loadingDevices && !loadingUsers && alert) ? (
          <AlertForm onSubmit={onSubmit} />
        ) : (
          <Box p={4}>
            <Loading />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default CreateOrEditAlertForm;
