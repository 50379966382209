import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { IRootState } from 'config/store';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { notificationStrategy } from 'shared/model/alert.model';
import { IUser } from 'shared/model/user.model';
import { displayUser, userWithEmptyName } from 'shared/utils/user-utils';
import { workspaceIsSilo } from 'shared/utils/workspace-utils';
import MultipleAutoComplete from 'shared/widgets/form/multipleAutocomplete';

export interface IAlertFormThirdStepProps {
  step: number;
  activeStep: number;
}

const AlertFormThirdStep = (props: IAlertFormThirdStepProps) => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new' ? true : false;
  const currentUser = useSelector(({ authentication }: IRootState) => authentication.currentUser) as IUser;

  const { activeStep, step } = props;

  const groups = useSelector(({ group }: IRootState) => group.groups);
  const users = useSelector(({ users }: IRootState) => users.users);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const isSilo = workspaceIsSilo(settings);

  const groupGroup = t('group');
  const userGroup = t('user', { count: 100 });

  const groupOptions = groups.map(aGroup => ({
    label: aGroup.group_name,
    value: aGroup.group_id,
    group: groupGroup
  }));

  const userOptions = users
  .filter(item => !item.is_invitation_pending)
  .filter(userWithEmptyName)
  .map(aUser => ({
    label: displayUser(aUser),
    value: aUser.id as string,
    group: userGroup
  }));
  
  if (!userOptions.find(u => u.value === currentUser.id)) userOptions.push({
    label: displayUser(currentUser),
    value: currentUser.id as string,
    group: userGroup
  })
  const fixedOptions = isNew ? userOptions.filter(item => item.value === currentUser.id) : [];

  const recipientsOptions = groupOptions.concat(userOptions);
  form.register({ name: 'notification_strategy_names' });

  let recipientsDefaultValue;
  if (!isNew) {
    recipientsDefaultValue = form.getValues().recipients;
    // trigger validation if reicipeints default values is empty
    // it will re-render the form
    if (!recipientsDefaultValue) {
      setTimeout(() => {
        form.triggerValidation();
      }, 100);
    }
  }
  let notification_strategy_names = form.watch<string, notificationStrategy[]>('notification_strategy_names');
  console.log({notification_strategy_names})
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newselection = [...notification_strategy_names]; //shallow copy
    if ( event.target.checked ) newselection.push(event.target.name)
    else {
      const index = newselection.indexOf(event.target.name);
      if (index > -1) newselection.splice(index, 1); // 2nd parameter means remove one item only
    }
    form.setValue('notification_strategy_names', newselection);
  };
  return (
    <Box display={activeStep === step ? 'block' : 'none'}>
      {isSilo && (
        <>
          <Box textAlign="center" fontWeight="bold" color="#555">
            {t('select_strategy')}
          </Box>
          <FormGroup>
            <FormControlLabel
              label={t("email")}
              control={<Checkbox onChange={handleChange} checked={notification_strategy_names.includes('email')} name="email"/>}
              />
            <FormControlLabel
              label={t("push")}
              control={<Checkbox onChange={handleChange} checked={notification_strategy_names.includes('push')} name="push"/>}
            />
          </FormGroup>
        </>
      )}
      <MultipleAutoComplete
        name="recipients"
        label={t('select_group_or_user')}
        defaultValue={recipientsDefaultValue}
        options={recipientsOptions}
        fixedOptions={fixedOptions}
        validate={(value: any[]) => {
          if (activeStep === step && (!value || value.length === 0)) {
            return <Trans i18nKey="required_field">Required Field</Trans>;
          }
          return true;
        }}
      />
    </Box>
  );
};

export default AlertFormThirdStep;
