import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import { IRootState } from 'config/store';
import React, { useEffect } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IUser } from 'shared/model/user.model';
import { deleteCurrentUser, updateCurrentUser } from 'shared/reducers/usersSlice';
import { getTimezonesNames } from 'shared/utils/date-utils';
import { usePrevious } from 'shared/utils/hook-utils';
import { getLangOptions } from 'shared/utils/lang-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import ConfirmDelete from 'shared/widgets/confirmDelete';
import SingleAutoComplete from 'shared/widgets/form/singleAutocomplete';

const zonesOptions = getTimezonesNames();

interface IFormResponse {
  first_name: string;
  last_name: string;
  email: string;
  preferred_language: ILabelValueOption;
  preferred_timezone: ILabelValueOption;
  // units_system: ILabelValueOption;
}

const UserProfile = () => {
  const { t } = useTranslation();
  const updating = useSelector(({ users }: IRootState) => users.updating);
  const currentUser = useSelector(({ authentication }: IRootState) => authentication.currentUser) as IUser;
  const errorMessage = useSelector(({ users }: IRootState) => users.errorMessage);
  const updateSuccess = useSelector(({ users }: IRootState) => users.updateSuccess);
  const previousUpdating = usePrevious<boolean>(updating);

  const dispatch = useDispatch();

  const langOptions = getLangOptions();

  const langDefault = langOptions.find(option => option.value === currentUser.preferred_language);

  const timeZoneDefault = zonesOptions.find(zoneOption => zoneOption.value === currentUser.preferred_timezone);
  
  // const unitsSystemDefault = unitsOptions.find(unitOption => unitOption.value === currentUser.units_system);
  
  const form = useForm<IFormResponse>({
    defaultValues: {
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      email: currentUser.email,
      preferred_timezone: timeZoneDefault,
      preferred_language: langDefault
      // units_system: unitsSystemDefault
    }
  });

  useEffect(() => {
    if (previousUpdating === true && updating === false) {
      if (errorMessage && !updateSuccess) {
        form.reset();
      }
    }
  }, [errorMessage, form, previousUpdating, updateSuccess, updating]);

  const onSubmit = (responses: IFormResponse) => {
    const toUpdate = {
      id: currentUser.id,
      first_name: responses.first_name,
      last_name: responses.last_name,
      email: responses.email,
      preferred_language: responses.preferred_language.value,
      preferred_timezone: responses.preferred_timezone.value
      // units_system: responses.units_system.value
    };

    dispatch(updateCurrentUser(toUpdate));
  };

  const removeAccount = () => {
    dispatch(deleteCurrentUser(currentUser));
  };

  return (
    <Box p={2}>
      <FormContext {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
          <Box textAlign="center">
            <Typography variant="h5">{t('edit_profile')}</Typography>
          </Box>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={8} sm={6} md={4} lg={3}>
              <TextField
                autoFocus
                margin="dense"
                fullWidth
                label={t('given_name')}
                name="first_name"
                disabled={updating}
                inputRef={form.register({ required: <Trans i18nKey="required_field">Required Field</Trans> })}
                error={form.errors.first_name ? true : false}
                helperText={form.errors.first_name && form.errors.first_name.message}
              />
              <TextField
                margin="dense"
                fullWidth
                label={t('lastname')}
                name="last_name"
                disabled={updating}
                inputRef={form.register({ required: <Trans i18nKey="required_field">Required Field</Trans> })}
                error={form.errors.last_name ? true : false}
                helperText={form.errors.last_name && form.errors.last_name.message}
              />
              <TextField
                margin="dense"
                fullWidth
                label={t('email_address')}
                name="email"
                disabled={true}
                inputRef={form.register({ required: <Trans i18nKey="required_field">Required Field</Trans> })}
                error={form.errors.email ? true : false}
                helperText={form.errors.email && form.errors.email.message}
              />
              <SingleAutoComplete
                disableClearable
                name="preferred_language"
                label={t('lang')}
                defaultValue={langDefault}
                options={langOptions}
                disabled={updating}
              />
              <SingleAutoComplete
                disableClearable
                name="preferred_timezone"
                label={t('timezone')}
                defaultValue={timeZoneDefault}
                options={zonesOptions}
                disabled={updating}
              />
              {/* <SingleAutoComplete
                disableClearable
                name="units_system"
                label={t('units_system')}
                defaultValue={unitsSystemDefault}
                options={unitsOptions}
                disabled={updating}
              /> */}
              <Box mt={4} textAlign="center">
                <Button
                  type="submit"
                  color="primary"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  disabled={updating || !form.formState.dirty}
                >
                  {t('save')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormContext>
      <Box mt={4} textAlign="center">
        <ConfirmDelete
          btnVariant="text"
          noIcon
          btnText={t('remove_account')}
          message={t('confirm_removing_text')}
          size="small"
          onConfirm={removeAccount}
          objectToReturn={[currentUser]}
        />
      </Box>
    </Box>
  );
};

export default UserProfile;
