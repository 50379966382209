import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import 'rc-slider/assets/index.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPng from 'shared/icons/error.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      paddingTop: theme.spacing(1)
    }
  })
);

interface IMapErrorMessageProps {
  isError: boolean;
}

const MapErrorMessage = (props: IMapErrorMessageProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isError} = props;
  if (!isError) {
    return null;
  }



  return (
    <Box display="flex" alignItems="top" marginTop={2}>
      <Card>
        <CardContent className={classes.card}>
        <Box textAlign="center">
            <img src={ErrorPng} alt={t('devices_status.error.label')} height="40px" />
        </Box>
        
        <Box textAlign="center" marginBottom={1} fontSize="0.8rem">
            {t('forecast_timed_out')}
        </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default MapErrorMessage;
