import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { IRootState } from 'config/store';
import React, { useCallback, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IUser } from 'shared/model/user.model';
import { addGroupsToUser } from 'shared/reducers/usersSlice';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { displayUser } from 'shared/utils/user-utils';
import MultipleAutoComplete from 'shared/widgets/form/multipleAutocomplete';

interface IFormResponse {
  role: string;
  groups: ILabelValueOption[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      minWidth: '580px',
      minHeight: '200px'
    },
    autoComplete: {
      marginBottom: theme.spacing(2)
    }
  })
);

export interface IAddGroupToUserDialogProps {
  users: IUser[];
  onSuccess?: Function;
}

const AddGroupToUserDialog = (props: IAddGroupToUserDialogProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const groups = useSelector(({ group }: IRootState) => group.groups);
  const authorities = useSelector(({ group }: IRootState) => group.authorities);
  const updating = useSelector(({ users }: IRootState) => users.updating);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const form = useForm<IFormResponse>();

  const { users, onSuccess } = props;

  const toggleDialog = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const onSubmit = form.handleSubmit(values => {
    
    users.forEach(user => {
      const role = (!user.workspace_role.group_role_delegation && values.role) ? values.role : 'Admin'; // by default admin if not set via menu, used for installer v2 role
      const groupsToAdd = values.groups.map(aGroup => ({ group_id: aGroup.value, group_role_id: role }));
      dispatch(addGroupsToUser(groupsToAdd, user));
    });
    if (onSuccess) {
      onSuccess();
    }
    setOpen(false);
  });

  const groupOptions = groups.reduce((agg: ILabelValueOption[], current) => {
    let groupNotUsed = true;
    for (let i = 0; i < users.length; i++) {
      const aUser = users[i];
      if (aUser.group_memberships.some(aGroup => aGroup.group.group_id === current.group_id)) {
        groupNotUsed = false;
        break;
      }
    }

    if (groupNotUsed) {
      agg.push({
        label: current.group_name,
        value: current.group_id
      });
    }

    return agg;
  }, []);

  const userNames = users.reduce((agg, current) => {
    if (agg.length > 0) {
      agg += ', ';
    }
    agg += '"' + displayUser(current) + '"';
    return agg;
  }, '');

  return (
    <>
      <Tooltip title={<Trans i18nKey="add_user_to_group"></Trans>}>
        <Button variant="contained" color="primary" size="small" onClick={toggleDialog}>
          <AddIcon />
        </Button>
      </Tooltip>
      <Dialog fullScreen={fullScreen} open={open} onClose={toggleDialog}>
        <FormContext {...form}>
          <form onSubmit={onSubmit} autoComplete="off">
            <DialogTitle>{t('add_group_to_user_name', { name: userNames })}</DialogTitle>
            <DialogContent className={classes.content} dividers>
              <MultipleAutoComplete
                label={t('add_group_to_user')}
                name="groups"
                options={groupOptions}
                autoFocus
                className={classes.autoComplete}
                disabled={updating}
                validate={(value: any) => {
                  if (!value || value.length === 0) {
                    return <Trans i18nKey="required_field">Required Field</Trans>;
                  }
                  return true;
                }}
              />
              {(authorities.length > 0 && users.some(user => user.workspace_role.group_role_delegation)) && (
                <FormControl component="fieldset">
                  <FormLabel component="legend">{t('please_select_role')}</FormLabel>
                  <RadioGroup name="role" row={!fullScreen} defaultValue={authorities[0].group_role_id}>
                    {authorities.map(authority => (
                      <FormControlLabel
                        key={authority.group_role_id}
                        value={authority.group_role_id}
                        control={<Radio inputRef={form.register({ required: true })} />}
                        label={t(authority.group_role_name.toLowerCase())}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleDialog} startIcon={<CancelIcon />} disabled={updating}>
                {t('cancel')}
              </Button>
              <Button type="submit" color="primary" startIcon={<SaveIcon />} disabled={updating}>
                {t('save')}
              </Button>
            </DialogActions>
          </form>
        </FormContext>
      </Dialog>
    </>
  );
};

export default AddGroupToUserDialog;
