import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { IGroup } from 'shared/model/group.model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.5),
      borderRadius: '10px'
    }
  })
);

export interface IAlertGroupProps {
  group: IGroup | undefined;
}

const AlertGroup = (props: IAlertGroupProps) => {
  const classes = useStyles();

  const { group } = props;

  if (!group) {
    return null;
  }

  return <Chip label={group.group_name} variant="outlined" className={classes.chip} size="small" />;
};

export default AlertGroup;
