import React from 'react';
import { Redirect } from 'react-router-dom';

const Home = () => {
  // TODO see what to display

  return <Redirect to="/dashboards" />;
};

export default Home;
