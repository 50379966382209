import { createMuiTheme, Theme, ThemeOptions } from '@material-ui/core';
import { enUS, esES, frFR, itIT, ruRU } from '@material-ui/core/locale';
import { lighten } from '@material-ui/core/styles';
import { useMemo } from 'react';
import { createTheme } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ISettings } from 'shared/reducers/settingsSlice';
import nanolikeLogo from './nanolike.png';
import binconnectHeader from './binconnectHeader.png';
import tankconnectHeader from './tankconnectHeader.png';
import siloconnectHeader from './siloconnectHeader.png';
import binConnectIconBlue from './Picto-binConnect.png'
import siloConnectIconBlue from './Picto-SiloConnect.png'
import tankConnectIconBlue from './Picto-TankConnect.png'
import nanolikeSmallLogo from './nanolikeLogo.png';
import { IRootState } from './store';

const capitalize = (word: string) => word.charAt(0).toUpperCase() + word.slice(1)

const logoForUseCase = {
  'silo': binconnectHeader,
  'ibc': tankconnectHeader,
  'silo_industry': siloconnectHeader,
  'default': nanolikeLogo
}
// const iconForUseCase = {
//   'silo': binConnectIcon,
//   'ibc': tankConnectIcon,
//   'silo_industry': siloConnectIcon,
//   'default': nanolikeSmallLogo
// }

const blueIconForUseCase = {
  'silo': binConnectIconBlue,
  'ibc': tankConnectIconBlue,
  'silo_industry': siloConnectIconBlue,
  'default': nanolikeSmallLogo
}
const titleForUseCase = {
  'silo': `BinConnect - ${capitalize(window.env.workspace)}`,
  'ibc': `TankConnect - ${capitalize(window.env.workspace)}`,
  'silo_industry': `SiloConnect - ${capitalize(window.env.workspace)}`,
  'default': `Nanolike - ${capitalize(window.env.workspace)}`
}

export interface INanolikeCustomTheme {
  title: string;
  muiTheme: Theme;
  logo: string;
  logoSmall: string;
  headerMenuLogo: string;
  welcomeText: string;
  welcomeDescription: string;
  headerMenuTitle?: string;
  // backgroundLoginColor: { backgroundColor: string; color: string, display: string, alignItems: string };
  backgroundLoginColor: string;
  headerMenuBackgroundColor: string;
}

export const getNanolikeTheme = (settings: ISettings | null) => {
  const theme: ThemeOptions = {
    palette: {
      // primary: settings && settings.primary? { main: settings.primary as string } : {main: "#00FF00"},
      primary: { main: (settings && settings.primary) ?? "#171155" },
      // primary: nanolikePrimaryColors,
      warning: {
        main: "#2E48A7"
      },
      error: {
        main: "#E83E6D"
      },
      success: {
        main: "#4caf50"
      },
      info: {
        main: "#F3F5FC"
      }
    },
    props: {
      MuiButton: {
        size: 'small'
      },
      MuiFilledInput: {
        margin: 'dense'
      },
      MuiFormControl: {
        margin: 'dense'
      },
      MuiFormHelperText: {
        margin: 'dense'
      },
      MuiIconButton: {
        size: 'small'
      },
      MuiInputBase: {
        margin: 'dense'
      },
      MuiInputLabel: {
        margin: 'dense'
      },
      MuiListItem: {
        dense: true
      },
      MuiOutlinedInput: {
        margin: 'dense'
      },
      MuiFab: {
        size: 'small'
      },
      MuiTable: {
        size: 'small'
      },
      MuiTextField: {
        margin: 'dense'
      },
      MuiToolbar: {
        variant: 'dense'
      }
    }
  };
  return theme;
};

const useConfigTheme = (): INanolikeCustomTheme => {
  const { i18n, t } = useTranslation();
  const settings = useSelector(({ settings }: IRootState) => settings.settings);

  const theme = useMemo(() => {
    const theme: ThemeOptions = getNanolikeTheme(settings);
    let locale = enUS;
    switch (i18n.resolvedLanguage) {
      case 'fr':
        locale = frFR;
        break;
      case 'es':
        locale = esES;
        break;
      case 'it':
        locale = itIT;
        break;
      case 'ru':
        locale = ruRU;
        break;
      default:
        locale = enUS;
        break;
    }
    const muiTheme = createMuiTheme(theme, locale);
    const headerContextBackground = lighten(muiTheme.palette.primary.light, 0.8);
    const headerContextText = muiTheme.palette.text;

    createTheme('nanolike', {
      context: {
        background: headerContextBackground,
        text: headerContextText
      }
    });
    const result: INanolikeCustomTheme = {
      muiTheme,
      //@ts-ignore
      title: titleForUseCase[settings?.useCase || 'default'],
      //@ts-ignore
      logo: logoForUseCase[settings?.useCase || 'default'],
      //@ts-ignore
      logoSmall: blueIconForUseCase[settings?.useCase || 'default'],
      //@ts-ignore
      headerMenuLogo: logoForUseCase[settings?.useCase || 'default'],
      welcomeText: t('welcome_text'),
      welcomeDescription: t('welcome_description'),
      backgroundLoginColor: muiTheme.palette.primary.main,
      // backgroundLoginColor: {
      //   backgroundColor: muiTheme.palette.primary.main,
      //   color: '#FFF',
      //   display: 'flex',
      //   alignItems: 'center'
      // },
      headerMenuBackgroundColor: muiTheme.palette.primary.main
    };
    if (settings?.config_activated) {
      result.title = settings.platform_title;
      result.headerMenuTitle = settings.platform_title;
      result.logo = settings.logo;
      result.logoSmall = settings.logo_small;
      result.headerMenuLogo = settings.logo_small;
      result.welcomeText = settings.welcome_text;
      result.welcomeDescription = settings.welcome_description;
      result.backgroundLoginColor = settings.background_login_color;
      // result.backgroundLoginColor.backgroundColor = settings.background_login_color;
      // result.backgroundLoginColor.color = muiTheme.palette.primary.main;
      result.headerMenuBackgroundColor = settings.background_header_color;
    }
    return result;
  }, [i18n.resolvedLanguage, settings, t]);

  return theme;
};

export default useConfigTheme;
